import React, { Suspense } from "react";
import styled from "styled-components";
import { ViewLoader } from "../ViewLoader";
import { AuthorizedHeader } from "./AuthorizedHeader";

export const AuthorizedLayout: React.FC = ({ children }) => (
  <>
    <AuthorizedHeader />
    <ContentWrapper>
      <Suspense fallback={<ViewLoader fullPage />}>{children}</Suspense>
    </ContentWrapper>
  </>
);

const ContentWrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme.palette.neutral.grey_warm_20};
`;
