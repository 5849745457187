import React, { useContext } from "react";
import { DetailedProject } from "dto/Project";

export interface ProjectStateContextType {
  project: DetailedProject | undefined;
  updateProject: (update: Partial<DetailedProject>) => void;
}

export const ProjectStateContext = React.createContext<ProjectStateContextType>(
  {
    project: undefined,
    updateProject: () => {
      throw Error("cannot find ProjectStateContext Provider!");
    }
  }
);

export const useProjectState = () => {
  const { project, updateProject } = useContext(ProjectStateContext);
  if (!project) {
    throw Error("ProjectStateContext have to contain project object");
  }
  return { project, updateProject };
};
