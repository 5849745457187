import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Add, Download, ManageAccounts, Search } from "@mui/icons-material";
import { SurveyTable } from "./SurveyTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getAllProjects } from "services/projectService";
import { PagedProjects, ProjectTab, Status } from "dto/Project";
import { Paths } from "routing";
import { useMe, usePermissions } from "contexts/Auth";
import { ProjectsContainer } from "components/Projects/ProjectsContainer";
import { Paginator } from "components/Shared/Pagination";
import { useDebounce, useUrlSortSettings } from "utils";
import { getAllTemplates } from "services/templateService";
import { queryKeys } from "api/queryClient";
import { ReactComponent as SurveysEmpty } from "assets/icons/surveys-empty.svg";
import { Welcome } from "components/Shared/Welcome";
import { QueryErrorFeedback } from "components/Shared";
import { getAllGroups } from "services/groupService";
import { GroupListDto } from "dto";

const size = 9;

const availableStatuses: Status[] = Object.values(Status);

export const SurveyList: React.FC = () => {
  const { t } = useTranslation(["surveyList", "group", "common"]);
  const navigate = useNavigate();

  const { canCreate } = usePermissions("projectPermissions");
  const canAccessGroupsAndUsers = usePermissions("canAccessGroupsAndUsers");
  const { managedGroups } = useMe();
  const { groups, fetchNextPage, resetList } = useInfiniteGroupsList();

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<Status>();
  const [template, setTemplate] = useState<string>();
  const [group, setGroup] = useState<string>();
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 800);
  const { rawParam: sort } = useUrlSortSettings();

  const {
    isLoading,
    isError,
    data: pagedProjects,
    refetch
  } = useQuery<PagedProjects>(
    ["projects", page, size, sort, status, debouncedSearch, group, template],
    () =>
      getAllProjects({
        page,
        size,
        sort,
        status,
        search: debouncedSearch,
        group,
        template
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    // reset pagination on filter changes
    setPage(0);
  }, [status, group, template, debouncedSearch]);

  useEffect(() => {
    resetList();
    fetchNextPage();
  }, []);

  const { isLoading: isLoadingTemplates, data: templates } = useQuery(
    queryKeys.templates,
    getAllTemplates
  );

  return (
    <ProjectsContainer
      tab={ProjectTab.SURVEYS}
      createButton={
        canCreate && (
          <Button
            onClick={() => {
              const { base, create } = Paths.surveys;
              navigate(`/${base}/${create}`);
            }}
            variant="contained"
            color="primary"
            startIcon={<Add sx={{ mb: "3px" }} />}
          >
            {t("surveyList:newSurvey")}
          </Button>
        )
      }
    >
      <Welcome />
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100%",
            my: 30
          }}
        >
          <CircularProgress size={24} color="primary" />
        </Box>
      )}
      {isError && <QueryErrorFeedback retry={refetch} />}
      {pagedProjects ? (
        <>
          <Box display="flex" justifyContent="space-between" mt={8}>
            <Box>
              <FormControl sx={{ width: 175, mr: 5 }}>
                <InputLabel id="status-filter-label">
                  {t("surveyList:listFilters.status.title")}
                </InputLabel>
                <Select
                  labelId="status-filter-label"
                  id="status-filter"
                  label="Status"
                  value={status}
                  onChange={(event) => setStatus(event.target.value as Status)}
                >
                  {availableStatuses.map((value) => (
                    <MenuItem key={value} value={value}>
                      {t(`surveyList:listFilters.status.${value}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {canAccessGroupsAndUsers && (
                <FormControl
                  sx={{
                    width: 175,
                    mr: 5,
                    ".MuiSelect-select svg": { display: "none" }
                  }}
                >
                  <InputLabel id="group-filter-label">
                    {t("surveyList:listFilters.group.title")}
                  </InputLabel>
                  <Select
                    labelId="group-filter-label"
                    id="group-filter"
                    label="Group"
                    value={group}
                    MenuProps={{
                      PaperProps: {
                        onScroll: fetchNextPage
                      },
                      MenuListProps: {
                        sx: { maxHeight: 200 }
                      }
                    }}
                    onChange={(event) => setGroup(event.target.value)}
                  >
                    <MenuItem>{t("common:any")}</MenuItem>
                    {groups &&
                      groups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {managedGroups &&
                            managedGroups.find(
                              (managedGroup) => managedGroup.id === group.id
                            ) && (
                              <ManageAccounts
                                color="primary"
                                sx={{ width: 15, mr: 1 }}
                              />
                            )}
                          {group.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {canCreate && (
                <FormControl sx={{ width: 175 }}>
                  <InputLabel id="template-filter-label">
                    {t("surveyList:listFilters.template.title")}
                  </InputLabel>
                  <Select
                    labelId="template-filter-label"
                    id="template-filter"
                    label="Template"
                    value={template}
                    onChange={(event) => setTemplate(event.target.value)}
                  >
                    {isLoadingTemplates && <CircularProgress />}
                    {templates &&
                      templates.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.title} ({value.shortCode})
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box sx={{ width: 260, ml: 2 }}>
              <TextField
                margin="dense"
                fullWidth
                placeholder={t("surveyList:listFilters.search.placeholder")}
                variant="standard"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoFocus
                InputProps={{
                  startAdornment: <Search />
                }}
              />
            </Box>
          </Box>
          <SurveyTable
            projects={pagedProjects.content}
            hasFilters={!!status || !!debouncedSearch || !!group || !!template}
            footer={
              <Paginator
                resourceName={t("group:list.table.header.projectsCount")}
                page={page}
                pageable={pagedProjects}
                setPage={setPage}
                size={pagedProjects.totalPages}
              />
            }
          />
        </>
      ) : (
        !isError && !isLoading && <SurveyListEmpty />
      )}
    </ProjectsContainer>
  );
};

export const SurveyListEmpty = () => {
  const { t } = useTranslation(["surveyList", "common"]);
  const { canCreate } = usePermissions("projectPermissions");
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={20}
    >
      <SurveysEmpty />
      <Typography variant="h2" sx={{ mt: 5 }}>
        {t("surveyList:emptyFallback")}
      </Typography>
      <Box mt={8}>
        {canCreate && (
          <Button
            onClick={() => {
              const { base, create } = Paths.surveys;
              navigate(`/${base}/${create}`);
            }}
            variant="contained"
            color="primary"
            startIcon={<Add sx={{ mb: "3px" }} />}
          >
            {t("surveyList:newSurvey")}
          </Button>
        )}
      </Box>
      <Box mt={4} mb={30}>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled
          startIcon={<Download />}
        >
          {t("common:userDropdown.guide")}
        </Button>
      </Box>
    </Box>
  );
};

export const useInfiniteGroupsList = () => {
  const [groups, setGroups] = useState<GroupListDto[]>();
  const [hasNextPage, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const fetchNextPage = () => {
    getAllGroups({ page: pageIndex, size: 9 }).then((pageable) => {
      setPageIndex((prevIndex) => prevIndex + 1);
      setHasNext(!pageable.last);
      setGroups((prevGroups) => {
        if (!prevGroups) {
          return pageable.content;
        }
        const prevGroupsIdsMap = prevGroups.reduce<Record<string, true>>(
          (acc, { id }) => ({ ...acc, [id]: true }),
          {}
        );

        const onlyNew = pageable.content.filter(
          ({ id }) => !prevGroupsIdsMap[id]
        );
        return prevGroups.concat(onlyNew);
      });
    });
  };

  const resetList = () => {
    setGroups(undefined);
    setPageIndex(0);
  };

  return { groups, hasNextPage, fetchNextPage, resetList };
};
