import { Placeholder, PlaceholderDetails } from "../../../../../dto";
import { createRecordWithKey } from "../../../../../utils/recordFactory";
import {
  placeholderKeysConfiguredByOthers,
  websiteAppBehaviour,
  websiteOrAppPKey
} from "./configurationPlaceholders";

const configurePlaceholdersBehaviour = (
  placeholdersFound: Placeholder[],
  configurationPlaceholders: Placeholder[]
) => {
  const websiteOrAppPlaceholder = configurationPlaceholders.find(
    (p) => p.key === websiteOrAppPKey
  );
  if (websiteOrAppPlaceholder && websiteOrAppPlaceholder.text) {
    return websiteAppBehaviour[parseInt(websiteOrAppPlaceholder.text)];
  }
  return [];
};

export const getConfigurationPlaceholders = (
  placeholders: Record<string, Placeholder>
) => {
  const configurationPlaceholders = Object.values(placeholders).filter(
    (p) => websiteOrAppPKey === p.key
  );
  const placeholdersFound = Object.values(placeholders).filter((p) =>
    placeholderKeysConfiguredByOthers.includes(p.key)
  );
  const placeholdersConfigured = configurePlaceholdersBehaviour(
    placeholdersFound,
    configurationPlaceholders
  );
  return placeholdersConfigured;
};

export const mergePlaceholdersWithDynamicConfig = (
  placeholdersRecord: Record<string, Placeholder>,
  placeholderDetailsRecord: Record<string, PlaceholderDetails>
) => {
  const placeholdersConfigured = getConfigurationPlaceholders(
    placeholdersRecord
  ).map(
    (placeholder) =>
      ({
        ...placeholderDetailsRecord[placeholder.key],
        ...placeholder
      } as PlaceholderDetails)
  );
  const placeholdersConfiguredRecord = createRecordWithKey(
    placeholdersConfigured
  );

  const updatedPlaceholders = {
    ...placeholderDetailsRecord,
    ...placeholdersConfiguredRecord
  };

  return updatedPlaceholders;
};
