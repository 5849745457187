import { Button, ButtonProps } from "@mui/material";
import { forwardRef } from "react";

interface ImageIconButtonProps extends ButtonProps {
  iconSource: string;
  iconAlt: string;
}

const ImageIconButton = forwardRef<HTMLButtonElement, ImageIconButtonProps>(
  ({ iconSource, iconAlt, ...buttonProps }, ref) => {
    return (
      <Button
        ref={ref}
        variant="outlined"
        {...buttonProps}
        sx={{
          height: "32px",
          width: "32px",
          "&.MuiButton-root": {
            minWidth: "32px"
          },
          ...buttonProps.sx
        }}
      >
        <img src={iconSource} alt={iconAlt} />
      </Button>
    );
  }
);

export default ImageIconButton;
