import React, { useState } from "react";
import {
  CloseableDialog,
  LoadingButton,
  PasswordInput,
  PasswordValidator
} from "components/Shared";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { changePassword } from "auth/auth-client";

export interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const i18nBase = "user:changePassword";

export const ChangePasswordDialog: React.VFC<ChangePasswordDialogProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation(["user", "common"]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

  const [wasTried, setWasTried] = useState(false);

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const { isLoading, mutate } = useMutation(changePassword, {
    onSuccess: () => {
      displaySuccessSnackbar(t(`${i18nBase}.successFeedback`));
      onClose();
      setCurrentPassword("");
      setNewPassword("");
      setWasTried(false);
    },
    onError: (e) => {
      displayErrorSnackbar(
        isLimitException(e) ? t(`${i18nBase}.limitFeedback`) : undefined
      );
    }
  });

  const onChangePassword = () => {
    if (currentPassword && isNewPasswordValid) {
      mutate({ currentPassword, newPassword });
    } else {
      setWasTried(true);
    }
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      body={
        <>
          <Typography variant="h2" align="center">
            {t(`${i18nBase}.heading`)}
          </Typography>
          <Box mb={10}>
            <PasswordInput
              error={wasTried && !currentPassword}
              sx={{ mt: 10 }}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              label={t(`${i18nBase}.currentPasswordLabel`)}
              fullWidth
            />

            <PasswordInput
              error={wasTried && !isNewPasswordValid}
              sx={{ mt: 10 }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              label={t(`${i18nBase}.newPasswordLabel`)}
              fullWidth
              autoComplete="new-password"
            />

            <PasswordValidator
              mt={4}
              password={newPassword}
              onIsValidChange={setIsNewPasswordValid}
              isError={wasTried}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={onClose}
            >
              {t("common:cancel")}
            </Button>

            <LoadingButton
              isLoading={isLoading}
              onClick={onChangePassword}
              variant="contained"
              size="large"
              color="primary"
            >
              {t(`${i18nBase}.confirm`)}
            </LoadingButton>
          </Box>
        </>
      }
    />
  );
};

const isLimitException = (error: any) => {
  return error?.name === "LimitExceededException";
};
