import { Breadcrumbs, Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { queryKeys } from "api/queryClient";
import { GroupAvatar, MoreButton, ViewLoader } from "components/Shared";
import { useNotificationContext } from "contexts/NotificationContext";
import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Paths, useRouteParam } from "routing";
import { getGroupById } from "services/groupService";
import { InviteNewUserButton } from "./InviteNewUser";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddManagerButton } from "./AddManager";
import { DeleteGroupButton } from "./DeleteGroup";
import { UsersTable } from "./UsersTable";
import { ReactComponent as UsersIcon } from "assets/icons/users-empty.svg";
import { usePermissions } from "contexts/Auth";

export const GroupDetails: React.VFC = () => {
  const { t } = useTranslation(["group", "common"]);
  const { canCreate, canEdit } = usePermissions("userPermissions");
  const { canDelete } = usePermissions("groupPermissions");
  const { displayErrorSnackbar } = useNotificationContext();
  const id = useRouteParam("GroupId");
  const navigate = useNavigate();
  const { data: group } = useQuery(
    queryKeys.group.byId(id),
    () => getGroupById(id),
    {
      onError: () => {
        displayErrorSnackbar();
        navigate(`/${Paths.group.base}`);
      }
    }
  );

  return group ? (
    <Box p={6.25}>
      <Paper sx={{ py: 10, mx: "auto", maxWidth: 1110 }} elevation={0}>
        <Box
          px={12.5}
          pb={7.5}
          borderBottom={1}
          borderColor="neutral.grey_warm_50"
        >
          <Breadcrumbs sx={{ ml: 6, mb: 2 }}>
            <Button
              size="small"
              startIcon={<ArrowBackIcon />}
              color="secondary"
              component={Link}
              to={`/${Paths.group.base}`}
            >
              {t("common:menuLinks.group")}
            </Button>
            <Typography noWrap variant="subtitle2">
              {group.name}
            </Typography>
          </Breadcrumbs>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ flex: 1, minWidth: 0 }}
            >
              <GroupAvatar group={group} />
              <Typography variant="h1" mx={4} noWrap>
                {group.name}
              </Typography>
            </Box>
            <Box>
              {canCreate && (
                <InviteNewUserButton variant="contained" group={group} />
              )}
              {canDelete && (
                <MoreButton
                  sx={{ ml: 3 }}
                  renderItems={(closePopper) => (
                    <DeleteGroupButton
                      closePopper={closePopper}
                      group={group}
                    />
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Box px={12.5}>
          <Typography mt={8} mb={5} variant="h4">
            {t("group:details.users.heading")}
          </Typography>
          <UsersTable
            withDetails={canEdit}
            users={group.users}
            emptyFallback={
              <EmptyFallback>
                <UsersIcon />
                <Typography variant="subtitle1" color="textPrimary" mt={3}>
                  {t("group:details.users.emptyFallback")}
                </Typography>
                {canCreate && (
                  <InviteNewUserButton variant="text" group={group} />
                )}
              </EmptyFallback>
            }
          />

          <Typography variant="h4" mt={12.5} mb={2}>
            {t("group:details.managers.heading")}
          </Typography>
          <Typography variant="body2" mb={5} color="textSecondary">
            {t("group:details.managers.subheading")}
          </Typography>
          <UsersTable
            users={group.managers}
            emptyFallback={
              <EmptyFallback>
                <Typography variant="subtitle1" color="textPrimary">
                  {t("group:details.managers.emptyFallback")}
                </Typography>
              </EmptyFallback>
            }
          />

          {canEdit && (
            <Box mt={6}>
              <AddManagerButton group={group} />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  ) : (
    <ViewLoader />
  );
};

const EmptyFallback: React.FC = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height={150}
  >
    {children}
  </Box>
);
