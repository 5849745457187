import { PageableParams } from "dto/common";
import { GroupDetailsDto, PagedGroups } from "dto/Group";
import { httpClient } from "api/httpClient";
import { urls } from "api/urls";
import { UserListDto, UserRole } from "dto";

export const getAllGroups = async ({ page, size, sort }: PageableParams) => {
  const response = await httpClient.get<PagedGroups>(urls.groups, {
    params: { page, size, sort }
  });
  return response.data;
};

export const getGroupById = async (id: string) => {
  const response = await httpClient.get<GroupDetailsDto>(
    `${urls.groups}/${id}`
  );
  return response.data;
};

export const getPotentialGroupManagers = async () => {
  const response = await httpClient.get<UserListDto[]>(
    `${urls.groups}/managers`
  );
  return response.data;
};

export const createGroup = async (body: {
  name: string;
  managers: string[];
}) => {
  const response = await httpClient.post<GroupDetailsDto>(urls.groups, body);
  return response.data;
};

export const addManagerToGroup = async ({
  groupId,
  managers
}: {
  groupId: string;
  managers: string[];
}) => {
  const response = await httpClient.put(`${urls.groups}/${groupId}/managers`, {
    managers
  });
  return response.data;
};

export const inviteNewUserToGroup = async ({
  groupId,
  email,
  name,
  role
}: {
  groupId: string;
  email: string;
  name: string;
  role: UserRole;
}) => {
  const response = await httpClient.post(`${urls.groups}/${groupId}/users`, {
    email,
    fullName: name,
    role
  });
  return response.data;
};

export const deleteGroup = async ({ groupId }: { groupId: string }) => {
  const response = await httpClient.delete(`${urls.groups}/${groupId}`);
  return response.data;
};
