import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@mui/icons-material";
import { RenameFileDialog } from "./RenameFileDialog";
import { RemoteFile } from "dto";

export const RenameFileButton = ({ file }: { file: RemoteFile }) => {
  const { t } = useTranslation(["folders"]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <ListItemIcon>
          <EditOutlined sx={{ color: "neutral.w_black" }} />
        </ListItemIcon>
        <ListItemText primary={t("folders:files.rename.title")} />
      </MenuItem>
      <RenameFileDialog
        open={open}
        onClose={() => setOpen(false)}
        file={file}
      />
    </>
  );
};
