export const urls = {
  config: {
    awsCognito: "api/configs/cognito"
  },
  groups: "api/groups",
  notifications: "api/notifications",
  users: "api/users",
  survey: {
    projects: "api/projects",
    templates: "api/templates",
    reports: "api/reports"
  },
  me: "api/me",
  folders: "api/folders"
};
