import { Breadcrumbs, Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { queryKeys } from "api/queryClient";
import { UserAvatar, ViewLoader } from "components/Shared";
import { useNotificationContext } from "contexts/NotificationContext";
import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Paths, useRouteParam } from "routing";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getUserById } from "services/userService";
import { UserDetailsDto } from "dto";
import { UserData } from "./UserData";
import { useMe } from "contexts/Auth";

export interface UserDetailsProps {
  isMe?: boolean;
}

export const UserDetails: React.VFC<UserDetailsProps> = ({ isMe }) => {
  return isMe ? <MyUserDetails /> : <GroupUserDetails />;
};

const GroupUserDetails = () => {
  const userId = useRouteParam("UserId");
  const { displayErrorSnackbar } = useNotificationContext();
  const navigate = useNavigate();
  const { data: user } = useQuery(
    queryKeys.user.byId(userId),
    () => getUserById(userId),
    {
      onError: () => {
        displayErrorSnackbar();
        navigate(`/${Paths.group.base}`);
      }
    }
  );
  return renderUserData(user);
};

const MyUserDetails = () => {
  const me = useMe();
  return renderUserData(me, true);
};

const renderUserData = (user?: UserDetailsDto, isMe = false) =>
  user ? (
    <Box p={6.25}>
      <Paper sx={{ pt: 10, mx: "auto", maxWidth: 1110 }} elevation={0}>
        <Box
          px={12.5}
          pb={7.5}
          borderBottom={1}
          borderColor="neutral.grey_warm_50"
        >
          <Box ml={6} mb={3}>
            <Heading isMe={isMe} user={user} />
          </Box>

          <Box display="flex" alignItems="center" sx={{ flex: 1, minWidth: 0 }}>
            <UserAvatar name={user.name} />
            <Typography variant="h1" mx={4} noWrap>
              {user.name}
            </Typography>
          </Box>
        </Box>

        <Box p={12.5}>
          <UserData user={user} />
        </Box>
      </Paper>
    </Box>
  ) : (
    <ViewLoader />
  );

interface HeadingProps {
  isMe: boolean;
  user: UserDetailsDto;
}

const Heading: React.VFC<HeadingProps> = ({ isMe, user }) => {
  const { t } = useTranslation(["user", "common"]);

  return isMe ? (
    <Breadcrumbs>
      <Button
        size="small"
        startIcon={<ArrowBackIcon />}
        color="secondary"
        component={Link}
        to={`/${Paths.projects.base}`}
      >
        {t("common:menuLinks.projects")}
      </Button>
      <Typography color="textSecondary" variant="subtitle2">
        {t("user:myAccount")}
      </Typography>
    </Breadcrumbs>
  ) : (
    <Breadcrumbs>
      <Button
        size="small"
        startIcon={<ArrowBackIcon />}
        color="secondary"
        component={Link}
        to={`/${Paths.group.base}`}
      >
        {t("common:menuLinks.group")}
      </Button>
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/${Paths.group.base}/${user.group.id}`}
      >
        {user.group.name}
      </Button>
      <Typography noWrap variant="subtitle2">
        {user.name}
      </Typography>
    </Breadcrumbs>
  );
};
