import { httpClient } from "../api/httpClient";
import { urls } from "../api/urls";

export interface ConfigUserPool {
  userPoolId: string;
  clientId: string;
}

export const getUserPoolConfig: () => Promise<ConfigUserPool> = async () => {
  const response = await httpClient.get<ConfigUserPool>(urls.config.awsCognito);
  return response.data;
};
