import {
  Button,
  IconButton,
  LinearProgress,
  CircularProgress
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { useMutation } from "react-query";
import { deleteFile, uploadFile } from "services/projectService";
import { useNotificationContext } from "contexts/NotificationContext";
import { RemoteFile, Stimulus, StimulusMedia } from "dto/Project";
import { mimeTypeToExtension } from "utils";
import { elipsis } from "utils/elipsis";

export interface FileUploaderProps {
  projectId: string;
  stimulus: Stimulus;
  media: StimulusMedia;
  updateStimulusMedia: (
    mediaId: string,
    update: Partial<StimulusMedia>
  ) => void;
  setStimuliDisabled: (disabled: boolean) => void;
  disabled?: boolean;
}

export const FileUploader: React.VFC<FileUploaderProps> = ({
  projectId,
  stimulus,
  updateStimulusMedia,
  media,
  setStimuliDisabled,
  disabled
}) => {
  const remoteFile = media.file;
  const { t } = useTranslation(["editProject"]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };
  const [localFile, setLocalFile] = useState<RemoteFile | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      updateStimulusMedia(media.id, { file: localFile });
      setProgress(100);
      displaySuccessSnackbar(t("editProject:stimuli.mediaUploaded"));
    },
    onError: () => {
      setProgress(0);
      setLocalFile(undefined);
      displayErrorSnackbar();
    }
  });

  const deletionMutation = useMutation(deleteFile, {
    onSuccess: () => {
      setLocalFile(undefined);
      updateStimulusMedia(media.id, { file: undefined });
      setProgress(0);
    },
    onError: () => displayErrorSnackbar()
  });

  const updateFile = (file: File) => {
    setLocalFile(fileToRemoteFile(file));
    uploadMutation.mutate({
      projectId,
      stimulusId: stimulus.id,
      mediaId: media.id,
      file,
      onProgressChange: (p) => setProgress(p)
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      updateFile(file);
    }
  };

  const handleDeleteClick = () => {
    deletionMutation.mutate({
      projectId,
      stimulusId: stimulus.id,
      mediaId: media.id
    });
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    muteEventDefaults(e);
    setIsDragging(false);
    const file = e.dataTransfer?.files?.item(0);
    if (!file) {
      return;
    }
    if (isFileValid(file)) {
      updateFile(file);
    } else {
      displayErrorSnackbar(t("editProject:stimuli.unsupportedFile"));
    }
  };

  useEffect(() => {
    window.addEventListener("dragover", muteEventDefaults);
    window.addEventListener("drop", muteEventDefaults);
    return () => {
      window.removeEventListener("dragover", muteEventDefaults);
      window.removeEventListener("drop", muteEventDefaults);
    };
  }, []);

  useEffect(() => {
    if (!localFile) {
      setProgress(remoteFile ? 100 : 0);
    }
  }, [remoteFile]);

  useEffect(() => {
    setStimuliDisabled(deletionMutation.isLoading || uploadMutation.isLoading);
  }, [uploadMutation.isLoading, deletionMutation.isLoading]);

  const file = remoteFile || localFile;
  if (file) {
    return (
      <LoadedFile>
        <FileType>{mimeTypeToExtension(file.mimeType)}</FileType>
        <div>
          <FileName>
            {file?.link ? (
              <NameLink href={file?.link} target="_blank">
                {elipsis(file.filename)}
              </NameLink>
            ) : (
              <div>{elipsis(file.filename)}</div>
            )}
            {progress !== 100 && <div>{progress}%</div>}
          </FileName>
          {/* Different progresses to prevent animation on already uploaded file*/}
          {progress === 100 ? (
            <ProgressBar variant="determinate" value={100} />
          ) : (
            <ProgressBar variant="determinate" value={progress} />
          )}
        </div>
        {!uploadMutation.isLoading && (
          <ButtonWrapper>
            <IconButton
              onClick={handleDeleteClick}
              disabled={deletionMutation.isLoading || disabled}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            {deletionMutation.isLoading && <CircularProgress size={20} />}
          </ButtonWrapper>
        )}
      </LoadedFile>
    );
  }

  return (
    <Root
      isDragging={isDragging}
      onDrop={handleDrop}
      overlayText={t("editProject:stimuli.dropHere")}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
    >
      <Inner>
        {t("editProject:stimuli.dragAndDropOr")}
        <Button onClick={handleBrowseClick} color="primary" disabled={disabled}>
          {t("editProject:stimuli.browse")}
        </Button>
      </Inner>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="video/*,image/*"
      />
    </Root>
  );
};

const Root = styled.div<{ isDragging: boolean; overlayText: string }>`
  position: relative;
  width: 100%;
  height: 72px;
  background-color: rgba(0, 127, 255, 0.05);
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  place-content: center;
  margin-bottom: 40px;

  input {
    display: none;
  }

  ::after {
    content: "${({ overlayText }) => overlayText}";
    display: ${({ isDragging }) => (isDragging ? "grid" : "none")};
    place-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.light};
    border-radius: 4px;
    font-size: 16px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: baseline;
`;

const ButtonWrapper = styled.div`
  display: grid;
  place-items: center;

  & > * {
    grid-area: 1/1;
  }
`;

const LoadedFile = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 40px;
  align-items: center;
  margin-bottom: 40px;
`;

const FileType = styled.div`
  display: grid;
  place-content: center;
  width: 40px;
  height: 48px;
  margin-right: 12px;
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 4px;
  text-transform: uppercase;
`;

const FileName = styled.div`
  margin-bottom: 4px;
  display: grid;
  grid-template-columns: 1fr auto;
  place-content: center;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const NameLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const ProgressBar = styled(LinearProgress)`
  border-radius: 2px;

  && {
    &.MuiLinearProgress-colorPrimary {
      background-color: ${({ theme }) => theme.palette.neutral.grey_warm_50};
    }

    .MuiLinearProgress-bar {
      border-radius: 2px;
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const muteEventDefaults = (e: Event | React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileToRemoteFile = (file: File): RemoteFile => ({
  id: "",
  mimeType: file.type,
  filename: file.name,
  size: file.size
});

const isFileValid = (file: File): boolean => {
  return file.type.includes("image") || file.type.includes("video");
};
