import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { Paths } from "routing";
import { usePermissions } from "contexts/Auth";

type Config = { label: string; path: string }[];

export const MainTabs: React.VFC = () => {
  const { t } = useTranslation(["common"]);
  const canAccessGroupsAndUsers = usePermissions("canAccessGroupsAndUsers");
  const { pathname } = useLocation();

  const config: Config = [
    ...(canAccessGroupsAndUsers
      ? [
          {
            label: t("common:menuLinks.group"),
            path: `/${Paths.group.base}`
          },
          {
            label: t("common:menuLinks.projects"),
            path: `/${Paths.surveys.base}`
          }
        ]
      : [])
  ];

  const currentPath = useMemo(
    () => findCurrentPath(pathname, config),
    [pathname]
  );

  return (
    <Tabs value={currentPath} textColor="secondary">
      {config.map(({ label, path }) => (
        <Tab component={Link} label={label} value={path} to={path} key={path} />
      ))}
    </Tabs>
  );
};

const findCurrentPath = (pathname: string, config: Config) => {
  for (const { path } of config) {
    if (pathname.includes(path)) {
      return path;
    }
  }
  return false;
};
