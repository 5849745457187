import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
import styled from "styled-components";
import React from "react";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { useFeasibility } from "contexts/FeasibilityContext";
import { CostFeedback } from "./CostFeedback";
import { Paths } from "routing";
import { useLocation } from "react-router";
import { useStaticDataState } from "../../../../contexts/StaticDataContext";
import { InfoIcon } from "components/Shared/InfoIcon";

export const CostCalculator: React.VFC = () => {
  const { t } = useTranslation(["editProject"]);

  const { showCostDialog, isLoading, isError, feasibility } = useFeasibility();
  const {
    currency,
    stimuli,
    cellSize,
    requestedSampleSize,
    availableSampleSize,
    costPerInterview,
    totalCost
  } = feasibility;
  const { stimuliNameAlias, adjustableStimuli } = useStaticDataState();
  const displayStimuliRow = stimuliNameAlias === "Ad" && adjustableStimuli;

  const { pathname } = useLocation();
  const { groupSample } = Paths.surveys.edit;
  const calculateButtonVisible = pathname.includes(groupSample);

  return (
    <Host>
      <Heading>{t("editProject:costsDialog.groupSampleAndCosts")}</Heading>
      {displayStimuliRow && (
        <Row>
          <span>{t("editProject:costsDialog.stimuli")}</span>
          <b>{stimuli}</b>
        </Row>
      )}
      <CellSizeRow>
        <span>{t("editProject:costsDialog.cellSize")}</span>
        <b>{cellSize}</b>
      </CellSizeRow>
      <Row>
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>{t("editProject:costsDialog.requestedSampleSize")}</span>
          <InfoIcon
            tooltip={t("editProject:costsDialog.requestedSampleSizeTooltip")}
          />
        </Stack>
        <b>{requestedSampleSize}</b>
      </Row>
      <Row>
        <span>{t("editProject:costsDialog.availableSampleSize")}</span>
        <b>{availableSampleSize}</b>
      </Row>
      <FeedbackWrapper>
        <CostFeedback
          requestedSampleSize={requestedSampleSize}
          availableSampleSize={availableSampleSize}
          isLoading={isLoading}
          isError={isError}
        />
      </FeedbackWrapper>
      <CostRow>
        <span>{t("editProject:costsDialog.costOrInterview")}</span>
        <b>
          <Currency>{currency}</Currency>
          {costPerInterview}
        </b>
      </CostRow>
      <Row>
        <span>{t("editProject:costsDialog.sampleCostTotal")}</span>
        <b>
          <Currency>{currency}</Currency>
          {totalCost}
        </b>
      </Row>
      {calculateButtonVisible && (
        <CalculateButton
          variant="contained"
          color="primary"
          onClick={showCostDialog}
        >
          {t("editProject:costsDialog.calculateCost")}
          <StyledArrow />
        </CalculateButton>
      )}
    </Host>
  );
};

const Host = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Heading = styled.p`
  margin: 32px 24px;
  font-weight: 700;
`;

const Row = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 12px;
`;

const CellSizeRow = styled(Row)`
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
`;

const CostRow = styled(Row)`
  margin-top: 20px;
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const CalculateButton = styled(Button)`
  margin: 12px auto;
  stroke: ${({ theme }) => theme.palette.secondary.main};
  padding: 10px 20px;
`;

const StyledArrow = styled(ArrowIcon)`
  stroke: white;
  transform: translateY(-1px);
  margin-left: 4px;
`;

const FeedbackWrapper = styled.div`
  margin: 0 12px;
  display: grid;
  place-items: center;
`;
