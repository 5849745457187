import React, { useEffect, useState } from "react";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ManagersSelect } from "../../Create/ManagersSelect";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { addManagerToGroup } from "services/groupService";
import { queryKeys } from "api/queryClient";
import { useRouteParam } from "routing";
import { GroupDetailsDto } from "dto/Group";

export interface AddManagerDialogProps {
  open: boolean;
  group: GroupDetailsDto;
  onClose: () => void;
}

export const AddManagerDialog: React.VFC<AddManagerDialogProps> = ({
  open,
  group,
  onClose
}) => {
  const { t } = useTranslation(["group", "common"]);

  const [managers, setManagers] = useState<string[]>(
    group.managers.map((manager) => {
      return manager.id;
    })
  );

  const queryClient = useQueryClient();

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const groupId = useRouteParam("GroupId");

  useEffect(() => {
    setManagers(group.managers.map((manager) => manager.id));
  }, [group]);

  const { isLoading, mutate } = useMutation(addManagerToGroup, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.group.byId(groupId));
      displaySuccessSnackbar(
        t("group:addManager.successFeedback", { groupName: group.name })
      );
      onClose();
    },
    onError: () => displayErrorSnackbar()
  });

  const onAddClick = () => {
    mutate({ groupId, managers });
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      header={
        <>
          <Typography variant="h2" align="center">
            {t("group:create.managersList")}
          </Typography>
        </>
      }
      body={
        <>
          <Box mx={-2.5}>
            <ManagersSelect value={managers} onChange={setManagers} />
          </Box>
        </>
      }
      footer={
        <>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={onClose}
          >
            {t("common:cancel")}
          </Button>

          <LoadingButton
            isLoading={isLoading}
            variant="contained"
            size="large"
            color="primary"
            onClick={onAddClick}
          >
            {t("common:assign")}
          </LoadingButton>
        </>
      }
    />
  );
};
