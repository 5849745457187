import {
  CheckCircle,
  Download,
  Folder,
  ManageAccounts,
  Search
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { queryClient, queryKeys } from "api/queryClient";
import { ProjectsContainer } from "components/Projects/ProjectsContainer";
import { MoreButton, QueryErrorFeedback, ViewLoader } from "components/Shared";
import { Paginator } from "components/Shared/Pagination";
import { FolderListItem, FolderSortParam, FolderStatus } from "dto/Folder";
import { ProjectTab } from "dto/Project";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Paths } from "routing";
import { deleteFolder, getAllFolders } from "services/foldersService";
import { useDebounce } from "utils";
import { CreateFolderButton } from "../Create";
import { ReactComponent as ResultsEmpty } from "assets/icons/results-empty.svg";
import { ReactComponent as AssetsEmpty } from "assets/icons/assets-empty.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useMe, usePermissions } from "contexts/Auth";
import { EditFolderButton } from "../Edit";
import { useNotificationContext } from "contexts/NotificationContext";
import { useInfiniteGroupsList } from "components/Projects/Survey/List/SurveyList";

const size = 9;

const availableSortParams: FolderSortParam[] = [
  "NAME",
  "CREATED_AT",
  "LAST_EDIT",
  "FILES_COUNT"
];

const availableStatuses: FolderStatus[] = ["ANY", "FINISHED", "NOT_FINISHED"];

export const FoldersList = () => {
  const { t } = useTranslation(["folders", "common", "surveyList"]);
  const { canCreate, canEdit, canDelete } = usePermissions("folderPermissions");
  const canAccessGroupsAndUsers = usePermissions("canAccessGroupsAndUsers");
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<FolderStatus>("ANY");
  const [sortBy, setSortBy] = useState<FolderSortParam>("CREATED_AT");
  const [search, setSearch] = useState<string>();
  const [group, setGroup] = useState<string>();
  const debouncedSearch = useDebounce(search, 800);
  const { managedGroups } = useMe();
  const { groups, fetchNextPage, resetList } = useInfiniteGroupsList();

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const {
    data: pageable,
    refetch,
    isError
  } = useQuery(
    queryKeys.folders.list({
      page,
      size,
      sort: sortBy,
      status,
      group,
      search: debouncedSearch
    }),
    () =>
      getAllFolders({
        page,
        size,
        sort: sortBy,
        status,
        group,
        search: debouncedSearch
      })
  );

  const hasFilters = () => {
    return status !== "ANY" || sortBy !== "CREATED_AT" || debouncedSearch;
  };

  const deleteMutation = useMutation(deleteFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.folders.all);
      displaySuccessSnackbar(t("folders:files.delete.successFeedback"));
    },
    onError: () => displayErrorSnackbar()
  });

  useEffect(() => {
    resetList();
    fetchNextPage();
  }, []);

  useEffect(() => {
    // reset pagination on filter changes
    setPage(0);
  }, [status, group, debouncedSearch]);

  return (
    <ProjectsContainer
      tab={ProjectTab.FILES}
      createButton={canCreate && <CreateFolderButton />}
    >
      <Box display="flex" justifyContent="space-between" mt={8}>
        <Box>
          <FormControl sx={{ width: 175, mr: 5 }}>
            <InputLabel id="status-filter-label">
              {t("folders:listFilters.status.title")}
            </InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              label="Status"
              value={status}
              onChange={(event) =>
                setStatus(event.target.value as FolderStatus)
              }
            >
              {availableStatuses.map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`folders:listFilters.status.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {canAccessGroupsAndUsers && (
            <FormControl
              sx={{
                width: 175,
                mr: 5,
                ".MuiSelect-select svg": { display: "none" }
              }}
            >
              <InputLabel id="group-filter-label">
                {t("surveyList:listFilters.group.title")}
              </InputLabel>
              <Select
                labelId="group-filter-label"
                id="group-filter"
                label="Group"
                value={group}
                MenuProps={{
                  PaperProps: {
                    onScroll: fetchNextPage
                  },
                  MenuListProps: {
                    sx: { maxHeight: 200 }
                  }
                }}
                onChange={(event) => setGroup(event.target.value)}
              >
                <MenuItem>{t("common:any")}</MenuItem>
                {groups &&
                  groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {managedGroups &&
                        managedGroups.find(
                          (managedGroup) => managedGroup.id === group.id
                        ) && (
                          <ManageAccounts
                            color="primary"
                            sx={{ width: 15, mr: 1 }}
                          />
                        )}
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <FormControl sx={{ width: 175 }}>
            <InputLabel id="sortby-filter-label">
              {t("folders:listFilters.sortBy.title")}
            </InputLabel>
            <Select
              labelId="sortby-filter-label"
              id="sortby-filter"
              label="Sort by"
              value={sortBy}
              onChange={(event) =>
                setSortBy(event.target.value as FolderSortParam)
              }
            >
              {availableSortParams.map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`folders:listFilters.sortBy.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: 260, ml: 2 }}>
          <TextField
            margin="dense"
            fullWidth
            placeholder={t("folders:listFilters.search.placeholder")}
            variant="standard"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: <Search />
            }}
          />
        </Box>
      </Box>
      <Box mt={10} sx={{ minHeight: 350 }}>
        {isError ? (
          <QueryErrorFeedback retry={refetch} />
        ) : pageable ? (
          pageable.content.length > 0 ? (
            <Grid container spacing={6}>
              {pageable.content.map((folder: FolderListItem) => (
                <Grid key={folder.id} item md={4}>
                  <Card
                    variant="outlined"
                    sx={{
                      position: "relative"
                    }}
                  >
                    <Box
                      sx={{ position: "absolute", right: 8, top: 4, zIndex: 1 }}
                    >
                      <MoreButton
                        sx={{
                          border: "none",
                          borderRadius: "50%",
                          ":hover": { border: "none" },
                          padding: "5px",
                          ml: 2
                        }}
                        renderItems={(closePopper) => (
                          <Box>
                            <EditFolderButton
                              folder={folder}
                              disabled={!canEdit}
                              handleClosePopper={closePopper}
                            />
                            <MenuItem
                              sx={{ color: "error.main" }}
                              onClick={() =>
                                deleteMutation.mutate({ folderId: folder.id })
                              }
                              disabled={
                                deleteMutation.isLoading ||
                                folder.isFinished ||
                                !canDelete
                              }
                            >
                              <ListItemIcon>
                                <TrashIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={t("folders:buttons.deleteFolder")}
                              />
                              {deleteMutation.isLoading && (
                                <CircularProgress size={20} />
                              )}
                            </MenuItem>
                          </Box>
                        )}
                      />
                    </Box>
                    <CardActionArea
                      href={`${Paths.folders.base}/${folder.id}`}
                      sx={{
                        p: 6
                      }}
                    >
                      <FolderIcon isFinished={folder.isFinished} />
                      <Typography noWrap variant="h4" mt={15} mb={2}>
                        {folder.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {folder.numberOfFiles}&nbsp;{t("folders:title")}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : hasFilters() ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={20}
              mb={30}
            >
              <ResultsEmpty />
              <Typography variant="h2" sx={{ mt: 5 }}>
                {t("common:empty.title")}
              </Typography>
              <Typography variant="subtitle1" color="neutral.grey_dark">
                {t("common:empty.subtitle")}
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={20}
            >
              <AssetsEmpty />
              <Typography variant="h2" sx={{ mt: 5 }}>
                {t("folders:emptyFallback")}
              </Typography>
              {canCreate && (
                <Box mt={8}>
                  <CreateFolderButton small />
                </Box>
              )}
              <Box mt={4} mb={30}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  disabled
                  startIcon={<Download />}
                >
                  {t("common:userDropdown.guide")}
                </Button>
              </Box>
            </Box>
          )
        ) : (
          <ViewLoader />
        )}
      </Box>
      {pageable && pageable.content.length > 0 && (
        <Paginator
          resourceName={t("folders:resourceName")}
          page={page}
          pageable={pageable}
          setPage={setPage}
          size={size}
        />
      )}
    </ProjectsContainer>
  );
};

const FolderIcon = ({ isFinished }: { isFinished: boolean }) => {
  return (
    <Box sx={{ position: "relative", m: -2 }}>
      <Folder
        sx={{
          color: "#F8C821",
          width: 50,
          height: 48,
          opacity: isFinished ? 1 : 0.5
        }}
      />
      {isFinished && (
        <CheckCircle
          color="primary"
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #fff",
            borderRadius: "50%",
            position: "absolute",
            bottom: 6,
            left: 35,
            width: 18,
            height: 18
          }}
        />
      )}
    </Box>
  );
};
