import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExternalLink } from "../../assets/icons/external-link.svg";
import { useNavigate } from "react-router";
import { Paths } from "routing";
import { CircularProgress, Tooltip } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import { usePreview } from "./usePreview";
import { usePermissions } from "contexts/Auth";

export interface FlatButtonProps {
  id: string;
  withEdit?: boolean;
}

export const FlatButton: React.FC<FlatButtonProps> = ({
  id,
  withEdit = false
}) => {
  const { t } = useTranslation(["surveyList"]);

  const { canOpenInTivian } = usePermissions("projectPermissions");

  const navigate = useNavigate();

  const handleEditClick = () => {
    const { base, edit } = Paths.surveys;
    const path = `/${base}/${edit.base}/${id}/${edit.groupSample}`;
    navigate(path);
  };

  const { handlePreviewClick, isPreviewLoading } = usePreview(id);

  const loader = <CircularProgress size={18} />;

  return (
    <>
      <ButtonGroup
        variant="outlined"
        color="secondary"
        aria-label="split button"
      >
        {withEdit && (
          <StyledButton
            variant="outlined"
            onClick={handleEditClick}
            startIcon={<EditIcon />}
          >
            {t("surveyList:edit")}
          </StyledButton>
        )}
        <StyledMenuList id="split-button-menu">
          <Tooltip title={t("surveyList:preview")}>
            <MenuItem
              onClick={handlePreviewClick((data) => data.previewUrl)}
              sx={{ mr: 2 }}
              key={0}
              disabled={isPreviewLoading}
            >
              {isPreviewLoading ? (
                loader
              ) : (
                <VisibilityOutlined color="primary" />
              )}
            </MenuItem>
          </Tooltip>
          {canOpenInTivian && (
            <Tooltip title={t("surveyList:openInTivian")}>
              <MenuItem
                onClick={handlePreviewClick((data) => data.questback)}
                key={1}
                disabled={isPreviewLoading}
              >
                {isPreviewLoading ? loader : <ExternalLink />}
              </MenuItem>
            </Tooltip>
          )}
        </StyledMenuList>
      </ButtonGroup>
    </>
  );
};

const StyledButton = styled(Button)`
  && {
    height: 32px;
    font-size: 13px;

    .MuiButton-label {
      margin-top: 2px;
    }

    .MuiButton-startIcon {
      margin-bottom: 2px;
    }
  }
`;

const StyledMenuList = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 125px;

  && {
    .MuiMenuItem-root {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 55px;
      font-size: 15px;
      border-radius: 4px;
    }
  }
`;
