import styled from "styled-components";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface ChooseOptionFieldProps {
  maxOptionsNumber: number;
  initialValue: number;
  onValueSelected: (value: number) => void;
  disabled?: boolean;
}

export const ChooseOptionField: React.FC<ChooseOptionFieldProps> = ({
  maxOptionsNumber,
  initialValue,
  onValueSelected,
  disabled
}) => {
  const options = [...new Array(maxOptionsNumber)].map((_, i) => i + 1);

  const [state, setState] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== state) {
      onValueSelected(state);
    }
  }, [state, initialValue, onValueSelected]);

  const handleSelect = (option: number) => {
    setState(option);
  };

  return (
    <ButtonWrapper>
      {options.map((option) =>
        state !== option ? (
          <StyledButon
            key={option}
            variant="outlined"
            color="secondary"
            onClick={() => handleSelect(option)}
            disabled={disabled}
          >
            {option}
          </StyledButon>
        ) : (
          <StyledButon
            key={option}
            variant="outlined"
            color="primary"
            onClick={() => handleSelect(option)}
            disabled={disabled}
          >
            {option}
          </StyledButon>
        )
      )}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButon = styled(Button)`
  && {
    margin-right: 8px;
    &.MuiButton-root {
      min-width: 40px;
    }

    .MuiButton-label {
      margin-top: 4px;
    }

    &.MuiButton-outlinedPrimary {
      background-color: rgba(0, 127, 255, 0.1);
    }
  }
`;
