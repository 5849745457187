import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const allowedValues = ["asc", "desc"] as const;

export type SortOrder = typeof allowedValues[number];

export type SortSetting = { fieldName: string; order: SortOrder } | undefined;

export const useUrlSortSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rawParam = useMemo(
    () => getRawParam(searchParams),
    [searchParams.get("sort")]
  );
  const sortSettings = useMemo(() => getSortSettings(rawParam), [rawParam]);

  const setSortSettings = useCallback(
    (setting: SortSetting) => {
      setSearchParams(
        setting ? { sort: `${setting.fieldName},${setting.order}` } : {}
      );
    },
    [setSearchParams]
  );

  return { rawParam, sortSettings, setSortSettings };
};

const getRawParam = (searchParams: URLSearchParams) => {
  const param = searchParams.get("sort");
  if (!param) {
    return undefined;
  }
  const parts = param.split(",");
  if (parts.length !== 2 || !allowedValues.includes(parts[1] as any)) {
    return undefined;
  }
  return param;
};

const getSortSettings = (rawParam?: string) => {
  if (!rawParam) {
    return undefined;
  }
  const [fieldName, value] = rawParam.split(",");
  return { fieldName, order: value } as SortSetting;
};
