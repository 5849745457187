import { Box, Button, Typography } from "@mui/material";
import { useMe } from "contexts/Auth";
import { useEffect, useState } from "react";
import { CloseableDialog } from "./CloseableDialog";
import { ReactComponent as Confetti } from "assets/icons/confetti.svg";
import { useTranslation } from "react-i18next";

export const Welcome = () => {
  const { t } = useTranslation(["common"]);
  const [isOpen, setIsOpen] = useState(false);
  const { lastActive } = useMe();

  useEffect(() => {
    setIsOpen(isWelcomeActive);
  }, []);

  const isWelcomeActive = () => {
    const hasVisitedBefore = lastActive !== 0;
    const hasLocalStorageItem = localStorage.getItem("EyeSquareFirstVisit");
    if (hasVisitedBefore) return false;
    if (hasLocalStorageItem) return false;

    return true;
  };

  const handleCloseWelcome = () => {
    localStorage.setItem("EyeSquareFirstVisit", "true");
    setIsOpen(false);
  };

  return (
    <CloseableDialog
      open={isOpen}
      onClose={handleCloseWelcome}
      body={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ textAlign: "center", maxWidth: 380, m: "auto" }}
        >
          <Confetti />
          <Typography mt={5} variant="h2">
            {t("common:welcome.title")}
          </Typography>
          <Typography mt={3} mb={8} variant="body1" color="neutral.grey_dark">
            {t("common:welcome.subtitle")}
          </Typography>
          <Button
            sx={{ width: 120 }}
            onClick={handleCloseWelcome}
            variant="contained"
            color="primary"
          >
            {t("common:ok")}
          </Button>
        </Box>
      }
    />
  );
};
