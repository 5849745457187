import { Stimulus } from "dto/Project";
import { Link, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useProjectState } from "contexts/ProjectStateContext";
import { StimulusMediaForm } from "./StimulusMediaForm";
import { useStaticDataState } from "../../../../../contexts/StaticDataContext";
import styled from "styled-components";
import { DisplayMode } from "../EditProject";

export interface StimulusFormProps {
  stimulusIndex: number;
  stimulus: Stimulus;
  updateStimulus: (update: Partial<Stimulus>) => void;
  setStimuliDisabled: (disabled: boolean) => void;
  displayMode: DisplayMode;
}

const i18nBase = "editProject:stimuli";

export const StimulusForm: React.VFC<StimulusFormProps> = ({
  stimulusIndex,
  stimulus,
  updateStimulus,
  setStimuliDisabled,
  displayMode
}) => {
  const projectId = useProjectState().project.id;
  const { t } = useTranslation(["editProject"]);
  const firstInputRef = useRef<HTMLInputElement>();
  const [incontextId, setIncontextId] = useState("");
  const [incontextIdError, setIncontextIdError] = useState(false);
  const isEditDisabled = displayMode !== "edit";

  useEffect(() => {
    setIncontextId(stimulus.incontextId);
  }, [stimulus.incontextId]);

  const { stimuliNameAlias } = useStaticDataState();

  useEffect(() => {
    firstInputRef.current?.focus();
  }, [stimulusIndex]);

  const updateIncontextId = (value: string) => {
    setIncontextId(value);
    const regexp = /^\d/;
    const matched = regexp.test(value);
    if (value.length > 0 && !matched) {
      setIncontextIdError(true);
    } else {
      setIncontextIdError(false);
      updateStimulus({ incontextId: value });
    }
  };

  return (
    <>
      <Stack spacing={6}>
        <StyledTextField
          id="ad-name-input"
          label={t(`${i18nBase}.adName`, {
            stimuliAlias: stimuliNameAlias
          })}
          variant="outlined"
          value={stimulus.name}
          onChange={(e) => updateStimulus({ name: e.target.value })}
          inputRef={firstInputRef}
          fullWidth
          disabled={isEditDisabled}
        />
        <Link
          href="https://portal.incontext-research.com/projects"
          variant="button"
          target="_blank"
          alignSelf="flex-start"
          underline="hover"
        >
          {t(`${i18nBase}.configureInContext`)}
        </Link>
        <StyledTextField
          id="incontext-project-id-input"
          label={t(`${i18nBase}.incontextProjectId`)}
          variant="outlined"
          error={incontextIdError}
          helperText={incontextIdError ? t(`${i18nBase}.incontextIdError`) : ""}
          value={incontextId}
          onChange={(e) => updateIncontextId(e.target.value)}
          fullWidth
          disabled={isEditDisabled}
        />
      </Stack>
      <StimulusMediaForm
        stimulus={stimulus}
        updateStimulus={updateStimulus}
        setStimuliDisabled={setStimuliDisabled}
        projectId={projectId}
        displayMode={displayMode}
      />
    </>
  );
};

const StyledTextField = styled(TextField)`
  text-transform: uppercase;
`;
