import { Placeholder, PlaceholderDetails } from "../../../../../../dto";
import React from "react";
import { FormControl, TextField } from "@mui/material";
import styled from "styled-components";

type TextFieldProps = {
  placeholderConfig: PlaceholderDetails;
  placeholder: Placeholder;
  updatePlaceholderValue: (key: string, value: string) => void;
  autofocus: boolean;
  disabled?: boolean;
};

export const TextInput: React.FC<TextFieldProps> = ({
  placeholderConfig: { key, label, sentence },
  placeholder: { text: value },
  updatePlaceholderValue,
  autofocus,
  disabled
}) => {
  return (
    <>
      {sentence && <Sentence>{sentence}</Sentence>}
      <FormControlWrapper>
        <StyledTextField
          fullWidth
          id={key}
          label={label}
          variant="outlined"
          value={value}
          onChange={(e) => {
            updatePlaceholderValue(key, e.target.value || "");
          }}
          autoFocus={autofocus}
          disabled={disabled}
        />
      </FormControlWrapper>
    </>
  );
};

const Sentence = styled.p`
  margin: 0 0 24px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const FormControlWrapper = styled(FormControl)`
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  text-transform: uppercase;
`;
