import React, { useState } from "react";
import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

export interface InfoIconProps {
  tooltip: string;
}

const size = "20px";

export const InfoIcon: React.VFC<InfoIconProps> = ({ tooltip }) => {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box width={size} height={size}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          title={tooltip}
        >
          <Info
            color="primary"
            sx={{ cursor: "pointer", fontSize: size }}
            onClick={(e) => {
              e.stopPropagation();
              setOpen((p) => !p);
            }}
          />
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};
