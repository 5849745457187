import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Device } from "../../../../../dto/ProjectConfiguration";

export interface SupportedDevicesFieldProps {
  defaultSupportedDevices: Device[];
  onChange: (devices: Device[]) => void;
  disabled?: boolean;
}

export const SupportedDevicesField: React.FC<SupportedDevicesFieldProps> = ({
  defaultSupportedDevices,
  onChange,
  disabled
}) => {
  const isFirstRef = useRef(true);
  const { t } = useTranslation(["editProject"]);

  const availableSupportedDevices = Object.values(Device).map(
    (supportedDeviceType) => {
      return {
        code: supportedDeviceType,
        checked: defaultSupportedDevices.includes(supportedDeviceType)
      };
    }
  );

  const initialState = Object.assign(
    {},
    ...availableSupportedDevices.map((a) => ({ [a.code]: a.checked }))
  );

  const [state, setState] = React.useState(initialState);

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState: typeof state) => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }));
  };

  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }
    const arrayOfState = Object.keys(state).filter(
      (key) => state[key] === true
    ) as Device[];
    return onChange(arrayOfState);
  }, [state]);

  return (
    <FormGroup>
      {availableSupportedDevices.map((option) => (
        <FormControlLabel
          key={option.code}
          control={
            <Checkbox
              key={option.code}
              checked={state[option.code]}
              onChange={handleCheckboxClick}
              name={option.code}
              color="primary"
              disabled={disabled}
            />
          }
          label={t(`editProject:groupSample.deviceType.${option.code}`)}
        />
      ))}
    </FormGroup>
  );
};
