import React, { useState } from "react";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ManagersSelect } from "./ManagersSelect";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { createGroup } from "services/groupService";
import { queryKeys } from "api/queryClient";
import { useNavigate } from "react-router";

export interface CreateGroupDialogProps {
  open: boolean;
  onClose: () => void;
}

type CreatePayload = Parameters<typeof createGroup>[0];

export const CreateGroupDialog: React.VFC<CreateGroupDialogProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation(["group", "common"]);

  const [payload, setPayload] = useState<CreatePayload>({
    name: "",
    managers: []
  });

  const queryClient = useQueryClient();
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const navigate = useNavigate();
  const { isLoading, mutate } = useMutation(createGroup, {
    onSuccess: ({ id, name }) => {
      displaySuccessSnackbar(t("group:create.successFeedback", { name }));
      queryClient.invalidateQueries(queryKeys.group.all);
      navigate(id);
    },
    onError: () => displayErrorSnackbar()
  });

  const onCreate = () => {
    const { name, managers } = payload;
    if (!name) {
      displayErrorSnackbar(t("group:create.wrongInputFeedback"));
      return;
    }
    mutate({ name, managers });
  };

  const updatePayload = <T extends keyof CreatePayload>(
    fieldName: T,
    value: CreatePayload[T]
  ) => setPayload((prevPayload) => ({ ...prevPayload, [fieldName]: value }));

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      header={<DialogHeader />}
      body={<DialogBody payload={payload} updatePayload={updatePayload} />}
      footer={
        <DialogFooter
          isLoading={isLoading}
          onClose={onClose}
          onCreate={onCreate}
        />
      }
    />
  );
};

const DialogHeader: React.VFC = () => {
  const { t } = useTranslation(["group"]);
  return (
    <>
      <Typography variant="h2" align="center" mb={3}>
        {t("group:create.heading")}
      </Typography>
      <Typography variant="body1" align="center" color="neutral.grey_dark">
        {t("group:create.subheading")}
      </Typography>
    </>
  );
};

interface DialogBodyProps {
  payload: CreatePayload;
  updatePayload: <T extends keyof CreatePayload>(
    fieldName: T,
    value: CreatePayload[T]
  ) => void;
}

const DialogBody: React.VFC<DialogBodyProps> = ({ payload, updatePayload }) => {
  const { t } = useTranslation(["group"]);
  return (
    <>
      <TextField
        margin="dense"
        fullWidth
        label={t("group:create.input.label")}
        variant="outlined"
        value={payload.name}
        onChange={(e) => updatePayload("name", e.target.value)}
        autoFocus
        id="group-name"
      />

      <Typography variant="subtitle2" mt={2}>
        {t("group:create.hint.title")}
      </Typography>

      <Box component="ul" color="neutral.grey_dark" mt={1} pl={6}>
        <Typography variant="body2" component="li">
          {t("group:create.hint.example1")}
        </Typography>
        <Typography variant="body2" component="li" mt={1}>
          {t("group:create.hint.example2")}
        </Typography>
      </Box>

      <Typography variant="subtitle1" mt={8} mb={3}>
        {t("group:create.managersList")}
      </Typography>

      <Box mx={-2.5}>
        <ManagersSelect
          value={payload.managers}
          onChange={(managers) => updatePayload("managers", managers)}
        />
      </Box>
    </>
  );
};

interface DialogFooterProps {
  isLoading: boolean;
  onClose: () => void;
  onCreate: () => void;
}

const DialogFooter: React.VFC<DialogFooterProps> = ({
  isLoading,
  onClose,
  onCreate
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={onClose}
      >
        {t("common:cancel")}
      </Button>

      <LoadingButton
        isLoading={isLoading}
        variant="contained"
        size="large"
        color="primary"
        onClick={onCreate}
      >
        {t("common:create")}
      </LoadingButton>
    </>
  );
};
