import React, { useEffect } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export interface PasswordValidatorProps {
  password: string;
  isError?: boolean;
  onIsValidChange: (isValid: boolean) => void;
}

const i18nBase = "common:passwordValidator";

type Criterion = [boolean, string];

export const PasswordValidator: React.VFC<BoxProps & PasswordValidatorProps> =
  ({ password, isError, onIsValidChange, ...boxProps }) => {
    const { t } = useTranslation(["common"]);

    const isLengthCorrect = validateLength(password);
    const isLetterCorrect = validateLetters(password);
    const isDigitCorrect = validateDigit(password);

    const isValid = isLengthCorrect && isLetterCorrect && isDigitCorrect;
    useEffect(() => onIsValidChange(isValid), [isValid]);

    const config: Criterion[] = [
      [isLengthCorrect, t(`${i18nBase}.lengthCriterion`)],
      [isLetterCorrect, t(`${i18nBase}.lettersCriterion`)],
      [isDigitCorrect, t(`${i18nBase}.digitsCriterion`)]
    ];

    return (
      <Box {...boxProps}>
        <Typography variant="subtitle2">{t(`${i18nBase}.heading`)}</Typography>
        {config.map(([isValid, label]) => (
          <Box
            key={label}
            mt={4}
            display="grid"
            gridTemplateColumns="auto 1fr"
            alignItems="center"
            gap={3}
            color={
              isValid
                ? "success.main"
                : isError
                ? "error.main"
                : "text.secondary"
            }
          >
            {isValid ? (
              <DoneIcon sx={{ fontSize: 20 }} />
            ) : isError ? (
              <CloseIcon sx={{ fontSize: 20 }} />
            ) : (
              <CircleIcon sx={{ fontSize: 20, transform: "scale(0.4)" }} />
            )}
            <Typography variant="body2">{label}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

const validateLength = (value: string) => {
  return value.length >= 10;
};

const validateDigit = (value: string) => {
  return /\d/.test(value);
};

const validateLetters = (value: string) => {
  return /[a-z]+/.test(value) && /[A-Z]+/.test(value);
};
