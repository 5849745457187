import React, { useEffect, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import styled from "styled-components";
import { useStaticDataState } from "contexts/StaticDataContext";
import { useTranslation } from "react-i18next";
import { Age, Gender } from "dto/ProjectConfiguration";

export interface DemographicFieldProps {
  defaultAge: Age;
  defaultGender: Gender;
  onChange: (state: DemographicsState) => void;
  disabled?: boolean;
}

export interface DemographicsState {
  gender: Gender;
  age: Age;
}

export const DemographicField: React.FC<DemographicFieldProps> = ({
  defaultAge,
  defaultGender,
  onChange,
  disabled
}) => {
  const isFirstRef = useRef(true);
  const { t } = useTranslation(["editProject"]);
  const { availableAge } = useStaticDataState();

  const [state, setState] = React.useState<DemographicsState>({
    gender: defaultGender,
    age: defaultAge
  });

  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }
    return onChange(state);
  }, [state.age, state.gender]);

  const availableGenders = Object.values(Gender);

  const handleAgeChange = (event: SelectChangeEvent<string>) => {
    const selectedAge = availableAge.find(
      (age) => age.id === event.target.value
    );
    if (selectedAge) {
      setState((prevState: DemographicsState) => ({
        ...prevState,
        age: selectedAge
      }));
    }
  };

  const handleGenderChange = (event: SelectChangeEvent<Gender>) => {
    const selectedGender = event.target.value as Gender;
    setState((prevState: DemographicsState) => ({
      ...prevState,
      gender: selectedGender
    }));
  };

  const findMinAge = (age: Age) => {
    return Math.min(...age.splits.map((a) => a.from));
  };

  const findMaxAge = (age: Age) => {
    return Math.max(...age.splits.map((a) => a.to));
  };

  const getSplitShares = (age: Age) => {
    return age.splits.map((ageSplit, i) => {
      return (
        <span key={i}>
          {ageSplit.splitShare * 100}% {ageSplit.from} - {ageSplit.to}{" "}
          {i + 1 !== age.splits.length && t("editProject:groupSample.and")}{" "}
        </span>
      );
    });
  };

  return (
    <ControlsRow>
      <FormControl variant="outlined">
        <InputLabel id="gender-select-label">
          {t("editProject:groupSample.gender")}
        </InputLabel>
        <Select
          labelId="gender-select-label"
          id="gender-select-field"
          value={state.gender}
          onChange={handleGenderChange}
          label={t("editProject:groupSample.gender")}
          variant="outlined"
          disabled={disabled}
        >
          {availableGenders.map((option) => (
            <MenuItem key={option} value={option}>
              {t(`editProject:groupSample.genderType.${option}`)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {t(`editProject:groupSample.genderHelperText`)}
        </FormHelperText>
      </FormControl>
      {availableAge.length > 0 && (
        <FormControl variant="outlined">
          <InputLabel id="age-select-label">
            {t("editProject:groupSample.age")}
          </InputLabel>
          <Select
            labelId="age-select-label"
            id="age-select-field"
            value={state.age.id}
            onChange={handleAgeChange}
            label={t("editProject:groupSample.age")}
            variant="outlined"
            disabled={disabled}
          >
            {availableAge.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name} ({findMinAge(option)} - {findMaxAge(option)})
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{getSplitShares(state.age)}</FormHelperText>
        </FormControl>
      )}
    </ControlsRow>
  );
};

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;

  .MuiFormControl-root {
    margin-right: 40px;
  }

  .MuiFormHelperText-root {
    margin-top: 12px;
  }
`;
