import { PageableParams } from "dto";
import { PagedSurveyReports, SurveyReportBody } from "dto/Reports";
import { httpClient } from "./httpClient";
import { urls } from "./urls";

export const getSurveyReports = async (
  projectId: string,
  params: PageableParams
) => {
  const result = await httpClient.get<PagedSurveyReports>(
    `${urls.survey.projects}/${projectId}/reports`,
    {
      params
    }
  );

  return result.data;
};

export const addSurveyReport = async (
  projectId: string,
  body: SurveyReportBody
) => {
  const requestBody: SurveyReportBody = {
    ...body,
    type: "LINK"
  };

  return httpClient.post(
    `${urls.survey.projects}/${projectId}/reports`,
    requestBody
  );
};

export const removeSurveyReport = async (reportId: string) => {
  return httpClient.delete(`${urls.survey.reports}/${reportId}`);
};
