import { Paged } from "./common";
import { Configuration } from "./ProjectConfiguration";

export type PagedProjects = Paged<ListProject>;

export interface Project {
  author: {
    id: string;
    name: string;
  };
  creationDate: string;
  id: string;
  projectName: string;
  status: Status;
  templateId: string;
  previewed: boolean;
}

export interface ListProject extends Project {
  groupName: string;
  finishedSamplesCount: number;
  totalSamplesCount: number;
  templateShortCode: string;
  previewed: boolean;
}

export interface DetailedProject extends Project {
  configuration: Configuration;
  dynataSecurityKey: string;
  placeholders: Placeholder[];
  progress: {
    finishedSamplesCount: number;
  };
  groupName: string;
  referenceNumber: string;
  stimuli: Stimulus[];
}

export interface Placeholder {
  id: string;
  key: string;
  text: string | undefined;
  defaultValues: PlaceholderDefaultValue[];
  allowedValueId: string | undefined;
}

interface PlaceholderDefaultValue {
  value: string;
  locale: string;
}

export interface Stimulus {
  id: string;
  name: string;
  incontextId: string;
  key: string;
  mediaList: StimulusMedia[];
}

export interface StimulusMedia {
  id: string;
  link: string;
  type: MediaType;
  file?: RemoteFile;
  requiredType: RequiredType;
}

export interface RemoteFile {
  id: string;
  mimeType: string;
  filename: string;
  size: number;
  link?: string;
}

export enum MediaType {
  IMAGE_LINK = "IMAGE_LINK",
  VIDEO_LINK = "VIDEO_LINK",
  FILE = "FILE"
}

export enum RequiredType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO"
}

export enum Status {
  ALL = "ALL",
  DRAFT = "DRAFT",
  PAUSED = "PAUSED",
  ACTIVE = "ACTIVE",
  TIMED_OUT = "TIMED_OUT",
  COMPLETED = "COMPLETED",
  QUOTE_REQUESTED = "QUOTE_REQUESTED"
}

export const PreviewStatuses = [Status.ACTIVE, Status.COMPLETED];

export interface ProjectFeasibility {
  costPerInterview: number;
  currency: string;
  expiry: string;
  feasible: boolean;
  requestedTotalCount: number;
  status: FeasibilityStatus;
  totalCount: number;
}

export enum FeasibilityStatus {
  PROCESSING = "PROCESSING",
  READY = "READY",
  FAILED = "FAILED"
}

export enum ProjectTab {
  SURVEYS = "SURVEYS",
  FILES = "FILES",
  TEMPLATES = "TEMPLATES"
}
