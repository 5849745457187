import { isUserAuthenticated, signIn, signOut } from "auth/auth-client";
import { clearUserAuth } from "auth/auth-storage";
import { ViewLoader } from "components/Shared";
import { UserDetailsDto } from "dto";
import { Permissions } from "dto/User";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getMe } from "services/userService";

const AuthContext =
  createContext<ReturnType<typeof useContextValue>>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const value = useContextValue();

  return value ? (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  ) : (
    <ViewLoader fullPage />
  );
};

export const useAuth = () => {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error("Cannot find AuthProvider");
  }
  return value;
};

export const useMe = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error("User not authenticated");
  }
  return user;
};

export const usePermissions = <K extends keyof Permissions>(
  permissionsType: K
) => {
  const { permissions } = useMe();
  return permissions[permissionsType];
};

const useContextValue = () => {
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState<UserDetailsDto>();

  const initialization = async () => {
    if (isUserAuthenticated()) {
      try {
        const user = await getMe();
        setUser(user);
      } catch {
        setUser(undefined);
        clearUserAuth();
      }
    }
    setIsReady(true);
  };

  useEffect(() => {
    initialization();
  }, []);

  const login = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        await signIn(email, password);
        const user = await getMe();
        setUser(user);
      } catch (error: any) {
        setUser(undefined);
        clearUserAuth();
        throw error;
      }
    }
  );

  const logout = useMutation(async () => {
    await signOut();
    setUser(undefined);
  });

  const isAuthenticated = !!user;

  return isReady ? { user, isAuthenticated, login, logout } : undefined;
};
