import { Typography, Box, TextField, Button } from "@mui/material";
import { queryKeys } from "api/queryClient";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { useNotificationContext } from "contexts/NotificationContext";
import { UserDetailsDto } from "dto";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation } from "react-query";
import { updateUsername } from "services/userService";

interface EditNameDialogProps {
  open: boolean;
  user: UserDetailsDto;
  onClose: () => void;
}

export const EditNameDialog: React.VFC<EditNameDialogProps> = ({
  open,
  user,
  onClose
}) => {
  const { t } = useTranslation(["user", "common"]);
  const [name, setName] = useState("");
  useEffect(() => {
    setName(user.name);
  }, [user]);

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateUsername, {
    onSuccess: async () => {
      displaySuccessSnackbar(t("user:updateUsername.successFeedback"));
      await queryClient.invalidateQueries(queryKeys.user.all);
      onClose();
    },
    onError: () => {
      displayErrorSnackbar();
    }
  });

  const handleConfirmChange = () => {
    mutate({ userId: user.id, name });
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      body={
        <>
          <Typography variant="h2" align="center" mb={10}>
            <b>{t("user:updateUsername.heading")}</b>
          </Typography>
          <Box>
            <TextField
              margin="dense"
              fullWidth
              name="name"
              label={t("user:userName")}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </Box>
          <Box mt={10} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={onClose}
            >
              {t("common:cancel")}
            </Button>

            <LoadingButton
              isLoading={isLoading}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleConfirmChange}
            >
              {t("common:confirm")}
            </LoadingButton>
          </Box>
        </>
      }
    />
  );
};
