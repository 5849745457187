import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info.svg";
import type { InfoBox } from "dto/Template";
import { CloseableDialog } from "components/Shared";

type DefaultStatementsDialogProps = {
  infoBoxDetails: InfoBox;
};

export const DefaultStatementsDialog: React.FC<DefaultStatementsDialogProps> =
  ({ infoBoxDetails }) => {
    const [dialogOpenState, setDialogOpenState] = useState(false);

    if (!infoBoxDetails.content) {
      return null;
    }

    return (
      <>
        <StyledButton
          sx={{ svg: { transform: "translateY(-3px)" } }}
          variant="text"
          color="primary"
          endIcon={<InfoIcon height={16} width={16} />}
          onClick={() => setDialogOpenState(true)}
          disableRipple
        >
          {infoBoxDetails.title}
        </StyledButton>
        <CloseableDialog
          open={dialogOpenState}
          onClose={() => setDialogOpenState(false)}
          fullWidth
          maxWidth="sm"
          body={
            <Content
              type={infoBoxDetails.type}
              content={infoBoxDetails.content}
            />
          }
        />
      </>
    );
  };

const Content = ({ type, content }: Pick<InfoBox, "type" | "content">) => {
  switch (type) {
    case "HTML":
      return (
        <HtmlContentWrap
          dangerouslySetInnerHTML={{ __html: content }}
        ></HtmlContentWrap>
      );

    default:
      return <>{content}</>;
  }
};

const StyledButton = styled(Button)`
  background: none !important;
  padding: 0;
`;

const HtmlContentWrap = styled.div`
  font-size: 15px;

  p {
    margin: 0 0 12px;
  }
`;
