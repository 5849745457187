import React from "react";
import { useTranslation } from "react-i18next";
import { ManagedGroupDto } from "dto";
import { TableCell, Typography } from "@mui/material";
import { DataTable, GroupAvatar } from "components/Shared";
import styled from "styled-components";

export interface ManagedGroupsTableProps {
  groups: ManagedGroupDto[];
}

export const ManagedGroupsTable: React.FC<ManagedGroupsTableProps> = ({
  groups
}) => {
  const { t } = useTranslation(["user"]);
  const i18nBase = "user:manageGroups";
  return (
    <DataTable
      data={groups}
      emptyFallback={
        <Typography variant="subtitle1" color="textPrimary" textAlign="center">
          {t(`${i18nBase}.emptyFallback`)}
        </Typography>
      }
      columnsNumber={3}
      renderRow={(group) => {
        const { name, usersCount, managersCount } = group;
        return (
          <>
            <TableCell>
              <NameWrapper>
                <GroupAvatar group={group} />
                <Typography variant="subtitle2" noWrap>
                  {name}
                </Typography>
              </NameWrapper>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {t(`${i18nBase}.usersCount`, { usersCount })}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {t(`${i18nBase}.managersCount`, { managersCount })}
              </Typography>
            </TableCell>
          </>
        );
      }}
    />
  );
};

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 19px;
`;
