import { Box, Pagination, Typography } from "@mui/material";
import { Paged } from "dto";
import { useTranslation } from "react-i18next";

interface PaginatorProps<T> {
  pageable: Paged<T>;
  page: number;
  setPage: (v: number) => void;
  size: number;
  resourceName: string;
}

export const Paginator = <T,>({
  pageable,
  page,
  setPage,
  size,
  resourceName
}: PaginatorProps<T>) => {
  const { t } = useTranslation(["common"]);
  return (
    <Box
      sx={{ mt: 16 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Pagination
        count={pageable.totalPages}
        page={page + 1}
        onChange={(_, v) => setPage(v - 1)}
        variant="outlined"
        shape="rounded"
      />
      <Typography variant="body2" color="textSecondary">
        {t("common:paginationSummary", {
          first: page * size + 1,
          last: page * size + pageable.numberOfElements,
          all: pageable.totalElements,
          resourceName
        })}
      </Typography>
    </Box>
  );
};
