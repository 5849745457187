import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { FeasibilityStatus, Project } from "dto/Project";
import { LaunchProjectDialogContent } from "./LaunchProjectDialogContent";
import { getProjectFeasibility } from "services/projectService";
import { delay, getCurrencySymbol } from "utils";
import { useNotificationContext } from "contexts/NotificationContext";
import { queryKeys } from "api/queryClient";
import { CloseableDialog } from "components/Shared";

export interface LaunchFromListDialogProps {
  project: Project | null;
  onClose: () => void;
}

export const LaunchFromListDialog: React.FC<LaunchFromListDialogProps> = ({
  project,
  onClose
}) => {
  const { i18n } = useTranslation();
  const { displayErrorSnackbar } = useNotificationContext();

  const projectId = project?.id || "";
  const {
    data,
    isLoading,
    isError: isRequestError
  } = useQuery(
    queryKeys.projects.feasibilityById(projectId),
    () => getProjectFeasibility(projectId),
    {
      onSuccess: async ({ status }) => {
        await refetchWhenProcessing(status);
      },
      onError: () => displayErrorSnackbar(),
      enabled: !!project
    }
  );

  const queryClient = useQueryClient();
  const refetchWhenProcessing = async (status: FeasibilityStatus) => {
    if (status === FeasibilityStatus.PROCESSING) {
      await delay(800);
      queryClient.invalidateQueries(
        queryKeys.projects.feasibilityById(projectId)
      );
    }
  };

  const calculationsFailed = data?.status === FeasibilityStatus.FAILED;
  const currency = data?.currency
    ? getCurrencySymbol(i18n.language, data.currency)
    : "";
  const totalCount = data?.totalCount ? data.totalCount.toFixed(0) : "";
  const requestedTotalCount = data?.requestedTotalCount;
  const costPerInterview = data?.costPerInterview
    ? data.costPerInterview.toFixed(2)
    : "";
  const totalCost =
    data?.requestedTotalCount && data?.costPerInterview
      ? (data.costPerInterview * data.requestedTotalCount).toFixed(2)
      : "";
  const availableSampleSize =
    totalCount && requestedTotalCount
      ? Math.min(+totalCount, +requestedTotalCount).toFixed(0)
      : "";

  return (
    <CloseableDialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={!!project}
      body={
        project && (
          <LaunchProjectDialogContent
            projectId={project.id}
            isPreviewed={project.previewed}
            onClose={onClose}
            isFeasibilityLoading={
              isLoading || data?.status === FeasibilityStatus.PROCESSING
            }
            isFeasibilityError={isRequestError || calculationsFailed}
            currency={currency}
            costPerInterview={costPerInterview}
            totalCost={totalCost}
            availableSampleSize={availableSampleSize}
          />
        )
      }
    />
  );
};
