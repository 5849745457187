import { Login } from "components/Auth";
import { SurveyList } from "components/Projects/Survey/List";
import { CreateSurvey } from "components/Projects/Survey/CreateSurvey";
import {
  EditProject,
  EditSurvey,
  GroupSample,
  Stimuli
} from "components/Projects/Survey/EditProject";
import { Paths } from "./Paths";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AuthorizedLayout } from "components/Shared/Layout";
import { GroupList } from "components/Group/List";
import { GroupDetails } from "components/Group/Details";
import { RouteParams } from "./routeParams";
import { FoldersList } from "components/Projects/Folders/List";
import { UserDetails } from "components/User";
import { useAuth, usePermissions } from "contexts/Auth";
import { FolderDetails } from "components/Projects/Folders/Details";
import { Permissions } from "dto/User";
import { ActivateAccount } from "components/Auth/ActivateAccount";
import { ResetPassword } from "components/Auth/ResetPassword";
import { NewPassword } from "components/Auth/NewPassword";
import { TemplatesList } from "components/Projects/Templates/TemplatesList";

export const Routing: React.VFC = () => (
  <Routes>
    <Route path={Paths.auth.base} element={<NonAuthGuard />}>
      <Route index element={<Navigate to={Paths.auth.signIn} />} />
      <Route path={Paths.auth.signIn} element={<Login />} />
      <Route path={Paths.auth.activateAccount} element={<ActivateAccount />} />
      <Route path={Paths.auth.resetPassword.base}>
        <Route index element={<ResetPassword />} />
        <Route
          path={Paths.auth.resetPassword.newPassword}
          element={<NewPassword />}
        />
      </Route>
    </Route>

    <Route path={"/"} element={<AuthGuard />}>
      <Route index element={<Navigate to={Paths.surveys.base} />} />
      <Route path={Paths.surveys.base}>
        <Route index element={<SurveyList />} />
        <Route path={Paths.surveys.create} element={<CreateSurvey />} />
        <Route path={Paths.surveys.edit.base} element={<Navigate to="/" />} />
        <Route
          path={`${Paths.surveys.edit.base}/:${RouteParams.ProjectId}`}
          element={
            <PermissionGuard
              permissionsType="projectPermissions"
              canActivate={({ canEdit }) => !!canEdit}
            >
              <EditProject displayMode="edit" />
            </PermissionGuard>
          }
        >
          <Route
            path={Paths.surveys.edit.groupSample}
            element={<GroupSample displayMode="edit" />}
          />
          <Route
            path={Paths.surveys.edit.stimuli}
            element={<Stimuli displayMode="edit" />}
          />
          <Route
            path={Paths.surveys.edit.editSurvey}
            element={<EditSurvey displayMode="edit" />}
          />
        </Route>

        <Route
          path={`${Paths.surveys.preview.base}/:${RouteParams.ProjectId}`}
          element={<EditProject displayMode="preview" />}
        >
          <Route
            path={Paths.surveys.preview.groupSample}
            element={<GroupSample displayMode="preview" />}
          />
          <Route
            path={Paths.surveys.preview.stimuli}
            element={<Stimuli displayMode="preview" />}
          />
          <Route
            path={Paths.surveys.preview.editSurvey}
            element={<EditSurvey displayMode="preview" />}
          />
        </Route>
      </Route>

      <Route path={Paths.folders.base}>
        <Route index element={<FoldersList />} />
        <Route path={`:${RouteParams.FolderId}`} element={<FolderDetails />} />
      </Route>

      <Route
        path={Paths.templates.base}
        element={
          <PermissionGuard
            permissionsType="projectPermissions"
            canActivate={({ canCreate }) => !!canCreate}
          />
        }
      >
        <Route index element={<TemplatesList />} />
      </Route>

      <Route
        path={Paths.group.base}
        element={
          <PermissionGuard
            permissionsType="canAccessGroupsAndUsers"
            canActivate={(canAccessGroupsAndUsers) => !!canAccessGroupsAndUsers}
          />
        }
      >
        <Route index element={<GroupList />} />
        <Route path={`:${RouteParams.GroupId}`}>
          <Route index element={<GroupDetails />} />
          <Route
            path={`:${RouteParams.UserId}`}
            element={
              <PermissionGuard
                permissionsType="userPermissions"
                canActivate={({ canEdit }) => !!canEdit}
              >
                <UserDetails />
              </PermissionGuard>
            }
          />
        </Route>
      </Route>

      <Route path={Paths.accountSettings} element={<UserDetails isMe />} />
    </Route>

    <Route path={"*"} element={<Navigate to="/" />} />
  </Routes>
);

const NonAuthGuard: React.VFC = () => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

const AuthGuard: React.VFC = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <AuthorizedLayout>
      <Outlet />
    </AuthorizedLayout>
  ) : (
    <Navigate to={Paths.auth.base} />
  );
};

const PermissionGuard = <T extends keyof Permissions>({
  permissionsType,
  canActivate,
  children
}: {
  permissionsType: T;
  canActivate: (permissions: Permissions[T]) => boolean;
  children?: JSX.Element;
}) => {
  const permissions = usePermissions(permissionsType);
  if (!permissions) {
    return <Navigate to={Paths.auth.base} />;
  }
  return canActivate(permissions) ? (
    children || <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
