import {
  CircularProgress,
  Link,
  Stack,
  TableCell,
  TextField,
  Typography
} from "@mui/material";
import { useGetSurveyReports } from "api/queries";
import { DataTable } from "components/Shared";
import { useTranslation } from "react-i18next";
import { ReportDialogComponentProps } from "./ReportsDialog";
import { useState } from "react";
import { Paginator } from "components/Shared/Pagination";
import { RemoveReportButton } from "./RemoveReportButton";
import { usePermissions } from "contexts/Auth";

interface ReportsDialogBodyProps extends ReportDialogComponentProps {
  isFormLoading: boolean;
  linkValue: string;
  nameValue: string;
  onLinkChange: (value: string) => void;
  onNameChange: (value: string) => void;
  projectId: string;
}

const size = 10;
const i18nBase = "surveyList:reports.dialog";

export const ReportsDialogBody = ({
  isFormLoading,
  isViewMode,
  linkValue,
  nameValue,
  onLinkChange,
  onNameChange,
  projectId
}: ReportsDialogBodyProps) => {
  const { t } = useTranslation(["surveyList"]);
  const { canDelete } = usePermissions("reportPermissions");

  const [page, setPage] = useState(0);

  const { data, isLoading } = useGetSurveyReports(projectId, {
    page,
    size,
    sort: "creationDate,DESC"
  });

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress size={24} color="primary" />
      </Stack>
    );
  }

  if (isViewMode) {
    return (
      <>
        <DataTable
          columnsNumber={3}
          emptyFallback={
            <Typography
              variant="subtitle1"
              color="textPrimary"
              textAlign="center"
            >
              {t(`${i18nBase}.view.emptyFallback`)}
            </Typography>
          }
          data={data?.content ?? []}
          head={
            <>
              <TableCell width="30%">
                <Typography variant="body2" color="textSecondary">
                  {t(`${i18nBase}.view.table.name`)}
                </Typography>
              </TableCell>
              <TableCell width="60%">
                <Typography variant="body2" color="textSecondary">
                  {t(`${i18nBase}.view.table.link`)}
                </Typography>
              </TableCell>
              <TableCell width="10%" />
            </>
          }
          renderRow={(report) => (
            <>
              <TableCell>
                <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                  {report.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Link href={report.link} variant="body2" target="_blank">
                  {report.link}
                </Link>
              </TableCell>
              <TableCell align="right">
                {canDelete && (
                  <RemoveReportButton
                    projectId={projectId}
                    reportId={report.id}
                  />
                )}
              </TableCell>
            </>
          )}
        />
        {data && (
          <Paginator
            resourceName={t(`${i18nBase}.view.resourceName`)}
            page={page}
            pageable={data}
            setPage={setPage}
            size={data.totalPages}
          />
        )}
      </>
    );
  }

  return (
    <>
      <TextField
        disabled={isFormLoading}
        margin="dense"
        fullWidth
        label={t(`${i18nBase}.add.fields.name`)}
        variant="outlined"
        value={nameValue}
        inputProps={{
          maxLength: 225
        }}
        onBlur={(e) => onNameChange(e.currentTarget.value.trim())}
        onChange={(e) => onNameChange(e.currentTarget.value)}
      />
      <TextField
        disabled={isFormLoading}
        margin="dense"
        fullWidth
        label={t(`${i18nBase}.add.fields.link`)}
        variant="outlined"
        value={linkValue}
        onBlur={(e) => onLinkChange(e.currentTarget.value.trim())}
        onChange={(e) => onLinkChange(e.currentTarget.value)}
      />

      <Typography variant="subtitle2" mt={2}>
        {t(`${i18nBase}.add.hint`)}
      </Typography>
    </>
  );
};
