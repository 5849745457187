import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import { getUserPoolConfig } from "../config/aws-cognito";
import {
  clearUserAuth,
  getStoredToken,
  getStoredUserAuth,
  storeUserAuth
} from "./auth-storage";

let cognitoUserPool: CognitoUserPool;

const getUserPool = async () => {
  if (!cognitoUserPool) {
    const userPoolConfig = await getUserPoolConfig();

    const userPool = {
      UserPoolId: userPoolConfig.userPoolId,
      ClientId: userPoolConfig.clientId
    };
    cognitoUserPool = new CognitoUserPool(userPool);
  }
  return cognitoUserPool;
};

export const isUserAuthenticated = (): boolean => {
  return !!getStoredToken();
};

export const signIn = async (email: string, password: string) => {
  const cognitoUser = await buildCognitoUser(email);
  await authenticateUser(cognitoUser, password);
};

export const activateAccount = async (
  email: string,
  code: string,
  password: string
) => {
  const cognitoUser = await buildCognitoUser(email);
  await authenticateUser(cognitoUser, code, password);
};

export const requestPasswordReset = async (email: string) => {
  const cognitoUser = await buildCognitoUser(email);
  await new Promise((onSuccess, onFailure) => {
    cognitoUser.forgotPassword({ onSuccess, onFailure });
  });
};

export const setNewPassword = async (
  email: string,
  code: string,
  password: string
) => {
  const cognitoUser = await buildCognitoUser(email);
  await new Promise<string>((onSuccess, onFailure) => {
    cognitoUser.confirmPassword(code, password, { onSuccess, onFailure });
  });
};

export const changePassword = async ({
  currentPassword,
  newPassword
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  const cognitoUser = (await getUserPool()).getCurrentUser();
  if (!cognitoUser) {
    return;
  }

  await authenticateUser(cognitoUser, currentPassword);

  await new Promise<void>((resolve, reject) => {
    cognitoUser.changePassword(currentPassword, newPassword, (err) =>
      err ? reject(err) : resolve()
    );
  });
};

const authenticateUser = (
  cognitoUser: CognitoUser,
  password: string,
  newPassword?: string
) => {
  const authDetails = new AuthenticationDetails({
    Username: cognitoUser.getUsername(),
    Password: password
  });

  return new Promise<void>((resolve, reject) => {
    const onSuccess = (session: CognitoUserSession) => {
      storeUserAuth(session);
      resolve();
    };

    const onFailure = (err?: unknown) => {
      reject(err);
    };

    cognitoUser.authenticateUser(authDetails, {
      onSuccess,
      onFailure,
      newPasswordRequired: () => {
        if (!newPassword) {
          return onFailure();
        }
        cognitoUser.completeNewPasswordChallenge(
          newPassword,
          {},
          { onSuccess, onFailure }
        );
      }
    });
  });
};

const refreshSession = (
  cognitoUser: CognitoUser,
  refreshToken: CognitoRefreshToken
) => {
  return new Promise<void>((resolve, reject) => {
    cognitoUser.refreshSession(
      refreshToken,
      (error, session: CognitoUserSession) => {
        if (error) {
          clearUserAuth();
          return reject(error);
        }
        storeUserAuth(session);
        resolve();
      }
    );
  });
};

export const refreshToken = async () => {
  const currentAuth = getStoredUserAuth();
  if (!currentAuth) {
    return;
  }
  const userPool = await getUserPool();
  const cognitoUser = userPool.getCurrentUser();
  const refreshToken = new CognitoRefreshToken({
    RefreshToken: currentAuth.refreshToken
  });
  if (cognitoUser) {
    await refreshSession(cognitoUser, refreshToken);
  }
};

export const signOut = async () => {
  const userPool = await getUserPool();
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.signOut();
  clearUserAuth();
};

const buildCognitoUser = async (email: string) => {
  return new CognitoUser({
    Username: email,
    Pool: await getUserPool()
  });
};
