import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { useRemoveSurveyReport } from "api/mutations";
import { CloseableDialog, LoadingButton } from "components/Shared";
import ImageIconButton from "components/Shared/ImageIconButton";
import { useTranslation } from "react-i18next";
import trash from "assets/icons/trash.svg";
import { useOpen } from "utils/useOpen";
import { useNotificationContext } from "contexts/NotificationContext";

interface RemoveReportButtonProps {
  projectId: string;
  reportId: string;
}

const i18nBase = "surveyList:reports.dialog.view.remove";

export const RemoveReportButton = ({
  projectId,
  reportId
}: RemoveReportButtonProps) => {
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const { t } = useTranslation(["surveyList", "common"]);

  const { close, open, isOpen } = useOpen();

  const { mutate, isLoading } = useRemoveSurveyReport();

  const handleRemoveReport = () => {
    mutate(
      { projectId, reportId },
      {
        onSuccess: () => {
          close();
          displaySuccessSnackbar(t(`${i18nBase}.successFeedback`));
        },
        onError: () => {
          displayErrorSnackbar();
        }
      }
    );
  };

  return (
    <>
      <Tooltip title={t(`${i18nBase}.tooltip`)}>
        <ImageIconButton
          disabled={isLoading}
          onClick={open}
          iconSource={trash}
          iconAlt="remove icon"
        />
      </Tooltip>

      <CloseableDialog
        onClose={close}
        open={isOpen}
        header={
          <Typography variant="h2" align="center" mb={3}>
            {t(`${i18nBase}.dialog.header`)}
          </Typography>
        }
        body={
          <Stack direction="row" justifyContent="space-between">
            <Button
              disabled={isLoading}
              variant="outlined"
              size="large"
              color="secondary"
              onClick={close}
            >
              {t("common:cancel")}
            </Button>

            <LoadingButton
              isLoading={isLoading}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleRemoveReport}
            >
              {t("common:confirm")}
            </LoadingButton>
          </Stack>
        }
      />
    </>
  );
};
