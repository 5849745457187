import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { Paths, useRouteParam } from "routing";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { useTranslation } from "react-i18next";
import { useStaticDataState } from "../../../../contexts/StaticDataContext";
import { DisplayMode } from "./EditProject";

const getConfig = (adjustableStimuli: boolean, mode: DisplayMode) => {
  const baseConfig = [
    {
      labelKey: "sample",
      path: Paths.surveys[mode].groupSample
    },
    {
      labelKey: "editSurvey",
      path: Paths.surveys[mode].editSurvey
    }
  ] as const;

  if (adjustableStimuli) {
    const [first, second] = baseConfig;

    return [
      first,
      {
        labelKey: "stimuli.header",
        path: Paths.surveys[mode].stimuli
      },
      second
    ] as const;
  }

  return baseConfig;
};

type BreadcrumbsProps = {
  displayMode: DisplayMode;
};

export const Breadcrumbs: React.VFC<BreadcrumbsProps> = ({ displayMode }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const id = useRouteParam("ProjectId");
  const { t } = useTranslation(["editProject"]);

  const { adjustableStimuli } = useStaticDataState();
  const breadCrumbsConfig = [
    ...getConfig(adjustableStimuli, displayMode)
  ] as const;

  const currentStepIndex = breadCrumbsConfig.findIndex(
    ({ path }) => path && pathname.includes(path)
  );

  return (
    <CustomBreadcrumbs separator={<StyledArrow />}>
      {breadCrumbsConfig.map(({ labelKey, path }, i) => (
        <BreadcrumbLink
          key={labelKey}
          onClick={
            path && currentStepIndex !== i
              ? () => {
                  const { base, edit, preview } = Paths.surveys;
                  const modeBase =
                    displayMode === "preview" ? preview.base : edit.base;
                  navigate(`/${base}/${modeBase}/${id}/${path}`);
                }
              : undefined
          }
          status={
            currentStepIndex > i
              ? "previous"
              : currentStepIndex === i
              ? "current"
              : "next"
          }
        >
          {t(`editProject:${labelKey}`)}
        </BreadcrumbLink>
      ))}
    </CustomBreadcrumbs>
  );
};

const CustomBreadcrumbs = styled(MuiBreadcrumbs)`
  .MuiBreadcrumbs-li * {
    padding: 10px 10px;
  }
`;

const BreadcrumbLink = styled.div<{
  status?: "previous" | "current" | "next";
  onClick?: () => void;
}>`
  border-radius: 4px;
  cursor: default;
  font-size: 16px;
  line-height: 24px;
  padding: 0 4px;
  color: ${({ theme: { palette }, status }) =>
    status === "current"
      ? palette.primary.main
      : status === "next"
      ? palette.neutral.grey_warm_100
      : palette.neutral.oxford_blue};

  ${({ theme, onClick }) =>
    onClick
      ? `
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.neutral.grey_warm_20};
    }`
      : ""}
`;

const StyledArrow = styled(ArrowIcon)`
  stroke: ${({ theme }) => theme.palette.neutral.grey_warm_100};
`;
