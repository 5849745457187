import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExternalLink } from "../../assets/icons/external-link.svg";
import { useNavigate } from "react-router";
import { Paths } from "routing";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import {
  MoreHoriz,
  VisibilityOutlined,
  Preview,
  FileCopy
} from "@mui/icons-material";
import { usePreview } from "./usePreview";
import { usePermissions } from "contexts/Auth";
import { CreateProjectDialog } from "../Projects/Survey/CreateSurvey/CreateProjectDialog";
import { ListProject, PreviewStatuses } from "../../dto";
import IconButton from "./IconButton";

export interface SplitButtonProps {
  id: string;
  project: ListProject;
  withEdit?: boolean;
}

export const SplitButton: React.FC<SplitButtonProps> = ({
  id,
  project,
  withEdit = false
}) => {
  const { t } = useTranslation(["surveyList"]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [copyModelOpened, setCopyModalOpened] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { handlePreviewClick, isPreviewLoading, reset } = usePreview(id);
  const { canEdit, canOpenInTivian } = usePermissions("projectPermissions");

  const canPreviewProject = PreviewStatuses.includes(project.status);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const handleEditClick = () => {
    const { base, edit } = Paths.surveys;
    const path = `/${base}/${edit.base}/${id}/${edit.groupSample}`;
    navigate(path);
  };

  const handlePreviewProjectClick = () => {
    const { base, preview } = Paths.surveys;
    const path = `/${base}/${preview.base}/${id}/${preview.groupSample}`;
    navigate(path);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleCopyClick = () => setCopyModalOpened(true);

  const loader = <CircularProgress size={18} />;

  return (
    <>
      <ButtonGroup
        variant="outlined"
        color="secondary"
        ref={anchorRef}
        aria-label="split button"
      >
        {withEdit && (
          <Tooltip title={t("surveyList:edit")}>
            <IconButton
              variant="outlined"
              onClick={handleEditClick}
              icon={<EditIcon />}
            />
          </Tooltip>
        )}
        <StyledButtonDropdown
          variant="outlined"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <MoreHoriz />
        </StyledButtonDropdown>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList id="split-button-menu">
                  {canPreviewProject && (
                    <MenuItem
                      onClick={handlePreviewProjectClick}
                      disabled={isPreviewLoading}
                    >
                      <Preview sx={{ mr: 3.5 }} color="primary" />
                      {isPreviewLoading
                        ? loader
                        : t("surveyList:previewProject")}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handlePreviewClick((data) => data.previewUrl)}
                    disabled={isPreviewLoading}
                  >
                    <VisibilityOutlined sx={{ mr: 3.5 }} color="primary" />
                    {isPreviewLoading ? loader : t("surveyList:preview")}
                  </MenuItem>
                  {canOpenInTivian && (
                    <MenuItem
                      onClick={handlePreviewClick((data) => data.questback)}
                      disabled={isPreviewLoading}
                    >
                      <Box mr={3.5}>
                        <ExternalLink />
                      </Box>
                      {isPreviewLoading ? loader : t("surveyList:openInTivian")}
                    </MenuItem>
                  )}
                  {canEdit && (
                    <MenuItem onClick={() => handleCopyClick()}>
                      <Box mr={3.5}>
                        <FileCopy
                          style={{ fill: "#007FFF" }}
                          width={24}
                          height={24}
                        />
                      </Box>
                      {t("surveyList:copySurvey")}
                    </MenuItem>
                  )}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <CreateProjectDialog
        open={copyModelOpened}
        onClose={() => setCopyModalOpened(false)}
        edit={{
          id: project.id,
          name: `Copy of - ${project.projectName}`,
          ref: ""
        }}
        copy
      />
    </>
  );
};

const StyledButtonDropdown = styled(Button)`
  && {
    height: 32px;
    width: 32px;

    &.MuiButton-root {
      min-width: 32px;
    }

    &.MuiButtonGroup-grouped {
      min-width: 32px;
    }
  }
`;

const StyledMenuList = styled(MenuList)`
  && {
    width: 220px;

    .MuiMenuItem-root {
      height: 48px;
      font-size: 13px;
    }
  }
`;
