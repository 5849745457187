import React, { ChangeEvent, useState } from "react";
import { CloseableDialog, LoadingButton } from "components/Shared";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { inviteNewUserToGroup } from "services/groupService";
import { queryKeys } from "api/queryClient";
import { GroupDetailsDto } from "dto/Group";
import { useRouteParam } from "routing";
import { UserRole } from "../../../../dto";

export interface InviteNewUserDialogProps {
  open: boolean;
  group: GroupDetailsDto;
  onClose: () => void;
}

interface InviteUserDefaultValues {
  name: string;
  email: string;
  role: UserRole;
}

const i18nBase = "group:details.inviteNewUser";

export const InviteNewUserDialog: React.VFC<InviteNewUserDialogProps> = ({
  open,
  group,
  onClose
}) => {
  const { t } = useTranslation(["group", "common"]);
  const userRoles: UserRole[] = group.isMainGroup
    ? ["ADMIN", "PROJECT_MANAGER"]
    : ["EXTERNAL_BASIC_USER", "EXTERNAL_POWER_USER"];
  const defaultInviteUserValues = {
    name: "",
    email: "",
    role: userRoles[0]
  };
  const [inviteUserValues, setInviteUserValues] =
    useState<InviteUserDefaultValues>(defaultInviteUserValues);
  const queryClient = useQueryClient();
  const groupId = useRouteParam("GroupId");

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const handleOnClose = () => {
    setInviteUserValues(defaultInviteUserValues);
    onClose();
  };

  const { isLoading, mutate } = useMutation(inviteNewUserToGroup, {
    onSuccess: async ({ email }) => {
      await queryClient.invalidateQueries(queryKeys.group.byId(groupId));
      displaySuccessSnackbar(t(`${i18nBase}.successFeedback`, { email }));
      handleOnClose();
    },
    onError: (error: any) => {
      if (error.response.status === 400) {
        return displayErrorSnackbar(
          t("group:details.inviteNewUser.emailAlreadyTaken")
        );
      }
      displayErrorSnackbar();
    }
  });

  const onAddClick = () => {
    const { name, email, role } = inviteUserValues;
    if (!name) {
      displayErrorSnackbar(t(`${i18nBase}.validation.name`));
      return;
    }
    if (!email) {
      displayErrorSnackbar(t(`${i18nBase}.validation.email`));
      return;
    }
    mutate({ groupId, name, email, role });
  };

  const handleFieldChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { value, name } = event.target;
    setInviteUserValues((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={handleOnClose}
      header={
        <>
          <Typography variant="h2" align="center" mb={3}>
            {t(`${i18nBase}.heading`)}
          </Typography>
          <Typography variant="body1" align="center" color="neutral.grey_dark">
            {t(`${i18nBase}.subheading`, {
              groupName: group.name
            })}
          </Typography>
        </>
      }
      body={
        <>
          <Box>
            <TextField
              margin="dense"
              fullWidth
              name="name"
              label={t(`${i18nBase}.name`)}
              variant="outlined"
              value={inviteUserValues.name}
              onChange={handleFieldChange}
              autoFocus
            />
            <TextField
              margin="dense"
              fullWidth
              name="email"
              label={t(`${i18nBase}.email`)}
              variant="outlined"
              value={inviteUserValues.email}
              onChange={handleFieldChange}
            />
            <Box mt={2} sx={{ paddingY: 1 }}>
              <InputLabel shrink htmlFor="invite-user-role-field">
                {t(`${i18nBase}.role`)}
              </InputLabel>
              <Select
                margin="dense"
                fullWidth
                name="role"
                id="invite-user-role-field"
                value={inviteUserValues.role}
                onChange={handleFieldChange}
                variant="outlined"
              >
                {userRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {t(`common:enums.UserRole.${role}`)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </>
      }
      footer={
        <>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={handleOnClose}
          >
            {t("common:cancel")}
          </Button>

          <LoadingButton
            isLoading={isLoading}
            variant="contained"
            size="large"
            color="primary"
            onClick={onAddClick}
          >
            {t("common:invite")}
          </LoadingButton>
        </>
      }
    />
  );
};
