import React, { useState } from "react";
import { Typography, Box, Button, TextField } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "components/Shared";
import { NonAuthorizedLayout } from "components/Shared/Layout";
import { useNotificationContext } from "contexts/NotificationContext";
import { useMutation } from "react-query";
import { Paths } from "routing";
import { ReactComponent as SuccessIcon } from "assets/images/email-sent.svg";
import { requestPasswordReset } from "auth/auth-client";
import { CardWrapper } from "./CardWrapper";

const i18nBase = "auth:resetPassword";

const loginPath = `/${Paths.auth.base}/${Paths.auth.signIn}`;

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation(["auth"]);
  const { displayErrorSnackbar } = useNotificationContext();
  const { isLoading, mutate, isSuccess } = useMutation(requestPasswordReset, {
    onError: (e) => displayErrorSnackbar()
  });

  return (
    <NonAuthorizedLayout>
      <CardWrapper bgColor={isSuccess ? "transparent" : "#fff"}>
        {isSuccess && (
          <Box alignSelf="center" mb={11}>
            <SuccessIcon />
          </Box>
        )}
        <Typography variant="h2" align="center" mb={3}>
          {t(isSuccess ? `${i18nBase}.successHeading` : `${i18nBase}.heading`)}
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary">
          {t(
            isSuccess
              ? `${i18nBase}.successDescription`
              : `${i18nBase}.description`
          )}
        </Typography>
        {!isSuccess && <Form isLoading={isLoading} onSubmit={mutate} />}
        <Button
          sx={{ mt: 12, alignSelf: "center" }}
          component={RouterLink}
          to={loginPath}
          color="secondary"
        >
          {t(`${i18nBase}.backButton`)}
        </Button>
      </CardWrapper>
    </NonAuthorizedLayout>
  );
};

type FormProps = {
  isLoading: boolean;
  onSubmit: (email: string) => void;
};

const Form: React.VFC<FormProps> = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation(["auth"]);
  const [email, setEmail] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (email) {
          onSubmit(email);
        }
      }}
    >
      <TextField
        sx={{ mt: 12, mb: 8 }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label={t(`${i18nBase}.label`)}
        fullWidth
      />
      <LoadingButton
        fullWidth
        isLoading={isLoading}
        variant="contained"
        size="large"
        color="primary"
        type="submit"
      >
        {t(`${i18nBase}.submit`)}
      </LoadingButton>
    </form>
  );
};
