import React from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import { useQuery } from "react-query";

import { TemplateCard } from "./TemplateCard";
import { getAllTemplates } from "services/templateService";
import { useTranslation } from "react-i18next";
import { CreateProjectDialog } from "./CreateProjectDialog";
import { Template } from "dto";
import { queryKeys } from "api/queryClient";
import { ViewLoader } from "../../../Shared";

export const CreateSurvey = () => {
  const { t } = useTranslation(["createSurvey", "common"]);

  const [template, setTemplate] = React.useState<Template>();

  const handleOpen = (templateById: Template) => {
    setTemplate(templateById);
  };
  const handleClose = () => setTemplate(undefined);

  return (
    <Host>
      <MainCard>
        <Heading>{t("createSurvey:chooseTemplate")}</Heading>
        <Templates handleOpen={handleOpen} />
      </MainCard>
      {template && (
        <CreateProjectDialog
          open={!!template}
          template={template}
          onClose={handleClose}
        />
      )}
    </Host>
  );
};

export const Templates = ({
  handleOpen
}: {
  handleOpen: (templateById: Template) => void;
}) => {
  const { t } = useTranslation(["createSurvey", "common"]);
  const {
    isLoading,
    isError,
    data: templates,
    refetch
  } = useQuery(queryKeys.templates, getAllTemplates);

  return (
    <>
      {isLoading && <ViewLoader size={24} />}
      {isError && (
        <ErrorInfo>
          <p>{t("common:errorOccurred")}</p>

          <Button color="primary" onClick={() => refetch()}>
            {t("common:tryAgain")}
          </Button>
        </ErrorInfo>
      )}
      {templates && (
        <ImageList>
          {templates.map((template) => (
            <li key={template.id} onClick={() => handleOpen(template)}>
              <TemplateCard template={template} />
            </li>
          ))}
        </ImageList>
      )}
    </>
  );
};

const Host = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.grey_warm_20};
  height: 100%;
  overflow: hidden;
`;

const MainCard = styled.div`
  max-width: 1110px;
  min-height: 500px;
  max-height: 657px;
  margin: 25px auto 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  margin: 0;
  padding: 31px 50px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  font-weight: normal;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
  line-height: 32px;
`;

const ImageList = styled.ul`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
  list-style: none;
  gap: 15px;
  overflow-y: auto;
`;

const ErrorInfo = styled.div`
  margin: auto;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.neutral.w_black};
  text-align: center;
`;
