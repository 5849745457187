export const Paths = {
  accountSettings: "me",
  group: {
    base: "group"
  },
  folders: {
    base: "files"
  },
  templates: {
    base: "templates"
  },
  projects: {
    base: "projects"
  },
  surveys: {
    base: "surveys",
    create: "create",
    edit: {
      base: "edit",
      groupSample: "groupSample",
      stimuli: "stimuli",
      editSurvey: "editSurvey"
    },
    preview: {
      base: "preview",
      groupSample: "groupSample",
      stimuli: "stimuli",
      editSurvey: "editSurvey"
    }
  },
  auth: {
    base: "auth",
    signIn: "sign-in",
    activateAccount: "activate-account",
    resetPassword: {
      base: "reset-password",
      newPassword: "new-password"
    }
  }
};
