import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { InviteNewUserDialog } from "./InviteNewUserDialog";
import { GroupDetailsDto } from "dto/Group";

interface InviteNewUserButtonProps {
  variant: "contained" | "text";
  group: GroupDetailsDto;
}

export const InviteNewUserButton: React.VFC<InviteNewUserButtonProps> = ({
  variant,
  group
}) => {
  const { t } = useTranslation(["group"]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant={variant}
        size="medium"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        {t("group:details.inviteNewUser.heading")}
      </Button>
      <InviteNewUserDialog
        open={open}
        group={group}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
