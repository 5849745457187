import { useState } from "react";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ButtonBase } from "@mui/material";
import { EditNameDialog } from "./EditNameDialog";
import { UserDetailsDto } from "dto";

export const EditNameButton = ({ user }: { user: UserDetailsDto }) => {
  const [isNameEditDialogOpen, setIsNameEditDialogOpen] = useState(false);
  return (
    <>
      <ButtonBase
        onClick={() => setIsNameEditDialogOpen(true)}
        sx={{ borderRadius: 1, p: 1 }}
      >
        <EditIcon />
      </ButtonBase>
      <EditNameDialog
        open={isNameEditDialogOpen}
        user={user}
        onClose={() => setIsNameEditDialogOpen(false)}
      />
    </>
  );
};
