import React from "react";
import { Box } from "@mui/material";

export type CardWrapperProps = {
  bgColor?: string;
};

export const CardWrapper: React.FC<CardWrapperProps> = ({
  children,
  bgColor = "#fff"
}) => {
  return (
    <Box height="100%" display="grid" sx={{ alignItems: "center" }} px={5}>
      <Box
        borderRadius={12}
        bgcolor={bgColor}
        pt={12.5}
        pb={17.5}
        px={5}
        width="min(100%,560px)"
        mx="auto"
      >
        <Box
          width="min(100%,380px)"
          mx="auto"
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
