import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Placeholder, PlaceholderDetails } from "../../../../../../dto";
import styled from "styled-components";

type SelectProps = {
  placeholderConfig: PlaceholderDetails;
  placeholder: Placeholder;
  updatePlaceholderValue: (
    key: string,
    value: string,
    allowedValId: string
  ) => void;
  disabled?: boolean;
};

export const SelectField: React.FC<SelectProps> = ({
  placeholderConfig: { key, label, allowedValues, sentence },
  placeholder: { allowedValueId },
  updatePlaceholderValue,
  disabled
}) => {
  const onValueChange = (key: string, id: string | null) => {
    if (!id) {
      return;
    }
    const allowedValue = allowedValues.find((val) => val.id === id);
    if (!allowedValue) {
      console.error("No value found!");
      return;
    }
    updatePlaceholderValue(key, allowedValue.value, id);
  };

  return (
    <>
      {sentence && <Sentence>{sentence}</Sentence>}
      <FormControlWrapper variant="outlined">
        <CustomSelectLabel id={`${key}-label`}>{label}</CustomSelectLabel>
        <Select
          labelId={`${key}-label`}
          id={`${key}`}
          value={allowedValueId ? allowedValueId : null}
          onChange={(e) => onValueChange(key, e.target.value)}
          variant="outlined"
          disabled={disabled}
        >
          {allowedValues.map(({ id, label, value }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControlWrapper>
    </>
  );
};

const FormControlWrapper = styled(FormControl)`
  width: 100%;
`;

const CustomSelectLabel = styled(InputLabel)`
  padding: 0 5px;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const Sentence = styled.p`
  margin: 0 0 24px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;
