import { Button, Typography, Box } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FolderDetailsDto } from "dto/Folder";
import { FileTile, FileUploader } from "./FileTile";
import { ReactComponent as FilesEmpty } from "assets/icons/files-empty.svg";

export interface MultipleFilesUploaderProps {
  folder: FolderDetailsDto;
}

export const MultipleFilesUploader: React.VFC<MultipleFilesUploaderProps> = ({
  folder
}) => {
  const remoteFiles = folder.files;
  const { t } = useTranslation(["editProject", "folders"]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const updateFiles = (files: FileList) => {
    setLocalFiles(Array.from(files));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      updateFiles(files);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    muteEventDefaults(e);
    setIsDragging(false);
    const files = e.dataTransfer?.files;
    if (!files) {
      return;
    }
    updateFiles(files);
  };

  useEffect(() => {
    window.addEventListener("dragover", muteEventDefaults);
    window.addEventListener("drop", muteEventDefaults);
    return () => {
      window.removeEventListener("dragover", muteEventDefaults);
      window.removeEventListener("drop", muteEventDefaults);
    };
  }, []);

  return (
    <>
      <Typography variant="h2" mb={3}>
        {t("folders:uploadHeader")}
      </Typography>
      <Root
        isDragging={isDragging}
        onDrop={handleDrop}
        overlayText={t("editProject:stimuli.dropHere")}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
      >
        <Inner>
          {t("editProject:stimuli.dragAndDropOr")}
          <Button onClick={handleBrowseClick} color="primary">
            {t("editProject:stimuli.browse")}
          </Button>
        </Inner>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".jpg,.png,.mp4,.avi,.mov,.pdf,.doc,.docx,.xls,.xlsx"
        />
      </Root>
      <Typography variant="body1" mt={2} color="GrayText">
        {t("folders:acceptableFormats")}
      </Typography>
      <Box mt={10} sx={{ minHeight: 350 }}>
        {!remoteFiles.length && !localFiles.length && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={30}
          >
            <FilesEmpty />
            <Typography sx={{ mt: 5, fontSize: 15 }}>
              {t("folders:files.empty.title")}
            </Typography>
            <Typography variant="body2" color="neutral.grey_dark">
              {t("folders:files.empty.subtitle")}
            </Typography>
          </Box>
        )}
        {remoteFiles.map((file) => (
          <FileTile file={file} />
        ))}
        {localFiles.map((file) => (
          <FileUploader file={file} key={file.name} />
        ))}
      </Box>
    </>
  );
};

const Root = styled.div<{ isDragging: boolean; overlayText: string }>`
  position: relative;
  width: 100%;
  height: 100px;
  background-color: rgba(0, 127, 255, 0.05);
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  place-content: center;

  input {
    display: none;
  }

  ::after {
    content: "${({ overlayText }) => overlayText}";
    display: ${({ isDragging }) => (isDragging ? "grid" : "none")};
    place-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.light};
    border-radius: 4px;
    font-size: 16px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: baseline;
`;

const muteEventDefaults = (e: Event | React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};
