import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import { useProjectState } from "contexts/ProjectStateContext";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { Paths } from "routing";
import { Navigate, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { MediaType, Stimulus, StimulusMedia } from "dto/Project";
import { StimulusForm } from "./StimulusForm";
import { NextButton, PrevButton } from "components/Shared";
import { useDebounce } from "utils";
import { useEditProject } from "contexts/EditProjectContext";
import { useStaticDataState } from "../../../../../contexts/StaticDataContext";
import { DisplayMode } from "../EditProject";

type StimuliProps = {
  displayMode: DisplayMode;
};

export const Stimuli: React.VFC<StimuliProps> = ({ displayMode }) => {
  const { project } = useProjectState();
  const navigate = useNavigate();
  const { t } = useTranslation(["editProject"]);
  const [stimuli, setStimuli] = useState(project.stimuli);
  const [currentIndex, setStimulusIndex] = useState(0);
  const [areStimuliDisabled, setStimuliDisabled] = useState(false);
  const { stimuliNameAlias } = useStaticDataState();

  useEffect(() => {
    console.log("effect", project.stimuli);
    setStimuli(project.stimuli);
  }, [project.stimuli]);

  const { updateStimuli } = useEditProject();

  const isStimulusValid = (stimulus: Stimulus) => {
    const { name, incontextId, mediaList } = stimulus;
    return mediaList.some((media) => {
      if (media.type === MediaType.FILE) {
        return !!name && !!incontextId;
      }
      return !!name && !!incontextId && !!media.link;
    });
  };

  const debouncedStimuli = useDebounce(stimuli, 500);
  useEffect(() => {
    const stimuliToUpdate = debouncedStimuli.filter((newStimulus, i) => {
      return (
        isStimulusValid(newStimulus) &&
        stimulusChanged(newStimulus, project.stimuli[i])
      );
    });
    if (stimuliToUpdate.length) {
      updateStimuli(project.id, stimuliToUpdate);
    }
  }, [debouncedStimuli]);

  const currentStimulus = stimuli[currentIndex];
  const updateCurrentStimulus = (update: Partial<Stimulus>) => {
    console.log(update, stimuli);
    setStimuli((prevStimuli) =>
      prevStimuli.map((stimulus, i) =>
        i === currentIndex ? { ...stimulus, ...update } : stimulus
      )
    );
  };

  const { base, edit, preview } = Paths.surveys;
  const mode = displayMode === "preview" ? preview : edit;
  const prevPath = `/${base}/${mode.base}/${project.id}/${mode.groupSample}`;
  const nextPath = `/${base}/${mode.base}/${project.id}/${mode.editSurvey}`;

  if (stimuli.length < 1) {
    <Navigate to={prevPath} />;
  }

  const handlePrev = () => {
    if (currentIndex === 0) {
      return navigate(prevPath);
    }
    setStimulusIndex((prevIndex) => --prevIndex);
  };
  const handleNext = () => {
    const lastIndex = stimuli.length - 1;
    if (currentIndex === lastIndex) {
      return navigate(nextPath);
    }
    setStimulusIndex((prevIndex) => ++prevIndex);
  };

  return (
    <Host>
      <Heading>{t("editProject:stimuli.header")}</Heading>
      <Main>
        <Buttons>
          {stimuli.map((stimulus, i) => (
            <StimulusButton
              key={i}
              variant={i === currentIndex ? "outlined" : "contained"}
              disabled={i === currentIndex || areStimuliDisabled}
              onClick={() => setStimulusIndex(i)}
            >
              <div>
                <span>
                  {t("editProject:stimuli.ad", {
                    stimuliAlias: stimuliNameAlias
                  })}{" "}
                  {i + 1}
                </span>
                <span>{stimulus.name}</span>
              </div>
              {isStimulusValid(project.stimuli[i]) && <CheckIcon />}
            </StimulusButton>
          ))}
        </Buttons>
        <div>
          <StimulusHeading>
            {t("editProject:stimuli.defineAd", {
              stimuliAlias: stimuliNameAlias
            })}{" "}
            {currentIndex + 1}
          </StimulusHeading>
          <Hint>
            {t("editProject:stimuli.defineAdHint", {
              stimuliAlias: stimuliNameAlias.toLowerCase()
            })}
          </Hint>
          <StimulusForm
            stimulusIndex={currentIndex}
            stimulus={currentStimulus}
            updateStimulus={updateCurrentStimulus}
            setStimuliDisabled={setStimuliDisabled}
            displayMode={displayMode}
          />
        </div>
      </Main>
      <Footer>
        <PrevButton onClick={handlePrev} disabled={areStimuliDisabled} />
        <NextButton onClick={handleNext} disabled={areStimuliDisabled} />
      </Footer>
    </Host>
  );
};

const Host = styled.div`
  width: 100%;
`;

const Heading = styled.h2`
  margin: 0;
  padding: 30px 0 20px 50px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  margin-bottom: 32px;
`;

const Main = styled.div`
  display: grid;
  padding: 0 50px 0 25px;
  gap: 50px;
  grid-template-columns: auto 1fr;
`;

const Buttons = styled.div`
  display: grid;
  width: 236px;
  gap: 8px;
  grid-auto-rows: 56px;
  margin-bottom: 20px;
`;

const StimulusButton = styled(Button)`
  && {
    padding: 5px 15px;
    height: 56px;
    background-color: #fff;
    border: 1px solid
      ${({ theme: { palette } }) => palette.neutral.grey_warm_100};
    box-shadow: none;
    box-sizing: border-box;
  }

  &.MuiButton-outlined:disabled {
    border-color: ${({ theme: { palette } }) => palette.neutral.navy_blue};
    color: ${({ theme: { palette } }) => palette.neutral.oxford_blue};
  }

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    flex-grow: 1;

    & span:first-child {
      color: ${({ theme }) => theme.palette.neutral.grey_dark};
      margin-right: 4px;
    }

    & span:not(first-child) {
      color: ${({ theme }) => theme.palette.neutral.oxford_blue};
    }
  }

  & svg {
    margin-left: 4px;
    flex-shrink: 0;
    transform: translateY(-3px);
  }
`;

const StimulusHeading = styled.h4`
  margin: 0 0 8px;
`;

const Hint = styled.p`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
  margin: 0 0 32px;
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  height: 79px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const stimulusChanged = (oldStimulus: Stimulus, newStimulus: Stimulus) => {
  return (
    oldStimulus.name !== newStimulus.name ||
    oldStimulus.incontextId !== newStimulus.incontextId ||
    stimulusMediaChanged(oldStimulus.mediaList, newStimulus.mediaList)
  );
};

const stimulusMediaChanged = (
  oldMediaList: StimulusMedia[],
  newMediaList: StimulusMedia[]
) => {
  return oldMediaList.some((oldMedia) => {
    const newMediaFound = newMediaList.find(
      (newMedia) => newMedia.id === oldMedia.id
    );
    if (!newMediaFound) {
      return true;
    }
    return (
      oldMedia.type !== newMediaFound.type ||
      oldMedia.link !== newMediaFound.link
    );
  });
};
