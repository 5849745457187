import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { CreateFolderDialog } from "./CreateFolderDialog";

type CreateFolderButtonProps = {
  small?: boolean;
};

export const CreateFolderButton: React.VFC<CreateFolderButtonProps> = ({
  small
}) => {
  const { t } = useTranslation(["folders"]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        fullWidth={small}
        variant="contained"
        size={small ? "small" : "medium"}
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        {t("folders:buttons.create")}
      </Button>
      <CreateFolderDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
