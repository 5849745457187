import { Box, Button, Typography } from "@mui/material";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { useNotificationContext } from "contexts/NotificationContext";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Paths, useRouteParam } from "routing";
import { deleteGroup } from "services/groupService";

export interface DeleteGroupDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DeleteGroupDialog: React.VFC<DeleteGroupDialogProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation(["group", "common"]);
  const groupId = useRouteParam("GroupId");
  const navigate = useNavigate();
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const { isLoading, mutate } = useMutation(deleteGroup, {
    onSuccess: () => {
      navigate(`/${Paths.group.base}`);
      displaySuccessSnackbar(t("group:details.deleteGroup.successFeedback"));
    },
    onError: () => displayErrorSnackbar()
  });

  const onDeleteClick = () => {
    mutate({ groupId });
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      body={
        <>
          <Typography variant="h2" align="center" mb={3}>
            {t("group:details.deleteGroup.heading")}
          </Typography>
          <Typography variant="body1" align="center" color="neutral.grey_dark">
            {t("group:details.deleteGroup.subheading")}
          </Typography>
          <Box
            display="flex"
            sx={{ marginTop: "48px", width: "100%" }}
            justifyContent="center"
          >
            <Box mx="10px">
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={onClose}
              >
                {t("common:cancel")}
              </Button>
            </Box>
            <Box mx="10px">
              <LoadingButton
                isLoading={isLoading}
                variant="contained"
                size="large"
                color="error"
                onClick={onDeleteClick}
              >
                {t("group:details.deleteGroup.button")}
              </LoadingButton>
            </Box>
          </Box>
        </>
      }
    />
  );
};
