import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  LoadingButton,
  PasswordInput,
  PasswordValidator
} from "components/Shared";
import { NonAuthorizedLayout } from "components/Shared/Layout";
import { useNotificationContext } from "contexts/NotificationContext";
import { useMutation } from "react-query";
import { Paths } from "routing";
import { useSearchParams } from "react-router-dom";
import { setNewPassword } from "auth/auth-client";
import { useAuth } from "contexts/Auth";
import { CardWrapper } from "./CardWrapper";

const i18nBase = "auth:newPassword";

const loginPath = `/${Paths.auth.base}/${Paths.auth.signIn}`;

export const NewPassword: React.FC = () => {
  const { t } = useTranslation(["auth"]);
  const [password, setPassword] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [wasTried, setWasTried] = useState(false);
  const { isLoading, mutate } = useNewPasswordMutation();

  const onChangePassword = () => {
    if (isNewPasswordValid) {
      mutate(password);
    } else {
      setWasTried(true);
    }
  };

  return (
    <NonAuthorizedLayout>
      <CardWrapper>
        <Typography variant="h2" align="center" mb={10}>
          {t(`${i18nBase}.heading`)}
        </Typography>
        <PasswordInput
          error={wasTried && !isNewPasswordValid}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label={t(`${i18nBase}.label`)}
          fullWidth
          autoComplete="new-password"
        />

        <PasswordValidator
          mt={4}
          mb={8}
          password={password}
          onIsValidChange={setIsNewPasswordValid}
          isError={wasTried}
        />
        <LoadingButton
          fullWidth
          isLoading={isLoading}
          onClick={onChangePassword}
          variant="contained"
          size="large"
          color="primary"
        >
          {t(`${i18nBase}.submit`)}
        </LoadingButton>
      </CardWrapper>
    </NonAuthorizedLayout>
  );
};

type QueryData = {
  email: string;
  code: string;
};

const useNewPasswordMutation = () => {
  const { t } = useTranslation(["auth"]);
  const [data, setData] = useState<QueryData>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const email = searchParams.get("email");
    const code = searchParams.get("code");
    if (email && code) {
      setData({ email, code });
      setSearchParams({});
    } else {
      displayErrorSnackbar(t(`${i18nBase}.wrongDataFeedback`));
      navigate(loginPath);
    }
  }, []);

  const { isLoading, mutate } = useMutation(
    async (body: QueryData & { password: string }) => {
      const { email, code, password } = body;
      await setNewPassword(email, code, password);
      await login.mutateAsync({ email, password });
    },
    {
      onSuccess: () => {
        displaySuccessSnackbar(t(`${i18nBase}.successFeedback`));
      },
      onError: (e) => {
        if (isWrongDataException(e)) {
          displayErrorSnackbar(t(`${i18nBase}.wrongDataFeedback`));
          navigate(loginPath);
        } else {
          displayErrorSnackbar();
        }
      }
    }
  );

  return {
    isLoading,
    mutate: (password: string) => {
      if (data) {
        mutate({ ...data, password });
      }
    }
  };
};

const isWrongDataException = (error: any) => {
  return error?.name === "NotAuthorizedException";
};
