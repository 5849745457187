import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { queryKeys } from "api/queryClient";
import { getPotentialGroupManagers } from "services/groupService";
import { QueryErrorFeedback, UserAvatar, ViewLoader } from "components/Shared";
import { UserListDto } from "dto";

export interface ManagersSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
}

export const ManagersSelect: React.VFC<ManagersSelectProps> = ({
  value,
  onChange
}) => {
  const {
    data: managers,
    refetch,
    isError
  } = useQuery(queryKeys.potentialManagers, getPotentialGroupManagers);

  const onClick = (manager: UserListDto, checked: boolean) => {
    onChange(
      checked
        ? value.filter((id) => manager.id !== id)
        : value.concat(manager.id)
    );
  };

  return (
    <Box>
      {isError ? (
        <QueryErrorFeedback retry={refetch} />
      ) : !managers ? (
        <ViewLoader size={30} />
      ) : (
        <List>
          {managers.map((manager) => {
            const checked = value.includes(manager.id);
            return (
              <ListItem key={manager.id} disablePadding>
                <ListItemButton
                  dense
                  disableGutters
                  divider
                  onClick={() => onClick(manager, checked)}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Checkbox checked={checked} tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemAvatar sx={{ minWidth: 0, marginX: 3.75 }}>
                    <UserAvatar name={manager.name} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={manager.name}
                    secondary={manager.email}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};
