import { httpClient } from "api/httpClient";
import { urls } from "api/urls";
import { PageableParams } from "dto/common";
import { FolderDetailsDto, FolderStatus, PagedFolders } from "dto/Folder";

export const createFolder = async ({
  name,
  referenceNo,
  groupId,
  description
}: {
  name: string;
  referenceNo: string;
  groupId: string;
  description: string;
}) => {
  const response = await httpClient.post<FolderDetailsDto>(
    `${urls.groups}/${groupId}/folders`,
    {
      description,
      groupId,
      name,
      referenceNo
    }
  );
  return response.data;
};

export const getFolderById = async (id: string) => {
  const response = await httpClient.get<FolderDetailsDto>(
    `${urls.folders}/${id}`
  );
  return response.data;
};

export const getAllFolders = async (
  pageable: PageableParams & {
    status: FolderStatus;
    group?: string;
    search?: string;
  }
) => {
  const sortDirection = pageable.sort === "NAME" ? ",asc" : ",desc";
  const response = await httpClient.get<PagedFolders>(urls.folders, {
    params: {
      page: pageable.page,
      size: pageable.size,
      status: pageable.status,
      group: pageable.group,
      search: pageable.search,
      sortBy: pageable.sort + sortDirection
    }
  });
  return response.data;
};

export const markFolderAsFinished = async ({
  folderId
}: {
  folderId: string;
}) => {
  const response = await httpClient.put(`${urls.folders}/${folderId}/finished`);
  return response.data;
};

export const downloadFile = async ({
  folderId,
  fileId
}: {
  folderId: string;
  fileId: string;
}) => {
  const response = await httpClient.get(
    `${urls.folders}/${folderId}/files/${fileId}`
  );
  return response.data;
};

export const downloadAllFiles = async ({ folderId }: { folderId: string }) => {
  const response = await httpClient.get(`${urls.folders}/${folderId}/files`);
  return response.data;
};

export const uploadFile = async ({
  folderId,
  file,
  onProgressChange
}: {
  folderId: string;
  file: File;
  onProgressChange: (progress: number) => void;
}) => {
  const data = new FormData();
  data.append("file", file);
  const response = await httpClient.post(
    `${urls.folders}/${folderId}/files`,
    data,
    {
      onUploadProgress: (e: { loaded: number; total: number }) => {
        const min = 0;
        const max = 99;
        const progress = Math.round((e.loaded * 100) / e.total);
        onProgressChange(Math.min(Math.max(progress, min), max));
      }
    }
  );
  return response.data;
};

export const renameFile = async ({
  folderId,
  fileId,
  name
}: {
  folderId: string;
  fileId: string;
  name: string;
}) => {
  const response = await httpClient.put(
    `${urls.folders}/${folderId}/files/${fileId}?updatedFileName=${name}`
  );
  return response.data;
};

export const deleteFile = async ({
  fileId,
  folderId
}: {
  fileId: string;
  folderId: string;
}) => {
  const response = await httpClient.delete(
    `${urls.folders}/${folderId}/files/${fileId}`
  );
  return response.data;
};

export const editFolder = async ({
  id,
  name,
  referenceNo,
  description
}: {
  id: string;
  name: string;
  referenceNo: string;
  description: string;
}) => {
  const response = await httpClient.put<FolderDetailsDto>(
    `${urls.folders}/${id}`,
    {
      description,
      name,
      referenceNo
    }
  );
  return response.data;
};

export const deleteFolder = async ({ folderId }: { folderId: string }) => {
  const response = await httpClient.delete(`${urls.folders}/${folderId}`);
  return response.data;
};
