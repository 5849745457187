import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { Button, ButtonProps } from "@mui/material";
import styled from "styled-components";

export const NextButton: React.VFC<ButtonProps> = (props) => {
  const { t } = useTranslation(["common"]);
  return (
    <StyledButton
      {...props}
      variant="outlined"
      color="secondary"
      endIcon={<ArrowIcon />}
    >
      {t("common:next")}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  && {
    stroke: ${({ theme }) => theme.palette.neutral.oxford_blue};
  }

  & svg {
    transform: translateY(-1px);
  }
`;
