import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { DeleteGroupDialog } from "./DeleteGroupDialog";
import { GroupDetailsDto } from "dto/Group";

interface DeleteGroupButtonProps {
  closePopper: () => void;
  group: GroupDetailsDto;
}

export const DeleteGroupButton: React.VFC<DeleteGroupButtonProps> = ({
  closePopper,
  group
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuItemComponent onClick={() => setOpen(true)} />
      <DeleteGroupDialog
        open={open}
        onClose={() => {
          setOpen(false);
          closePopper();
        }}
      />
    </>
  );
};

const MenuItemComponent = ({
  onClick,
  disabled
}: {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(["group"]);
  return (
    <MenuItem
      sx={{ color: "error.main" }}
      disabled={disabled}
      onClick={onClick}
    >
      <ListItemIcon>
        <TrashIcon />
      </ListItemIcon>
      <ListItemText primary={t("group:details.deleteGroup.button")} />
    </MenuItem>
  );
};
