import React from "react";
import { useTranslation } from "react-i18next";
import { UserDetailsDto } from "dto";
import { Box, BoxProps } from "@mui/system";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { ManagedGroupsTable } from "./ManagedGroupsTable";
import { ChangePasswordButton } from "./ChangePassword";
import { useMe } from "contexts/Auth";
import { EditNameButton } from "./EditName/EditNameButton";
import { EditRoleButton } from "./EditRole/EditRoleButton";
import { usePermissions } from "contexts/Auth";
import { LoadingButton } from "components/Shared";
import { deleteUser, disableUser, enableUser } from "services/userService";
import { useNotificationContext } from "contexts/NotificationContext";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "api/queryClient";

export interface UserDataProps {
  user: UserDetailsDto;
}

export const UserData: React.VFC<UserDataProps> = ({ user }) => {
  const { t } = useTranslation(["user", "common"]);
  const me = useMe();
  const { canEdit } = usePermissions("userPermissions");

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const queryClient = useQueryClient();
  const { isLoading: isEnableLoading, mutate: enableUserMutate } = useMutation(
    enableUser,
    {
      onSuccess: () => {
        displaySuccessSnackbar(t("user:enable.successFeedback"));
        queryClient.invalidateQueries(queryKeys.user.all);
      },
      onError: () => {
        displayErrorSnackbar();
      }
    }
  );
  const { isLoading: isDisableLoading, mutate: disableUserMutate } =
    useMutation(disableUser, {
      onSuccess: () => {
        displaySuccessSnackbar(t("user:disable.successFeedback"));
        queryClient.invalidateQueries(queryKeys.user.all);
      },
      onError: () => {
        displayErrorSnackbar();
      }
    });
  const { isLoading: isDeleteLoading, mutate: deleteUserMutate } = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        displaySuccessSnackbar(t("user:delete.successFeedback"));
        queryClient.invalidateQueries(queryKeys.user.all);
      },
      onError: () => {
        displayErrorSnackbar();
      }
    }
  );

  return (
    <>
      <Box display="grid" gridTemplateColumns="3fr 2fr" gap={20}>
        <Box>
          <Typography variant="h4" mb={5}>
            {t("user:userDetails")}
          </Typography>

          <FakeInput
            mb={3.5}
            label={t("user:userName")}
            value={user.name}
            endButton={canEdit && <EditNameButton user={user} />}
          />

          <FakeInput mb={3.5} label={t("user:email")} value={user.email} />

          <FakeInput mb={3.5} label={t("user:group")} value={user.group.name} />

          <FakeInput
            mb={12.5}
            label={t("user:role")}
            value={t(`common:enums.UserRole.${user.role}`)}
            endButton={
              canEdit && me.id !== user.id && <EditRoleButton user={user} />
            }
          />

          <Typography variant="h4" mb={5}>
            {t("user:security")}
          </Typography>

          <FakeInput
            label={t("user:password")}
            value="••••••••"
            endButton={me.id === user.id && <ChangePasswordButton />}
          />

          <Box mt={8} sx={{ display: "flex", flexDirection: "row" }}>
            {canEdit && user.status === "ACTIVE" && (
              <LoadingButton
                isLoading={isDisableLoading}
                variant="outlined"
                color="error"
                onClick={() => disableUserMutate(user.id)}
              >
                {t("user:disable.buttonText")}
              </LoadingButton>
            )}
            {canEdit && user.status === "DISABLED" && (
              <LoadingButton
                isLoading={isEnableLoading}
                variant="outlined"
                onClick={() => enableUserMutate(user.id)}
              >
                {t("user:enable.buttonText")}
              </LoadingButton>
            )}
            {canEdit && (
              <LoadingButton
                sx={{ ml: 5 }}
                isLoading={isDeleteLoading}
                variant="outlined"
                color="error"
                onClick={() => deleteUserMutate(user.id)}
              >
                {t("user:delete.buttonText")}
              </LoadingButton>
            )}
          </Box>

          {user.managedGroups && (
            <>
              <Typography variant="h4" mt={12.5} mb={5}>
                {t("user:manageGroups.heading")}
              </Typography>
              <ManagedGroupsTable groups={user.managedGroups} />
            </>
          )}
        </Box>

        <Box>
          <Typography variant="h4" mb={5}>
            {t("user:userActivity")}
          </Typography>

          <Box display="grid" gridTemplateColumns="auto 1fr" gap={5}>
            <Typography variant="body1" color="textSecondary">
              {t("user:invited")}
            </Typography>

            <Typography variant="body1" color="textPrimary">
              {dayjs.unix(user.invitationDate).format("DD MMM YYYY")}
              {user.inviter && (
                <>
                  <Typography component="span" color="textSecondary">
                    {` ${t("user:by")} `}
                  </Typography>
                  {user.inviter.name}
                </>
              )}
            </Typography>

            <Typography variant="body1" color="textSecondary">
              {t("user:lastActive")}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {user.lastActive ? dayjs.unix(user.lastActive).fromNow() : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

interface FakeInputProps extends Pick<BoxProps, "mt" | "mb"> {
  label: string;
  value: string;
  endButton?: React.ReactNode;
}

const FakeInput: React.VFC<FakeInputProps> = ({
  label,
  value,
  endButton,
  ...boxProps
}) => {
  return (
    <Box
      gap={1}
      borderBottom={1}
      borderColor="neutral.grey_warm_50"
      {...boxProps}
    >
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <Box my={1} display="grid" gridTemplateColumns="minmax(0,1fr) auto">
        <Typography variant="body1">{value}</Typography>
        <Box>{endButton}</Box>
      </Box>
    </Box>
  );
};
