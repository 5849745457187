import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "assets/images/logo.svg";
import { Paths } from "routing";

const loginPath = `/${Paths.auth.base}/${Paths.auth.signIn}`;

export const NotAuthorizedHeader = () => (
  <Container>
    <Link to={loginPath}>
      <LogoIcon />
    </Link>
  </Container>
);

const Container = styled.header`
  position: absolute;
  width: 100%;
  height: 70px;
  left: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  display: grid;
  place-items: center;
`;
