import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  TextFieldProps
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const PasswordInput: React.FC<
  OutlinedInputProps & Pick<TextFieldProps, "label">
> = ({ sx, value, onChange, label, id, fullWidth, error, ...props }) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => setVisible((p) => !p);
  return (
    <FormControl error={error} variant="outlined" sx={sx} fullWidth={fullWidth}>
      <InputLabel error={error} htmlFor={id}>
        {label}
      </InputLabel>
      <OutlinedInput
        error={error}
        {...props}
        id={id}
        type={visible ? "text" : "password"}
        value={value}
        onChange={onChange}
        label={label}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleVisibility}
              edge="end"
            >
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
