import styled from "styled-components";
import React from "react";
import { GroupDetailsDto, GroupListDto } from "dto/Group";
import { ReactComponent as Logo } from "assets/images/logo-avatar.svg";
import { UserAvatar } from "./UserAvatar";

const SIZE = 30;

export interface GroupAvatarProps {
  group: Pick<GroupDetailsDto | GroupListDto, "name" | "isMainGroup">;
}

export const GroupAvatar: React.VFC<GroupAvatarProps> = ({ group }) =>
  group.isMainGroup ? (
    <StyledLogo />
  ) : (
    <UserAvatar size={SIZE} name={group.name} />
  );

const StyledLogo = styled(Logo)`
  width: ${SIZE}px;
  height: ${SIZE}px;
`;
