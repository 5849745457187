/* eslint-disable prettier/prettier */
import {
  Box,
  Typography,
  LinearProgress,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@mui/material";
import styled from "styled-components";
import { mimeTypeToExtension } from "utils";
import { ReactComponent as VidIcon } from "assets/icons/doc-vid.svg";
import { ReactComponent as DocIcon } from "assets/icons/doc-doc.svg";
import { ReactComponent as JPGIcon } from "assets/icons/doc-jpg.svg";
import { ReactComponent as PNGIcon } from "assets/icons/doc-png.svg";
import { ReactComponent as PDFIcon } from "assets/icons/doc-pdf.svg";
import { ReactComponent as XMLIcon } from "assets/icons/doc-xml.svg";
import { RemoteFile } from "dto";
import { useEffect, useState } from "react";
import { queryClient, queryKeys } from "api/queryClient";
import { useMutation } from "react-query";
import { deleteFile, uploadFile } from "services/foldersService";
import { useNotificationContext } from "contexts/NotificationContext";
import { useTranslation } from "react-i18next";
import { formatBytes } from "utils/formatBytes";
import { useRouteParam } from "routing";
import { LoadingButton, MoreButton } from "components/Shared";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { RenameFileButton } from "./Rename/RenameFileButton";
import { Download } from "@mui/icons-material";
import saveAs from "file-saver";
import { elipsis } from "utils/elipsis";

export const FileTile = ({
  file,
  progress
}: {
  file: RemoteFile;
  progress?: number;
}) => {
  const { t } = useTranslation(["folders"]);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const folderId = useRouteParam("FolderId");

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const deleteMutation = useMutation(deleteFile, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.folders.all);
      displaySuccessSnackbar(
        t("folders:files.delete.successFeedback", { fileName: file.filename })
      );
    },
    onError: () => displayErrorSnackbar()
  });

  const downloadFile = (
    fileId: string,
    filename: string,
    fileFormat: string
  ) => {
    setIsDownloadInProgress(true);
    const requestOptions = {
      method: "GET"
    };

    fetch(
      `${window.location.origin}/api/folders/${folderId}/files/${fileId}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(URL.createObjectURL(blob), `${filename}.${fileFormat}`);
        setIsDownloadInProgress(false);
      })
      .catch(() => {
        displayErrorSnackbar();
        setIsDownloadInProgress(false);
      });
  };

  const handleDeleteClick = () => {
    deleteMutation.mutate({
      folderId,
      fileId: file.id
    });
  };

  return (
    <Box
      key={file.filename}
      mb={3}
      display="flex"
      alignItems="center"
      sx={{
        border: 1,
        borderColor: "neutral.grey_warm_50",
        borderRadius: 2,
        py: 3.5,
        px: 5,
        position: "relative"
      }}
    >
      <Box mr={3} sx={{ zIndex: 1 }}>
        {getFileTypeIcon(file.mimeType)}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ position: "relative", width: "100%", zIndex: 1 }}
      >
        <div>
          <Box display="flex">
            <Typography variant="body1">{elipsis(file.filename)}</Typography>
            {progress && (
              <Typography ml={2} color="GrayText">
                {progress}%
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body2" color="GrayText">
              {formatBytes(file.size)}
            </Typography>
          </Box>
        </div>
        <Box>
          <LoadingButton
            variant="outlined"
            color="secondary"
            size="small"
            sx={{
              display: "inline-block",
              width: 38,
              height: 38,
              button: { p: 2 }
            }}
            isLoading={isDownloadInProgress}
            onClick={() =>
              downloadFile(
                file.id,
                file.filename,
                mimeTypeToExtension(file.mimeType)
              )
            }
          >
            <Download sx={{ width: 20, height: 20 }} />
          </LoadingButton>
          {!progress && (
            <MoreButton
              sx={{ padding: "5px", ml: 2 }}
              size="small"
              renderItems={() => (
                <Box>
                  <RenameFileButton file={file} />
                  <MenuItem
                    sx={{ color: "error.main" }}
                    onClick={handleDeleteClick}
                    disabled={deleteMutation.isLoading}
                  >
                    <ListItemIcon>
                      <TrashIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("folders:files.delete.title")} />
                    {deleteMutation.isLoading && <CircularProgress size={20} />}
                  </MenuItem>
                </Box>
              )}
            />
          )}
        </Box>
      </Box>
      {progress ? (
        <ProgressBar variant="determinate" value={progress} />
      ) : (
        <ProgressBar variant="determinate" value={0} />
      )}
    </Box>
  );
};

export const FileUploader = ({ file }: { file: File }) => {
  const folderId = useRouteParam("FolderId");
  const [progress, setProgress] = useState(0);
  const { displayErrorSnackbar } = useNotificationContext();

  const uploadMutation = useMutation(uploadFile, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.folders.all);
      setProgress(100);
      // displaySuccessSnackbar(t("editProject:stimuli.mediaUploaded"));
    },
    onError: () => {
      setProgress(0);
      //   setLocalFile(undefined);
      displayErrorSnackbar();
    }
  });

  useEffect(() => {
    if (progress === 0) {
      uploadMutation.mutate({
        folderId,
        file,
        onProgressChange: (p) => setProgress(p)
      });
    }
  }, [file]);

  if (progress < 100) {
    return <FileTile file={fileToRemoteFile(file)} progress={progress} />;
  }
  return null;
};

const ProgressBar = styled(LinearProgress)`
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  && {
    &.MuiLinearProgress-colorPrimary {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }

    .MuiLinearProgress-bar {
      border-radius: 2px;
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.palette.neutral.grey_warm_20};
    }
  }
`;

const fileToRemoteFile = (file: File): RemoteFile => ({
  id: "",
  mimeType: file.type,
  filename: file.name,
  size: file.size
});

const getFileTypeIcon = (type: string) => {
  const mimeType = mimeTypeToExtension(type);
  switch (mimeType) {
    case "mp4":
      return <VidIcon />;
    case "jpg":
      return <JPGIcon />;
    case "png":
      return <PNGIcon />;
    case "pdf":
      return <PDFIcon />;
    case "xml" || "xls":
      return <XMLIcon />;
    default:
      return <DocIcon />;
  }
};
