import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "assets/images/logo.svg";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Paths } from "routing";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { UserDropdown } from "./UserDropdown";
import { MainTabs } from "./MainTabs";
import { NotificationsButton } from "components/Notifications";

export const AuthorizedHeader = () => {
  useTranslation(["common", "createSurvey"]); //Load namespaces to prevent blinking screen during loading

  const { pathname } = useLocation();

  if (
    pathname.includes(Paths.surveys.create) ||
    pathname.includes(Paths.surveys.edit.base)
  ) {
    return <CreateModeHeader />;
  }

  return (
    <Root>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        maxWidth="1440px"
        width="100%"
        mx="auto"
      >
        <Link to="/">
          <LogoIcon />
        </Link>
        <Box height="100%" display="flex" alignItems="center" gap={5}>
          <MainTabs />
          <NotificationsButton />
          <UserDropdown />
        </Box>
      </Box>
    </Root>
  );
};

const CreateModeHeader: React.VFC = () => {
  const { t } = useTranslation(["createSurvey"]);
  const { pathname } = useLocation();
  const headingText = pathname.includes(Paths.surveys.create)
    ? t("createSurvey:createNewProject")
    : "";

  return (
    <Root>
      <Box
        maxWidth="1440px"
        width="100%"
        mx="auto"
        display="grid"
        alignItems="center"
        gridTemplateColumns="1fr 2fr 1fr"
      >
        <Link to="/">
          <LogoIcon />
        </Link>
        <CreateModeHeading>{headingText}</CreateModeHeading>
        <IconButton
          sx={{ justifySelf: "end" }}
          component={Link}
          to={`/${Paths.surveys.base}`}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Root>
  );
};

const CreateModeHeading = styled.h1`
  justify-self: center;
  margin: 0;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
`;

const Root = styled.header`
  display: grid;
  position: absolute;
  width: 100%;
  height: 70px;
  left: 0px;
  top: 0px;
  background-color: white;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
`;
