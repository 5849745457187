import React, { Fragment } from "react";
import {
  MediaType,
  RequiredType,
  Stimulus,
  StimulusMedia
} from "../../../../../dto";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from "@mui/material";
import { ReactComponent as RadioOnIcon } from "../../../../../assets/icons/radio-on.svg";
import { ReactComponent as RadioOffIcon } from "../../../../../assets/icons/radio-off.svg";
import { FileUploader } from "./FileUploader";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DisplayMode } from "../EditProject";

type StimulusMediaFormProps = {
  stimulus: Stimulus;
  updateStimulus: (update: Partial<Stimulus>) => void;
  setStimuliDisabled: (disabled: boolean) => void;
  projectId: string;
  displayMode: DisplayMode;
};

export const StimulusMediaForm: React.VFC<StimulusMediaFormProps> = ({
  stimulus,
  updateStimulus,
  setStimuliDisabled,
  projectId,
  displayMode
}) => {
  const allowedType = (type: MediaType, requiredType: RequiredType) => {
    if (requiredType === RequiredType.IMAGE) {
      return type === MediaType.IMAGE_LINK || type === MediaType.FILE;
    } else if (requiredType === RequiredType.VIDEO) {
      return type === MediaType.VIDEO_LINK || type === MediaType.FILE;
    } else {
      return true;
    }
  };

  const fileAllowed = (requiredType: RequiredType) => {
    if (requiredType === RequiredType.IMAGE) {
      return "image";
    } else if (requiredType === RequiredType.VIDEO) {
      return "video";
    }
    return "video or image";
  };

  const { t } = useTranslation(["editProject"]);
  const updateStimulusMedia = (
    mediaId: string,
    update: Partial<StimulusMedia>
  ) => {
    const indexToUpdate = stimulus.mediaList.findIndex(
      (media) => media.id === mediaId
    );
    if (indexToUpdate < 0) {
      console.error("No media to update!");
      return;
    }
    const mediaToUpdate = stimulus.mediaList[indexToUpdate];
    stimulus.mediaList[indexToUpdate] = { ...mediaToUpdate, ...update };
    updateStimulus({ mediaList: [...stimulus.mediaList] });
  };

  const isEditDisabled = displayMode !== "edit";
  const mediaNumber = stimulus.mediaList.length;

  return (
    <Stack py="40px">
      <RadioLabel>{t("editProject:stimuli.mediaUpload")}</RadioLabel>
      {mediaNumber > 1 && (
        <MediaInfo>
          {t("editProject:stimuli.mediaUploadDesc", {
            number: mediaNumber
          })}
        </MediaInfo>
      )}
      {stimulus.mediaList.map((media, index) => (
        <Fragment key={media.id}>
          {mediaNumber > 1 && (
            <RadioLabel>
              {t("editProject:stimuli.mediaUploadWithNumber", {
                number: mediaNumber > 1 ? index + 1 : ""
              })}
            </RadioLabel>
          )}
          <StyledRadioGroup
            id="media-type-radio"
            aria-label="media type"
            value={media.type}
            onChange={(e) => {
              updateStimulusMedia(media.id, {
                type: e.target.value as MediaType
              });
            }}
          >
            {allowedType(MediaType.VIDEO_LINK, media.requiredType) && (
              <FormControlLabel
                value={MediaType.VIDEO_LINK}
                control={
                  <StyledRadio
                    checkedIcon={<RadioOnIcon />}
                    icon={<RadioOffIcon />}
                    name={MediaType.IMAGE_LINK}
                    disabled={isEditDisabled}
                  />
                }
                label={t("editProject:stimuli.attachVideoLink")}
              />
            )}
            {allowedType(MediaType.IMAGE_LINK, media.requiredType) && (
              <FormControlLabel
                value={MediaType.IMAGE_LINK}
                control={
                  <StyledRadio
                    checkedIcon={<RadioOnIcon />}
                    icon={<RadioOffIcon />}
                    name={MediaType.IMAGE_LINK}
                    disabled={isEditDisabled}
                  />
                }
                label={t("editProject:stimuli.attachImageLink")}
              />
            )}
            {allowedType(MediaType.FILE, media.requiredType) && (
              <FormControlLabel
                value={MediaType.FILE}
                control={
                  <StyledRadio
                    checkedIcon={<RadioOnIcon />}
                    icon={<RadioOffIcon />}
                    name={MediaType.FILE}
                    disabled={isEditDisabled}
                  />
                }
                label={t("editProject:stimuli.uploadMediaFile", {
                  allowedFile: fileAllowed(media.requiredType)
                })}
              />
            )}
          </StyledRadioGroup>
          {media.type === MediaType.FILE ? (
            <FileUploader
              projectId={projectId}
              stimulus={stimulus}
              media={media}
              updateStimulusMedia={updateStimulusMedia}
              setStimuliDisabled={setStimuliDisabled}
              disabled={isEditDisabled}
            />
          ) : (
            <StyledTextField
              fullWidth
              id="media-url-input"
              label={
                media.type === MediaType.IMAGE_LINK
                  ? t("editProject:stimuli.imageLink", {
                      index: mediaNumber > 1 ? index + 1 : ""
                    })
                  : t("editProject:stimuli.videoLink", {
                      index: mediaNumber > 1 ? index + 1 : ""
                    })
              }
              variant="outlined"
              value={media.link || ""}
              onChange={(e) =>
                updateStimulusMedia(media.id, { link: e.target.value })
              }
              disabled={isEditDisabled}
            />
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

const RadioLabel = styled.p`
  margin: -8px 0 20px;
  font-weight: 700;
`;

const StyledRadioGroup = styled(RadioGroup)`
  && {
    margin-bottom: 32px;
    align-items: flex-start;
    gap: 12px;
  }

  & span {
    font-size: 15px;
  }

  & svg {
    transform: translateY(-1px);
  }
`;

const MediaInfo = styled.p`
  margin: -8px 0 20px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const StyledRadio = styled(Radio)`
  height: 24px;
  width: 24px;
  padding: 0;
  margin-right: 8px;
  margin-left: 8px;
`;

const StyledTextField = styled(TextField)`
  text-transform: uppercase;
`;
