import React, { useEffect } from "react";
import { LaunchProjectDialogContent } from "./LaunchProjectDialogContent";
import { useProjectState } from "contexts/ProjectStateContext";
import { useFeasibility } from "contexts/FeasibilityContext";
import { Paths } from "routing";
import { useNavigate } from "react-router";
import { CloseableDialog } from "components/Shared";

export interface LaunchFromEditDialogProps {
  open: boolean;
  onClose: () => void;
}

export const LaunchFromEditDialog: React.FC<LaunchFromEditDialogProps> = ({
  open,
  onClose
}) => {
  const { project } = useProjectState();
  const { feasibility, isLoading, isError, recalculate } = useFeasibility();
  const navigate = useNavigate();

  const { currency, costPerInterview, totalCost, requestedSampleSize } =
    feasibility;

  const handleClose = (isActive: boolean) => {
    if (isActive) {
      const { base } = Paths.surveys;
      navigate(`/${base}`);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      recalculate();
    }
  }, [open]);

  return (
    <CloseableDialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      body={
        project && (
          <LaunchProjectDialogContent
            projectId={project.id}
            isPreviewed={project.previewed}
            onClose={handleClose}
            isFeasibilityLoading={isLoading}
            isFeasibilityError={isError}
            currency={currency}
            costPerInterview={costPerInterview}
            totalCost={totalCost}
            availableSampleSize={requestedSampleSize}
          />
        )
      }
    />
  );
};
