import styled from "styled-components";
import React from "react";
import { Status } from "dto/Project";

const getTextColorByStatus = (status: Status) => {
  switch (status) {
    case Status.DRAFT:
      return "#787A8F";
    case Status.PAUSED:
      return "#000066";
    case Status.ACTIVE:
      return "#007FFF";
    case Status.TIMED_OUT:
      return "#E91B29";
    case Status.COMPLETED:
      return "#14A344";
    case Status.QUOTE_REQUESTED:
      return "#000066";
    default:
      return "#eeeeee";
  }
};

const getBackgroundColorByStatus = (status: Status) => {
  switch (status) {
    case Status.DRAFT:
      return "rgba(120, 122, 143, 0.1)";
    case Status.PAUSED:
      return "rgba(0, 0, 102, 0.1)";
    case Status.ACTIVE:
      return "rgba(0, 127, 255, 0.1)";
    case Status.TIMED_OUT:
      return "rgba(233, 27, 41, 0.1)";
    case Status.COMPLETED:
      return "rgba(20, 163, 68, 0.1)";
    case Status.QUOTE_REQUESTED:
      return "#F1F1F9";
    default:
      return "rgba(120, 122, 143, 0.1)";
  }
};

interface StatusBoxProps {
  status: Status;
}

export const StatusBox: React.FC<StatusBoxProps> = ({ status, children }) => {
  return <StyledBox status={status}>{children}</StyledBox>;
};

export const StyledBox = styled(({ status, ...otherProps }) => (
  <span {...otherProps} />
))`
  background-color: ${(props) => getBackgroundColorByStatus(props.status)};
  color: ${(props) => getTextColorByStatus(props.status)};
  padding: 5px 8px 3px 8px;
`;
