import {
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import { queryKeys } from "api/queryClient";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { useNotificationContext } from "contexts/NotificationContext";
import { UserDetailsDto, UserRole } from "dto";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation } from "react-query";
import { updateUserRole } from "services/userService";

interface EditRoleDialogProps {
  open: boolean;
  user: UserDetailsDto;
  onClose: () => void;
}

export const EditRoleDialog: React.VFC<EditRoleDialogProps> = ({
  open,
  user,
  onClose
}) => {
  const { t } = useTranslation(["user", "common"]);
  const defaultUserRole = user.role;
  const [role, setRole] = useState<UserRole>(defaultUserRole);
  const userRoles: UserRole[] = user.group.isMainGroup
    ? ["ADMIN", "PROJECT_MANAGER"]
    : ["EXTERNAL_BASIC_USER", "EXTERNAL_POWER_USER"];

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateUserRole, {
    onSuccess: async () => {
      displaySuccessSnackbar(t("user:updateRole.successFeedback"));
      await queryClient.invalidateQueries(queryKeys.user.all);
      onClose();
    },
    onError: () => {
      displayErrorSnackbar();
    }
  });

  const handleConfirmChange = () => {
    if (role) {
      mutate({ userId: user.id, role });
    }
  };

  const handleOnClose = () => {
    setRole(defaultUserRole);
    onClose();
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={handleOnClose}
      body={
        <>
          <Typography variant="h2" align="center" mb={10}>
            <b>{t("user:updateRole.heading")}</b>
          </Typography>
          <Box>
            <InputLabel shrink htmlFor="invite-user-role-field">
              {t(`user:role`)}
            </InputLabel>
            <Select
              margin="dense"
              fullWidth
              name="role"
              id="invite-user-role-field"
              value={role}
              onChange={(e) => setRole(e.target.value as UserRole)}
              variant="standard"
            >
              {userRoles.map((role) => (
                <MenuItem key={role} value={role}>
                  {t(`common:enums.UserRole.${role}`)}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box mt={10} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={handleOnClose}
            >
              {t("common:cancel")}
            </Button>

            <LoadingButton
              isLoading={isLoading}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleConfirmChange}
            >
              {t("common:confirm")}
            </LoadingButton>
          </Box>
        </>
      }
    />
  );
};
