import { Box, TableCell, Typography } from "@mui/material";
import styled from "styled-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserListDto } from "dto";
import { DataTable, SortButton, UserAvatar } from "components/Shared";
import { SortSetting } from "utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNavigate } from "react-router";
import { compareStrings, compareUnix } from "utils/comparators";

dayjs.extend(relativeTime);
export interface UsersTableProps {
  users: UserListDto[];
  emptyFallback: React.ReactNode;
  withDetails?: boolean;
}

export const UsersTable: React.VFC<UsersTableProps> = ({
  users,
  emptyFallback,
  withDetails = false
}) => {
  const { t } = useTranslation(["group", "common"]);
  const i18nBase = "group:details";
  const navigate = useNavigate();
  const [sortSettings, setSortSettings] = useState<SortSetting>();
  const sortedUsers = useMemo(
    () => sortUsers(users, sortSettings),
    [users, sortSettings]
  );

  return (
    <DataTable
      data={sortedUsers}
      emptyFallback={emptyFallback}
      columnsNumber={5}
      onRowClick={withDetails ? ({ id }) => navigate(id) : undefined}
      head={
        <>
          <TableCell width={300}>
            <SortButton
              fieldName="name"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.tableHeader.name`)}
            </SortButton>
          </TableCell>
          <TableCell>
            <SortButton
              fieldName="status"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.tableHeader.status`)}
            </SortButton>
          </TableCell>
          <TableCell>
            <SortButton
              fieldName="lastActive"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.tableHeader.lastActive`)}
            </SortButton>
          </TableCell>
          <TableCell>
            <SortButton
              fieldName="role"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.tableHeader.role`)}
            </SortButton>
          </TableCell>
          <TableCell align="left" />
        </>
      }
      renderRow={(user) => {
        const isDisabled = user.status === "DISABLED";
        const textColor = isDisabled ? "textSecondary" : "textPrimary";
        return (
          <>
            <TableCell>
              <NameWrapper>
                <UserAvatar name={user.name} />
                <Box>
                  <Typography variant="subtitle2" color={textColor} noWrap>
                    {user.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" noWrap>
                    {user.email}
                  </Typography>
                </Box>
              </NameWrapper>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color={textColor}>
                {t(`common:enums.UserStatus.${user.status}`)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color={textColor}>
                {user.lastActive ? dayjs.unix(user.lastActive).fromNow() : "-"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color={textColor}>
                {t(`common:enums.UserRole.${user.role}`)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <UserDetails variant="body2" color="primary">
                {t(`${i18nBase}.userDetails`)}
              </UserDetails>
            </TableCell>
          </>
        );
      }}
    />
  );
};

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 19px;
`;

const UserDetails = styled(Typography)`
  opacity: 0;

  .MuiTableRow-hover:hover & {
    opacity: 1;
  }
`;

const sortUsers = (users: UserListDto[], sortSettings: SortSetting) => {
  if (!sortSettings) {
    return users;
  }
  const { fieldName, order } = sortSettings;
  switch (fieldName) {
    case "name":
    case "status":
    case "role":
      return [...users].sort(compareStrings(order, (u) => u[fieldName]));
    case "lastActive":
      return [...users].sort(compareUnix(order, (u) => u[fieldName]));
    default:
      return users;
  }
};
