import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  ProjectPropertiesPayload,
  updateManyPlaceholders,
  updateManyStimuli,
  updateProject as updateProjectProperties,
  UpdateProjectConfigPayload,
  updateProjectConfiguration
} from "../services/projectService";
import { Placeholder, Stimulus } from "../dto/Project";
import { useNotificationContext } from "./NotificationContext";
import { queryKeys } from "api/queryClient";

export enum EditStatus {
  SAVED,
  SAVING,
  FAILED
}

interface EditProjectContextType {
  status: EditStatus | undefined;
  updateConfig: (
    update: UpdateProjectConfigPayload,
    actionToPerformOnSuccess?: () => void
  ) => void;
  updateStimuli: (projectId: string, stimuliToUpdate: Stimulus[]) => void;
  updateProject: (
    projectId: string,
    projectProperties: ProjectPropertiesPayload,
    actionToPerformOnSuccess?: () => void
  ) => void;
  updatePlaceholders: (
    projectId: string,
    placeholders: Placeholder[],
    actionToPerformOnSuccess?: () => void
  ) => void;
}

const EditProjectContext = React.createContext<EditProjectContextType>({
  status: undefined,
  updateConfig: () => {
    throw Error("cannot find EditProjectContext Provider!");
  },
  updateStimuli: () => {
    throw Error("cannot find EditProjectContext Provider!");
  },
  updateProject: () => {
    throw Error("cannot find EditProjectContext Provider!");
  },
  updatePlaceholders: () => {
    throw Error("cannot find EditProjectContext Provider!");
  }
});

export const EditProjectProvider: React.FC = ({ children }) => {
  const [status, setStatus] = useState(EditStatus.SAVED);
  const queryClient = useQueryClient();

  const setSaving = () => setStatus(EditStatus.SAVING);
  const setSaved = (id: string) => {
    setStatus(EditStatus.SAVED);
    queryClient.invalidateQueries(queryKeys.projects.byId(id));
  };
  const setFail = () => {
    displayErrorSnackbar();
    setStatus(EditStatus.FAILED);
  };
  const { displayErrorSnackbar } = useNotificationContext();

  const getMutationOptions = (id: string, actionToPerform?: () => void) => ({
    onSuccess: () => {
      setSaved(id);
      actionToPerform?.();
    },
    onError: () => setFail()
  });

  const { mutate: mutateConfig } = useMutation(updateProjectConfiguration);
  const { mutate: mutateStimuli } = useMutation(updateManyStimuli);
  const { mutate: mutateProject } = useMutation(updateProjectProperties);
  const { mutate: mutatePlaceholders } = useMutation(updateManyPlaceholders);

  const updateStimuli = (projectId: string, stimuliToUpdate: Stimulus[]) => {
    setSaving();
    mutateStimuli(
      { projectId: projectId, stimuli: stimuliToUpdate },
      { ...getMutationOptions(projectId) }
    );
  };

  const updateProject = (
    projectId: string,
    projectProperties: ProjectPropertiesPayload,
    actionToPerformOnSuccess?: () => void
  ) => {
    setSaving();
    mutateProject(
      { projectId: projectId, projectProperties: projectProperties },
      { ...getMutationOptions(projectId, actionToPerformOnSuccess) }
    );
  };

  const updateConfig = (
    payload: UpdateProjectConfigPayload,
    actionToPerformOnSuccess?: () => void
  ) => {
    if (payload) {
      setSaving();
      mutateConfig(
        {
          projectId: payload.projectId,
          config: {
            ageId: payload.config.ageId,
            gender: payload.config.gender,
            groupSizePerStimuli: payload.config.groupSizePerStimuli,
            languageId: payload.config.languageId,
            numberOfStimuli: payload.config.numberOfStimuli,
            supportedDevices: payload.config.supportedDevices
          }
        },
        { ...getMutationOptions(payload.projectId, actionToPerformOnSuccess) }
      );
    }
  };

  const updatePlaceholders = (
    projectId: string,
    placeholders: Placeholder[],
    actionToPerformOnSuccess?: () => void
  ) => {
    setSaving();
    mutatePlaceholders(
      { projectId, placeholders },
      { ...getMutationOptions(projectId, actionToPerformOnSuccess) }
    );
  };

  const value = {
    status,
    updateConfig,
    updateStimuli,
    updateProject,
    updatePlaceholders
  };

  return (
    <EditProjectContext.Provider value={value}>
      {children}
    </EditProjectContext.Provider>
  );
};

export const useEditProject = () => {
  const {
    status,
    updateConfig,
    updateStimuli,
    updateProject,
    updatePlaceholders
  } = useContext(EditProjectContext);
  return {
    status,
    updateConfig,
    updateStimuli,
    updateProject,
    updatePlaceholders
  };
};
