import axios, { AxiosRequestConfig } from "axios";
import { urls } from "api/urls";
import { clearUserAuth, getStoredToken } from "../auth/auth-storage";
import { refreshToken } from "../auth/auth-client";

export const httpClient = axios.create({
  baseURL: window.location.origin
});

httpClient.interceptors.request.use((request) => {
  return addTokenIfRequired(request);
});

httpClient.interceptors.response.use(
  (res) => res,
  (err) => {
    const originalReq = err.config;
    if (originalReq.url === urls.config.awsCognito) {
      return Promise.reject(err);
    }
    if (err.response.status === 401 && !originalReq._retry) {
      originalReq._retry = true;
      return refreshToken()
        .then(() => axios(addTokenIfRequired(originalReq)))
        .catch(() => {
          clearUserAuth();
          window.location.reload();
        });
    }
    return Promise.reject(err);
  }
);

function addTokenIfRequired(request: AxiosRequestConfig): AxiosRequestConfig {
  const token = getStoredToken();
  if (!token) {
    return request;
  }
  if (request.url === urls.config.awsCognito) {
    return request;
  }

  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = { Authorization: `Bearer ${token}` };
  }
  return request;
}
