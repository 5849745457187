import { PageableParams } from "dto/common";
import { FolderStatus } from "dto/Folder";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const queryKeys = {
  projects: {
    all: ["projects"],
    list: (params: PageableParams) => ["projects", { params }],
    feasibilityById: (id: string) => ["projects", "feasibility", id],
    byId: (id: string) => ["projects", "id", id]
  },
  group: {
    all: ["groups"],
    list: (params: PageableParams) => ["groups", { params }],
    byId: (id: string) => ["groups", "id", id]
  },
  reports: {
    all: ["reports"],
    list: (projectId: string) => [...queryKeys.reports.all, projectId],
    listByParams: (projectId: string, params: PageableParams) => [
      ...queryKeys.reports.list(projectId),
      { params }
    ]
  },
  potentialManagers: ["managers"],
  templates: ["templates"],
  user: {
    all: ["users"],
    byId: (id: string) => ["users", "id", id]
  },
  folders: {
    all: ["folders"],
    list: (
      params: PageableParams & {
        status: FolderStatus;
        group?: string;
        search?: string;
      }
    ) => ["folders", { params }],
    byId: (id: string) => ["folders", "id", id]
  },
  notifications: {
    isSomethingToRead: ["notifications", "something-to-read"]
  }
};
