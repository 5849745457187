import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { AddManagerDialog } from "./AddManagerDialog";
import { GroupDetailsDto } from "dto/Group";

export const AddManagerButton = ({ group }: { group: GroupDetailsDto }) => {
  const { t } = useTranslation(["group"]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        color="secondary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        {t("group:details.addManager.heading")}
      </Button>
      <AddManagerDialog
        group={group}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
