import { Age, Language } from "./ProjectConfiguration";

export interface Template {
  base64Background: string;
  description: string;
  id: string;
  previewUrl: string;
  shortCode: string;
  title: string;
}

export interface DetailedTemplate extends Template {
  availableAge: Age[];
  availableLanguages: Language[];
  dynataSecurityKeyQueryParamName: string;
  emptyRequiredQueryParamNames: string[];
  languageQueryParamName: string;
  nextProjectIndex: [
    {
      index: 0;
      queryParamName: string;
    }
  ];
  placeholderGroups: PlaceholderGroup[];
  referenceNoQueryParamName: string;
  stimuliNumber: number;
  stimuliNameAlias: string;
  surveyIdQueryParamName: string;
}

export interface PlaceholderGroup {
  id: string;
  description: string;
  name: string;
  sections: PlaceholderSection[];
}

export interface InfoBox {
  type: "HTML" | string;
  title: string;
  content: string;
}

export interface PlaceholderSection {
  description?: string;
  name?: string;
  infobox?: InfoBox;
  placeholders: PlaceholderDetails[];
}

export interface PlaceholderDetails {
  id: string;
  key: string;
  label: string;
  sentence?: string;
  exampleSentences: PlaceholderExampleSentence[];
  tip?: string;
  fieldType: FieldType;
  allowedValues: PlaceholderAllowedValue[];
  extraUsage: string;
  description: string;
  hide: boolean;
}

export interface PlaceholderExampleSentence {
  locale: string;
  value: string;
}

export enum FieldType {
  INPUT = "INPUT",
  SELECT = "SELECT",
  SLIDER = "SLIDER",
  SELECT_COUNTRY_BASED = "SELECT_COUNTRY_BASED"
}

export interface PlaceholderAllowedValue {
  id: string;
  value: string;
  label: string;
  locale: string;
}
