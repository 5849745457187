import { Tooltip } from "@mui/material";
import reports from "assets/icons/reports.svg";
import ImageIconButton from "components/Shared/ImageIconButton";
import { useTranslation } from "react-i18next";
import { useOpen } from "utils/useOpen";
import ReportsDialog from "./ReportsDialog/ReportsDialog";

interface ReportsButtonProps {
  projectId: string;
}

const ReportsButton = ({ projectId }: ReportsButtonProps) => {
  const { t } = useTranslation(["surveyList"]);
  const { open, close, isOpen } = useOpen();

  return (
    <>
      <Tooltip title={t("surveyList:reports.tooltip")}>
        <ImageIconButton
          onClick={open}
          iconAlt="reports list"
          iconSource={reports}
          sx={{ marginRight: "8px" }}
        />
      </Tooltip>

      <ReportsDialog close={close} isOpen={isOpen} projectId={projectId} />
    </>
  );
};

export default ReportsButton;
