import { SortOrder } from "./useUrlSortSettings";

export const compareStrings =
  <T>(order: SortOrder, getter: (v: T) => string) =>
  (object1: T, object2: T) => {
    let value1 = getter(object1);
    let value2 = getter(object2);
    if (order === "asc") {
      [value1, value2] = [value2, value1];
    }
    return value2.localeCompare(value1);
  };

export const compareUnix =
  <T>(order: SortOrder, getter: (v: T) => number) =>
  (object1: T, object2: T) => {
    let value1 = getter(object1);
    let value2 = getter(object2);
    if (order === "asc") {
      [value1, value2] = [value2, value1];
    }
    return value1 - value2;
  };
