import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@mui/icons-material";
import { EditFolderDialog } from "./EditFolderDialog";
import { FolderDetailsDto, FolderListItem } from "dto/Folder";

export const EditFolderButton = ({
  folder,
  disabled,
  handleClosePopper
}: {
  folder: FolderDetailsDto | FolderListItem;
  disabled: boolean;
  handleClosePopper: () => void;
}) => {
  const { t } = useTranslation(["folders"]);
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
    handleClosePopper();
  };

  return (
    <>
      <MenuItem disabled={disabled} onClick={() => handleOnClose()}>
        <ListItemIcon>
          <EditOutlined sx={{ color: "neutral.w_black" }} />
        </ListItemIcon>
        <ListItemText primary={t("folders:buttons.editFolder")} />
      </MenuItem>
      <EditFolderDialog
        open={open}
        onClose={() => handleOnClose()}
        folder={folder}
      />
    </>
  );
};
