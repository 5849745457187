import React, { useState } from "react";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { renameFile } from "services/foldersService";
import { queryKeys } from "api/queryClient";
import { useRouteParam } from "routing";
import { RemoteFile } from "dto";

export interface RenameFileDialogProps {
  open: boolean;
  onClose: () => void;
  file: RemoteFile;
}

export const RenameFileDialog: React.VFC<RenameFileDialogProps> = ({
  open,
  onClose,
  file
}) => {
  const { t } = useTranslation(["folders", "common"]);
  const folderId = useRouteParam("FolderId");
  const [name, setName] = useState(file.filename);

  const queryClient = useQueryClient();
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();

  const { isLoading, mutate } = useMutation(renameFile, {
    onSuccess: () => {
      displaySuccessSnackbar(t("folders:files.rename.successFedback"));
      queryClient.invalidateQueries(queryKeys.folders.all);
      onClose();
    },
    onError: () => displayErrorSnackbar()
  });

  const handleConfirmChange = () => {
    if (!name) {
      displayErrorSnackbar(t("folders:files.rename.wrongInputFeedback"));
      return;
    }
    mutate({ folderId, fileId: file.id, name });
  };

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      body={
        <>
          <Typography variant="h2" align="center" mb={3}>
            {t("folders:files.rename.title")}
          </Typography>
          <TextField
            sx={{ mt: 5 }}
            fullWidth
            id="rename-file-field"
            label={t("folders:files.rename.label")}
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Box mt={10} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={onClose}
            >
              {t("common:cancel")}
            </Button>

            <LoadingButton
              isLoading={isLoading}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleConfirmChange}
            >
              {t("common:confirm")}
            </LoadingButton>
          </Box>
        </>
      }
    />
  );
};
