import { PageableParams } from "dto/common";
import { PagedNotifications } from "dto";
import { urls } from "api/urls";
import { httpClient } from "api/httpClient";

export const getNotifications = async ({
  page,
  size
}: Omit<PageableParams, "sort">) => {
  const response = await httpClient.get<PagedNotifications>(
    urls.notifications,
    { params: { page, size } }
  );
  return response.data;
};

export const getIsSomethingToRead = async () => {
  const response = await httpClient.get<{ newAvailable: boolean }>(
    `${urls.notifications}/new-available`
  );
  return response.data.newAvailable;
};

export const setNotificationAsRead = async (id: string) => {
  await httpClient.put<void>(`${urls.notifications}/${id}/read`);
};
