import { PlaceholderGroup } from "dto/Template";
import React, { useContext } from "react";
import { Age, Language } from "../dto/ProjectConfiguration";

export interface StaticDataContextType {
  availableAge: Age[];
  availableLanguages: Language[];
  placeholderGroups: PlaceholderGroup[];
  adjustableStimuli: boolean;
  maxStimuliNumber: number;
  stimuliNameAlias: string;
}

export const StaticDataContext = React.createContext<StaticDataContextType>({
  availableAge: [],
  availableLanguages: [],
  placeholderGroups: [],
  adjustableStimuli: false,
  maxStimuliNumber: 1,
  stimuliNameAlias: "Ad"
});

export const useStaticDataState = () => {
  const {
    availableAge,
    availableLanguages,
    placeholderGroups,
    adjustableStimuli,
    maxStimuliNumber,
    stimuliNameAlias
  } = useContext(StaticDataContext);
  return {
    availableAge,
    availableLanguages,
    placeholderGroups,
    adjustableStimuli,
    maxStimuliNumber,
    stimuliNameAlias
  };
};
