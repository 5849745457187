import { DetailedTemplate, Template } from "dto/Template";
import { httpClient } from "../api/httpClient";
import { urls } from "../api/urls";

export const getAllTemplates = async (): Promise<Template[]> => {
  const { data } = await httpClient.get<Template[]>(urls.survey.templates);
  return data;
};

// due to using 'enabled' in react query options there won't be possible to pass undefined here
// unfortunately useQuery is not so fluent with typescript so I had to this hack
export const getTemplateById = async (
  id: string | undefined
): Promise<DetailedTemplate> => {
  const { data } = await httpClient.get<DetailedTemplate>(
    `${urls.survey.templates}/${id}`
  );
  return data;
};
