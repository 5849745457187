import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

export const ChangePasswordButton: React.VFC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      <ChangePasswordDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
