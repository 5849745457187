import { SurveyReportBody } from "dto/Reports";
import { useMutation, useQueryClient } from "react-query";
import { addSurveyReport, removeSurveyReport } from "./endpoints";
import { queryKeys } from "./queryClient";

export const useAddSurveyReport = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, body }: { projectId: string; body: SurveyReportBody }) =>
      addSurveyReport(projectId, body),
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries(queryKeys.reports.list(projectId));
      }
    }
  );
};

export const useRemoveSurveyReport = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ reportId }: { projectId: string; reportId: string }) =>
      removeSurveyReport(reportId),
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries(queryKeys.reports.list(projectId));
      }
    }
  );
};
