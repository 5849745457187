import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface QueryErrorFeedbackProps {
  retry: () => void;
}

export const QueryErrorFeedback: React.VFC<QueryErrorFeedbackProps> = ({
  retry
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Box mx="auto" display="flex" flexDirection="column" alignItems="center">
      <Typography>{t("common:errorOccurred")}</Typography>
      <Button size="medium" color="primary" onClick={retry}>
        {t("common:tryAgain")}
      </Button>
    </Box>
  );
};
