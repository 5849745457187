import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  IconButton
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { Box } from "@mui/system";

export interface CloseableDialogProps extends DialogProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  bodyProps?: DialogContentProps;
}

export const CloseableDialog: React.VFC<CloseableDialogProps> = ({
  header,
  body,
  footer,
  bodyProps,
  ...dialogProps
}) => {
  const { onClose } = dialogProps;
  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <IconButton
        onClick={(e) => onClose?.(e, "backdropClick")}
        sx={{ position: "absolute", top: 10, right: 10 }}
      >
        <CloseIcon />
      </IconButton>

      {header && (
        <Box
          sx={{
            paddingX: 10,
            paddingTop: 10,
            paddingBottom: 5,
            marginBottom: 10,
            borderBottom: "1px solid #DFE1F2"
          }}
        >
          {header}
        </Box>
      )}

      {body && (
        <DialogContent
          {...bodyProps}
          sx={{
            paddingX: 10,
            paddingY: 0,
            marginTop: header ? 0 : 15,
            marginBottom: footer ? 0 : 10,
            ...bodyProps?.sx
          }}
        >
          {body}
        </DialogContent>
      )}

      {footer && (
        <DialogActions
          sx={{
            paddingX: 10,
            paddingTop: 5,
            paddingBottom: 8,
            marginTop: 10,
            justifyContent: "space-between",
            borderTop: "1px solid #DFE1F2"
          }}
        >
          {footer}
        </DialogActions>
      )}
    </Dialog>
  );
};
