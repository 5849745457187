import { Box, InputLabel, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PlaceholderAllowedValue,
  PlaceholderDetails
} from "../../../../../../dto";
import styled from "styled-components";
import percentageIcon from "assets/icons/percentage.png";
import { useDebounce } from "../../../../../../utils";
import { PlaceholderValuesDialog } from "./PlaceholderValuesDialog";
import { ReactComponent as InfoIcon } from "../../../../../../assets/icons/info.svg";

type SliderFieldProps = {
  placeholderConfig: PlaceholderDetails;
  value: string;
  updatePlaceholderValue: (key: string, value: string) => void;
  disabled?: boolean;
};

const getDefaultValueForSlider = (value: string) =>
  value ? parseInt(value) : 20;

const findMinForSlider: (allowedValues: PlaceholderAllowedValue[]) => number = (
  allowedValues: PlaceholderAllowedValue[]
) => {
  const foundMin = allowedValues.find((val) => val.label === "Min");
  return foundMin ? parseInt(foundMin.value) : 20;
};

const getMarksForSlider = [
  {
    value: 20,
    label: "20%"
  },
  {
    value: 100,
    label: "100%"
  }
];

const sliderValueText = (value: number) => {
  return `${value}%`;
};

export const SliderField: React.FC<SliderFieldProps> = ({
  placeholderConfig: { key, label, allowedValues, sentence, description },
  value,
  updatePlaceholderValue,
  disabled
}) => {
  const [sliderValue, setSliderValue] = useState(
    getDefaultValueForSlider(value)
  );
  const debouncedSliderValue = useDebounce(sliderValue, 500);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (+value !== sliderValue) {
      updatePlaceholderValue(key, String(sliderValue));
    }
  }, [debouncedSliderValue]);

  const onChange = (value: number | number[]) => {
    if (typeof value === "number") {
      setSliderValue(value);
    } else {
      console.error("Wrong slider value!");
    }
  };

  return (
    <>
      <SliderWrapper>
        <SliderHeading>
          <PercentageIcon src={percentageIcon} />
          <SliderLabel id={`${key}-label`}>{label}</SliderLabel>
        </SliderHeading>
        <SentenceSection>
          {sentence && <SliderSentence>{sentence}</SliderSentence>}
          {!!description && (
            <IconWrapper>
              <StyledInfoIcon
                height={36}
                width={36}
                onClick={() => setOpen(true)}
              />
              <PlaceholderValuesDialog
                textDescription={description}
                open={open}
                onClose={() => setOpen(false)}
              />
            </IconWrapper>
          )}
        </SentenceSection>
        <Slider
          defaultValue={sliderValue}
          min={findMinForSlider(allowedValues)}
          aria-label={key}
          getAriaValueText={sliderValueText}
          marks={getMarksForSlider}
          onChange={(e, newValue) => onChange(newValue)}
          valueLabelDisplay="auto"
          disabled={disabled}
        />
      </SliderWrapper>
    </>
  );
};

const SliderWrapper = styled(Box)`
  width: 100%;
`;

const SliderHeading = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const PercentageIcon = styled.img`
  max-width: 100%;
  margin-right: 5px;
`;

const SliderLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
`;

const SliderSentence = styled.p`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const SentenceSection = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  padding-top: 15px;
  justify-content: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
`;
