export type ConfigurablePlaceholder = {
  key: string;
  label: string;
  hide: boolean;
};

export const websiteOrAppPKey = "#websiteorapp#";
export const placeholderKeysConfiguredByOthers = [
  "#URL#",
  "#website1#",
  "#website2#",
  "#website3#",
  "#website4#",
  "#website5#",
  "#websiteuse#"
];

export const websiteAppBehaviour: Record<number, ConfigurablePlaceholder[]> = {
  1: [
    {
      key: "#URL#",
      label: "URL of the target website",
      hide: false
    },
    {
      key: "#website1#",
      label: "Target website",
      hide: false
    },
    {
      key: "#website2#",
      label: "Distractor website 1",
      hide: false
    },
    {
      key: "#website3#",
      label: "Distractor website 2",
      hide: false
    },
    {
      key: "#website4#",
      label: "Distractor website 3",
      hide: false
    },
    {
      key: "#website5#",
      label: "Distractor website 4",
      hide: false
    },
    {
      key: "#websiteuse#",
      label: "Favorite website",
      hide: false
    }
  ],
  2: [
    {
      key: "#URL#",
      label: "",
      hide: true
    },
    {
      key: "#website1#",
      label: "Target app",
      hide: false
    },
    {
      key: "#website2#",
      label: "Distractor app 1",
      hide: false
    },
    {
      key: "#website3#",
      label: "Distractor app 2",
      hide: false
    },
    {
      key: "#website4#",
      label: "Distractor app 3",
      hide: false
    },
    {
      key: "#website5#",
      label: "Distractor app 4",
      hide: false
    },
    {
      key: "#websiteuse#",
      label: "Favorite app",
      hide: false
    }
  ]
};
