import { EditOutlined } from "@mui/icons-material";
import { Box, Typography, Grid, ButtonBase } from "@mui/material";
import { usePermissions } from "contexts/Auth";
import { useTranslation } from "react-i18next";
import { useOpen } from "utils/useOpen";
import { CreateProjectDialog } from "../CreateSurvey/CreateProjectDialog";
import { DisplayMode } from "./EditProject";

const templateTypesToAdjust = ["PI", "AB"];

const getProperTemplateCode = (name: string) => {
  if (templateTypesToAdjust.includes(name)) {
    switch (name) {
      case "PI":
        return "IBX AB";
      case "AB":
        return "IBX PDP";
    }
  }
  return name;
};

export const GeneralInfo = ({
  templateName,
  projectName,
  projectId,
  groupName,
  referenceNo,
  displayMode
}: {
  templateName: string;
  projectName: string;
  projectId: string;
  groupName: string;
  referenceNo: string;
  displayMode: DisplayMode;
}) => {
  const { t } = useTranslation(["editProject", "common"]);
  const { open, close, isOpen } = useOpen();
  const { canEdit } = usePermissions("projectPermissions");
  const isEditable = canEdit && displayMode === "edit";

  return (
    <Box p={6} pb={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">
          {t("editProject:info.header")}
        </Typography>
        {isEditable && (
          <ButtonBase
            onClick={open}
            sx={{ width: 32, height: 32, borderRadius: "50%" }}
          >
            <EditOutlined sx={{ width: 16 }} />
          </ButtonBase>
        )}
      </Box>
      <Grid mt={3} container>
        <Grid item mt={3} md={4}>
          <Typography variant="body2">
            {t("editProject:info.templateName")}
          </Typography>
        </Grid>
        <Grid item mt={3} md={8}>
          <Typography noWrap>{getProperTemplateCode(templateName)}</Typography>
        </Grid>
        <Grid item mt={3} md={4}>
          <Typography variant="body2">
            {t("editProject:info.projectName")}
          </Typography>
        </Grid>
        <Grid item mt={3} md={8}>
          <Typography noWrap>{projectName}</Typography>
        </Grid>
        <Grid item mt={3} md={4}>
          <Typography variant="body2">
            {t("editProject:info.groupName")}
          </Typography>
        </Grid>
        <Grid item mt={5} md={8}>
          <Typography noWrap>{groupName}</Typography>
        </Grid>
        <Grid item mt={5} md={4}>
          <Typography variant="body2">
            {t("editProject:info.referenceNumber")}
          </Typography>
        </Grid>
        <Grid item mt={5} md={8}>
          <Typography noWrap>{referenceNo}</Typography>
        </Grid>
      </Grid>
      <CreateProjectDialog
        open={isOpen}
        onClose={close}
        edit={{
          id: projectId,
          name: projectName,
          ref: referenceNo
        }}
      />
    </Box>
  );
};
