export interface Configuration {
  demographics: {
    age: Age;
    gender: Gender;
  };
  groupSizePerStimuli: number;
  language: Language;
  numberOfStimuli: number;
  supportedDevices: Device[];
}

export interface AgeSplit {
  from: number;
  to: number;
  splitShare: number;
}

export interface Age {
  name: string;
  id: string;
  splits: AgeSplit[];
}

export interface Language {
  locale: string;
  label: string;
  id: string;
}

export enum Gender {
  ALL = "ALL",
  FEMALE = "FEMALE",
  MALE = "MALE"
}

export enum Device {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE"
}
