import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

const buildVersion = document
  .querySelector('meta[name="build-version"]')
  ?.getAttribute("content");

const backendOptions = {
  loadPath: `/locales/{{lng}}/{{ns}}.json?v=${buildVersion}`,
  allowMultiLoading: false
};

i18n.use(HttpBackend).use(initReactI18next).init({
  backend: backendOptions,
  fallbackLng: "en"
});

export default i18n;
