import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import {
  Placeholder,
  PlaceholderAllowedValue,
  PlaceholderDetails
} from "../../../../../../dto";
import styled from "styled-components";
import { useProjectState } from "../../../../../../contexts/ProjectStateContext";
import { PlaceholderValuesDialog } from "./PlaceholderValuesDialog";
import { ReactComponent as InfoIcon } from "../../../../../../assets/icons/info.svg";

type CountryBasedSelectProps = {
  placeholderConfig: PlaceholderDetails;
  placeholder: Placeholder;
  updatePlaceholderValue: (
    key: string,
    value: string,
    allowedValId: string
  ) => void;
  disabled?: boolean;
};

export const CountryBasedSelect: React.FC<CountryBasedSelectProps> = ({
  placeholderConfig: { key, label, allowedValues, sentence, description },
  placeholder: { allowedValueId },
  updatePlaceholderValue,
  disabled
}) => {
  const { project } = useProjectState();
  const locale = project.configuration.language.locale;
  const values = filterValuesPerLocale(locale, allowedValues);
  const onValueChange = (key: string, id: string | null) => {
    if (!id || id === allowedValueId) {
      return;
    }
    const allowedValue = values.find((val) => val.id === id);
    if (!allowedValue) {
      console.error("No value found!");
      return;
    }
    updatePlaceholderValue(key, allowedValue.label, id);
  };
  const [open, setOpen] = useState(false);

  return (
    <>
      {sentence && <Sentence>{sentence}</Sentence>}
      <FormControlWrapper variant="outlined">
        <CustomSelectLabel id={`${key}-label`}>{label}</CustomSelectLabel>
        <SelectWrapper>
          <Select
            labelId={`${key}-label`}
            id={`${key}`}
            value={allowedValueId ? allowedValueId : null}
            onChange={(e) => onValueChange(key, e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            disabled={disabled}
          >
            {values.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {!!description && (
            <IconWrapper>
              <StyledInfoIcon
                height={36}
                width={36}
                onClick={() => setOpen(true)}
              />
              <PlaceholderValuesDialog
                textDescription={description}
                open={open}
                onClose={() => setOpen(false)}
                language={project.configuration.language}
              />
            </IconWrapper>
          )}
        </SelectWrapper>
      </FormControlWrapper>
    </>
  );
};

const filterValuesPerLocale = (
  locale: string,
  allowedValues: PlaceholderAllowedValue[]
) => {
  let valuesToReturn: PlaceholderAllowedValue[] = [];
  switch (locale) {
    case "en_GB":
      valuesToReturn = allowedValues.filter((val) => val.locale === "en_GB");
      break;
    case "en_US":
      valuesToReturn = allowedValues.filter((val) => val.locale === "en_US");
      break;
    case "de_DE":
      valuesToReturn = allowedValues.filter((val) => val.locale === "de_DE");
      break;
    default:
      valuesToReturn = [];
  }
  return valuesToReturn.map((value) => {
    const labelWithoutCountryPrefix = value.label.substring(
      value.label.indexOf("_") + 1
    );
    return { ...value, label: labelWithoutCountryPrefix };
  });
};

const Sentence = styled.p`
  margin: 0 0 24px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const SelectWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const FormControlWrapper = styled(FormControl)`
  width: 100%;
`;

const CustomSelectLabel = styled(InputLabel)`
  padding: 0 5px;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const IconWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  margin-left: 10px;
  justify-content: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
`;
