import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { Button, ButtonProps } from "@mui/material";
import styled from "styled-components";

export const PrevButton: React.VFC<ButtonProps> = (props) => {
  return (
    <StyledButton {...props} variant="outlined" color="secondary">
      <ArrowIcon />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  && {
    stroke: ${({ theme }) => theme.palette.neutral.oxford_blue};
  }

  & svg {
    transform: scaleX(-1);
  }
`;
