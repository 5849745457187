import React, { useEffect, useRef, useState } from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { ReactComponent as CountryIcon } from "assets/icons/country.svg";
import { ReactComponent as DeviceIcon } from "assets/icons/device.svg";
import { ReactComponent as PieChartIcon } from "assets/icons/pie-chart.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import styled from "styled-components";
import { useStaticDataState } from "../../../../../contexts/StaticDataContext";
import { CellSizeField } from "./CellSizeField";
import { ChooseOptionField } from "./ChooseOptionField";
import { DemographicField, DemographicsState } from "./DemographicsField";
import { SupportedDevicesField } from "./SupportedDevicesField";
import { useTranslation } from "react-i18next";
import { Configuration, Device } from "../../../../../dto/ProjectConfiguration";
import { ProjectConfigPayload } from "../../../../../services/projectService";
import { DisplayMode } from "../EditProject";

export interface SampleFormProps {
  projectConfiguration: Configuration;
  onConfigUpdate: (project: ProjectConfigPayload) => void;
  displayMode: DisplayMode;
}

export const SampleForm: React.FC<SampleFormProps> = ({
  projectConfiguration,
  onConfigUpdate,
  displayMode
}) => {
  const { t } = useTranslation(["editProject"]);
  const {
    availableLanguages,
    adjustableStimuli,
    maxStimuliNumber,
    stimuliNameAlias
  } = useStaticDataState();
  const isEditDisabled = displayMode !== "edit";

  const defaultState: ProjectConfigPayload = {
    ageId: projectConfiguration.demographics.age.id,
    gender: projectConfiguration.demographics.gender,
    groupSizePerStimuli: projectConfiguration.groupSizePerStimuli,
    languageId: projectConfiguration.language.id,
    numberOfStimuli: projectConfiguration.numberOfStimuli,
    supportedDevices: projectConfiguration.supportedDevices
  };

  const [state, setState] = useState(defaultState);

  const isFirstRef = useRef(true);
  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }
    return onConfigUpdate(state);
  }, [state, setState]);

  const handleStimuliChange = (value: number) => {
    setState((prevState) => ({ ...prevState, numberOfStimuli: value }));
  };

  const handleLangChange = (event: SelectChangeEvent<string>) => {
    const val = event.target.value;
    setState((prevState) => ({
      ...prevState,
      languageId: val
    }));
  };

  const handleCellSizeChange = (cellSize: number) => {
    setState((prevState) => ({
      ...prevState,
      groupSizePerStimuli: cellSize
    }));
  };

  const handleDemographicChange = (demographics: DemographicsState) => {
    setState((prevState) => ({
      ...prevState,
      ageId: demographics.age.id,
      gender: demographics.gender
    }));
  };

  const handleSupportedDeviceChange = (devices: Device[]) => {
    setState((prevState) => ({ ...prevState, supportedDevices: devices }));
  };

  return (
    <>
      {adjustableStimuli && (
        <FormGroupSection>
          <FormGroupHeader>
            <IconWrapper>
              <FilterIcon />
            </IconWrapper>
            <h4>
              {t("editProject:groupSample.adsNumber", {
                stimuliAlias: stimuliNameAlias.toLowerCase()
              })}
            </h4>
          </FormGroupHeader>
          <StyledParagraph>
            {stimuliNameAlias === "Ad"
              ? t("editProject:groupSample.adsNumberDesc")
              : t("editProject:groupSample.testGroupNumberDesc")}
          </StyledParagraph>
          <FormGroupWrapper>
            <ChooseOptionField
              initialValue={state.numberOfStimuli}
              maxOptionsNumber={maxStimuliNumber}
              onValueSelected={handleStimuliChange}
              disabled={isEditDisabled}
            />
          </FormGroupWrapper>
        </FormGroupSection>
      )}
      <StyledDivider />
      <FormGroupSectionWithAnnotation>
        <FormGroupHeader>
          <IconWrapper>
            <UsersIcon />
          </IconWrapper>
          <h4>{t("editProject:groupSample.testGroupSize")}</h4>
        </FormGroupHeader>
        <StyledParagraph>
          {maxStimuliNumber === 1
            ? t("editProject:groupSample.peopleInTotal")
            : t("editProject:groupSample.testGroupSizeDesc", {
                stimuliAlias: stimuliNameAlias.toLowerCase()
              })}
        </StyledParagraph>
        <StyledFormControl>
          <CellSizeField
            initialCellSize={projectConfiguration.groupSizePerStimuli}
            stimuliNumber={state.numberOfStimuli}
            onChange={handleCellSizeChange}
            adjustableStimuli={adjustableStimuli}
            stimuliNameAlias={stimuliNameAlias}
            disabled={isEditDisabled}
          />
        </StyledFormControl>
        <Annotation>
          {t("editProject:groupSample.testGroupSizeAnnotation")}
        </Annotation>
      </FormGroupSectionWithAnnotation>
      <StyledDivider />
      <FormGroupSection>
        <FormGroupHeader>
          <IconWrapper>
            <CountryIcon />
          </IconWrapper>
          <h4>{t("editProject:groupSample.countryAndLanguage")}</h4>
        </FormGroupHeader>
        <StyledParagraph>
          {t("editProject:groupSample.countryAndLanguageDesc")}
        </StyledParagraph>
        <StyledFormControl>
          {availableLanguages.length > 0 ? (
            <FormControl variant="outlined">
              <InputLabel id="country-and-language-label">
                {t("editProject:groupSample.countryAndLang")}
              </InputLabel>
              <Select
                defaultValue=""
                labelId="country-and-language-label"
                id="country-and-language-field"
                value={state.languageId}
                onChange={handleLangChange}
                label={t("editProject:groupSample.countryAndLang")}
                variant="outlined"
                disabled={isEditDisabled}
              >
                {availableLanguages.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.locale} - {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined">
              <Select
                id="country-and-language-field"
                label={t("editProject:groupSample.countryAndLang")}
                value=""
                variant="outlined"
                disabled={isEditDisabled}
              />
            </FormControl>
          )}
        </StyledFormControl>
      </FormGroupSection>
      <StyledDivider />
      <FormGroupSection>
        <FormGroupHeader>
          <IconWrapper>
            <DeviceIcon />
          </IconWrapper>
          <h4>{t("editProject:groupSample.device")}</h4>
        </FormGroupHeader>
        <StyledParagraph>
          {t("editProject:groupSample.deviceDesc")}
        </StyledParagraph>
        <StyledFormControl>
          <SupportedDevicesField
            defaultSupportedDevices={projectConfiguration.supportedDevices}
            onChange={handleSupportedDeviceChange}
            disabled={isEditDisabled}
          />
        </StyledFormControl>
      </FormGroupSection>
      <StyledDivider />
      <FormGroupSection>
        <FormGroupHeader>
          <IconWrapper>
            <PieChartIcon />
          </IconWrapper>
          <h4>{t("editProject:groupSample.demographics")}</h4>
        </FormGroupHeader>
        <StyledParagraph>
          {t("editProject:groupSample.demographicsDesc")}
        </StyledParagraph>
        <StyledFormControl>
          <DemographicField
            defaultAge={projectConfiguration.demographics.age}
            defaultGender={projectConfiguration.demographics.gender}
            onChange={handleDemographicChange}
            disabled={isEditDisabled}
          />
        </StyledFormControl>
      </FormGroupSection>
    </>
  );
};

const FormGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  h4 {
    margin-top: 0;
    margin-bottom: 6px;
  }
`;

const FormGroupSection = styled.div`
  margin-top: 33px;
  margin-bottom: 31px;
`;

const FormGroupSectionWithAnnotation = styled(FormGroupSection)`
  margin-bottom: 0;
`;

const IconWrapper = styled.div`
  margin-right: 12px;
  margin-bottom: 9px;
  height: 24px;
`;

const StyledParagraph = styled.p`
  margin: 0 0 0 36px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const Annotation = styled(StyledParagraph)`
  margin: 5px 0 5px 36px;
`;

const StyledFormControl = styled(({ ...otherProps }) => (
  <FormControl {...otherProps} variant="standard" />
))`
  margin-left: 32px;
  margin-top: 24px;
`;

const FormGroupWrapper = styled.div`
  margin-left: 32px;
  margin-top: 24px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 32px;
`;
