const allowedMimeTypes: { [key: string]: string } = {
  "image/gif": "gif",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/tiff": "tif",
  "image/vnd.wap.wbmp": "wbmp",
  "image/x-icon": "ico",
  "image/x-jng": "jng",
  "image/x-ms-bmp": "bmp",
  "image/svg+xml": "svg",
  "image/webp": "webp",
  "video/3gpp": "3gpp",
  "video/mpeg": "mpeg",
  "video/quicktime": "mov",
  "video/x-flv": "flv",
  "video/x-mng": "mng",
  "video/x-ms-asf": "asx",
  "video/x-ms-wmv": "wmv",
  "video/x-msvideo": "avi",
  "video/mp4": "mp4",
  "application/pdf": "pdf",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/xhtml+xml": "xml",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx"
};

export function mimeTypeToExtension(mimeType: string): string {
  return allowedMimeTypes[mimeType] || "";
}
