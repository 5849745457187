import React, { useEffect, useRef, useState } from "react";
import { SampleForm } from "./SampleForm/SampleForm";
import styled from "styled-components";
import { useProjectState } from "contexts/ProjectStateContext";
import {
  ProjectConfigPayload,
  UpdateProjectConfigPayload
} from "services/projectService";
import { useEditProject } from "contexts/EditProjectContext";
import { NextButton } from "components/Shared";
import { useFeasibility } from "contexts/FeasibilityContext";
import { useDebounce } from "utils";
import { usePermissions } from "contexts/Auth";
import { DisplayMode } from "./EditProject";
import { useTranslation } from "react-i18next";

type GroupSampleProps = {
  displayMode: DisplayMode;
};

export const GroupSample: React.VFC<GroupSampleProps> = ({ displayMode }) => {
  const { t } = useTranslation(["editProject"]);
  const isFirstRef = useRef(true);
  const { project } = useProjectState();
  const [config, setConfig] = useState<ProjectConfigPayload | null>(null);
  const { canSeeCosts } = usePermissions("projectPermissions");
  const { showCostDialog, onContinue } = useFeasibility();
  const { updateConfig } = useEditProject();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (displayMode === "preview") {
      return onContinue();
    }

    updateProjectConfig();
    if (canSeeCosts) return showCostDialog();
    return onContinue();
  };

  const updateProjectConfig = () => {
    if (!config) {
      return;
    }
    const updateProjectPayload: UpdateProjectConfigPayload = {
      projectId: project.id,
      config: config
    };
    updateConfig(updateProjectPayload);
  };

  const debouncedConfig = useDebounce(config, 500);
  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }
    updateProjectConfig();
  }, [debouncedConfig]);

  const handleConfigUpdate = (payload: ProjectConfigPayload) => {
    setConfig(payload);
  };

  return (
    <Card onSubmit={handleSubmit}>
      <Heading>{t("editProject:groupSample.header")}</Heading>
      <FormWrapper>
        <SampleForm
          onConfigUpdate={(config) => handleConfigUpdate(config)}
          projectConfiguration={project.configuration}
          displayMode={displayMode}
        />
      </FormWrapper>
      <ButtonWrapper>
        <NextButton type="submit" />
      </ButtonWrapper>
    </Card>
  );
};

const Card = styled.form`
  width: 100%;
`;

const Heading = styled.h2`
  margin: 30px 0 20px 50px;
`;

const FormWrapper = styled.div`
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px;
`;
