import { FC, useEffect, useRef, useState } from "react";
import { Tooltip, Typography, TypographyProps } from "@mui/material";

export const TruncatedTypography: FC<Omit<TypographyProps, "noWrap">> = ({
  children,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);
  const textElementRef = useRef<HTMLSpanElement>(null);

  const compareSize = () => {
    const scrollWidth = textElementRef.current?.scrollWidth;
    const clientWidth = textElementRef.current?.clientWidth;
    const isOverflowing =
      scrollWidth != null && clientWidth != null && scrollWidth > clientWidth;
    setIsHover(isOverflowing);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => window.removeEventListener("resize", compareSize);
  }, []);

  return (
    <Tooltip title={<>{children}</>} disableHoverListener={!isHover}>
      <Typography ref={textElementRef} noWrap {...props}>
        {children}
      </Typography>
    </Tooltip>
  );
};
