import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import {
  copyProject,
  createProject,
  editProject
} from "services/projectService";
import { Paths } from "routing";
import { useNotificationContext } from "contexts/NotificationContext";
import { Template } from "dto";
import { useMe } from "contexts/Auth";
import { queryKeys } from "api/queryClient";

type EditProjectDetailsProps = {
  id: string;
  name: string;
  ref: string;
};

export interface CreateProjectDialogProps {
  open: boolean;
  template?: Template;
  edit?: EditProjectDetailsProps;
  copy?: boolean;
  onClose: () => void;
}

const getNamePrefix = () => dayjs().format("YYYY_MM_");

export const CreateProjectDialog: React.FC<CreateProjectDialogProps> = ({
  open,
  template,
  edit,
  copy,
  onClose
}) => {
  const defaultProjectName = edit?.name || getNamePrefix();
  const defaultRefNumber = edit?.ref || "";
  const { t } = useTranslation(["createSurvey", "common"]);
  const { managedGroups, group, role } = useMe();
  const [projectName, setProjectName] = useState(defaultProjectName);
  const [projectGroup, setProjectGroup] = useState("");
  const [refNumber, setRefNumber] = useState(defaultRefNumber);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { displayErrorSnackbar } = useNotificationContext();

  useEffect(() => {
    if (managedGroups && managedGroups.length > 0) {
      setProjectGroup(managedGroups[0].id);
    } else if (group.id) {
      setProjectGroup(group.id);
    }
  }, [managedGroups]);

  const { mutate, isLoading: isCreateLoading } = useMutation(createProject, {
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries(queryKeys.projects.all);
      const { base, edit } = Paths.surveys;
      const path = `/${base}/${edit.base}/${id}/${edit.groupSample}`;
      navigate(path);
    },
    onError: () => {
      displayErrorSnackbar();
    }
  });

  const { mutate: copyMutate, isLoading: isCopyLoading } = useMutation(
    copyProject,
    {
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries(queryKeys.projects.all);
        const { base, edit } = Paths.surveys;
        const path = `/${base}/${edit.base}/${id}/${edit.groupSample}`;
        navigate(path);
      },
      onError: () => {
        displayErrorSnackbar();
      }
    }
  );

  const { mutate: editMutate, isLoading: isEditLoading } = useMutation(
    editProject,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.projects.all);
        onClose();
      },
      onError: () => {
        displayErrorSnackbar();
      }
    }
  );

  const onsubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (copy && edit) {
      return copyMutate({
        projectId: edit.id,
        name: projectName,
        referenceNumber: refNumber
      });
    }
    if (edit) {
      return editMutate({
        project: {
          id: edit.id,
          name: projectName,
          referenceNumber: refNumber
        }
      });
    }
    if (template?.id) {
      return mutate({
        project: {
          name: projectName,
          referenceNumber: refNumber,
          templateId: template.id
        },
        groupId: projectGroup
      });
    }
  };

  const handleOnClose = () => {
    setProjectGroup("");
    setRefNumber(defaultRefNumber);
    setProjectName(defaultProjectName);
    onClose();
  };

  const title = copy
    ? t("createSurvey:copyProject")
    : edit
    ? t("createSurvey:editProjectDetails")
    : t("createSurvey:createNewProject");

  return (
    <Dialog onClose={handleOnClose} open={open}>
      <Header>
        <CloseButton aria-label="close" onClick={handleOnClose}>
          <CloseIcon />
        </CloseButton>
        <Heading>{title}</Heading>
      </Header>
      <Box
        component="form"
        p={10}
        pt={edit ? 10 : 0}
        sx={{ width: 500 }}
        onSubmit={onsubmit}
        noValidate
        autoComplete="off"
      >
        {!edit && (
          <SubHeading>
            {role === "EXTERNAL_POWER_USER"
              ? t("createSurvey:createProjectDialogExternalPowerSubheading", {
                  templateName: template?.shortCode
                })
              : t("createSurvey:createProjectDialogSubheading", {
                  templateName: template?.shortCode
                })}
          </SubHeading>
        )}
        {managedGroups && !edit && (
          <Box mb={10} py={1}>
            <InputLabel shrink htmlFor="group-field">
              {t("createSurvey:groupNameFieldLabel")}
            </InputLabel>
            <Select
              margin="dense"
              fullWidth
              name="group"
              id="group-field"
              value={projectGroup}
              onChange={(e) => setProjectGroup(e.target.value)}
              variant="outlined"
            >
              {projectGroup && (
                <MenuItem value={projectGroup}>{group.name}</MenuItem>
              )}
              {managedGroups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <TextField
          id="project-name-field"
          label={t("createSurvey:projectNameFieldLabel")}
          variant="outlined"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          autoFocus
          fullWidth
        />
        <HintTitle>{t("createSurvey:createProjectDialogHintTitle")}</HintTitle>
        <HintList>
          <li>{t("createSurvey:createProjectDialogHintItem1")}</li>
          <li>{t("createSurvey:createProjectDialogHintItem2")}</li>
        </HintList>
        <TextField
          id="ref-number-field"
          label={t("createSurvey:refNumberFieldLabel")}
          variant="outlined"
          value={refNumber}
          onChange={(e) => setRefNumber(e.target.value)}
          fullWidth
        />
        <HintTitle>{t("createSurvey:refNumberTip")}</HintTitle>
        <Buttons>
          <CancelButton variant="outlined" onClick={handleOnClose}>
            {t("common:cancel")}
          </CancelButton>
          <ContinueButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={isCreateLoading || isEditLoading || isCopyLoading}
          >
            {isCreateLoading || isEditLoading || isCopyLoading ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              t("common:continue")
            )}
          </ContinueButton>
        </Buttons>
      </Box>
    </Dialog>
  );
};

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
`;

const ContinueButton = styled(Button)`
  width: 138px;
  height: 48px;

  &:disabled {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const CancelButton = styled(ContinueButton)`
  width: 125px;
`;

const Header = styled.div`
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CloseButton = styled(IconButton)`
  padding: 5px;
`;

const Heading = styled.h2`
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  margin: 16px 0 12px;
  width: 100%;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
`;

const SubHeading = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
  margin: 0 0 32px;
`;

const HintTitle = styled.p`
  margin: 16px 0 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
`;

const HintList = styled.ul`
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
  margin-bottom: 28px;
  padding-left: 24px;
`;
