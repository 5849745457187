import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Paths } from "routing";

const loginPath = `/${Paths.auth.base}/${Paths.auth.signIn}`;

export const Footer = () => {
  const { t } = useTranslation(["common"]);

  return (
    <Container>
      <StyledLink to={loginPath}>{t("common:support")}</StyledLink>
    </Container>
  );
};

const Container = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.neutral.oxford_blue};
`;

export default Footer;
