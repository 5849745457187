import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

interface ViewLoaderProps {
  fullPage?: boolean;
  size?: number;
}

const FULL_PAGE_SIZE = 150;
const DEFAULT_SIZE = 70;

export const ViewLoader: React.FC<ViewLoaderProps> = ({
  fullPage = false,
  size = fullPage ? FULL_PAGE_SIZE : DEFAULT_SIZE
}) => (
  <Container fullPage={fullPage} data-testid="view-loader">
    <CircularProgress size={size} />
  </Container>
);

const Container = styled.div<ViewLoaderProps>`
  width: ${({ fullPage }) => (fullPage ? "100vw" : "100%")};
  height: ${({ fullPage }) => (fullPage ? "100vh" : "100%")};

  display: flex;
  align-items: center;
  justify-content: center;
`;
