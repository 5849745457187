import { DefaultTheme } from "styled-components";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import DinNext from "../fonts/DINNextLTPro-Regular.woff2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface CustomColors {
  grey_warm_100: string;
  grey_warm_50: string;
  grey_warm_20: string;
  grey_dark: string;
  navy_blue: string;
  navy_blue_10: string;
  royal_blue: string;
  oxford_blue: string;
  w_black: string;
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    neutral: PaletteOptions;
  }

  interface PaletteOptions {
    neutral: CustomColors;
  }
}

declare module "styled-components" {
  type Theme = ReturnType<typeof createTheme> & {
    palette: { neutral: CustomColors };
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

const DIN_Next_Lt_Pro = {
  fontFamily: "DIN Next Lt Pro",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('DIN Next Lt Pro'),
    local('DIN Next Lt Pro'),
    url(${DinNext}) format('woff2')
  `
};

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: "#007FFF", // azure
      light: "#E6F2FF" // azure_10
    },
    text: {
      primary: "#131631", //oxford_blue
      secondary: "#787A8F" // grey_dark
    },
    secondary: {
      main: "#131631" //oxford_blue
    },
    error: {
      main: "#E91B29", // red
      light: "#FDE8EA" // red_10
    },
    warning: {
      main: "#F8C821" // sunglow
    },
    success: {
      main: "#14A344", // green_pigment
      light: "#E8F6EC" // green_pigment_10
    },
    background: {
      default: "#ffffff" // white
    },
    neutral: {
      grey_warm_100: "#A4A8CD",
      grey_warm_50: "#DFE1F2",
      grey_warm_20: "#F1F1F9",
      grey_dark: "#787A8F",
      navy_blue_10: "#E6E6F0",
      navy_blue: "#000066",
      royal_blue: "#141450",
      oxford_blue: "#131631",
      w_black: "#000000"
    }
  },
  spacing: 4,
  typography: {
    fontFamily: "DIN Next LT Pro",
    fontWeightRegular: 400,
    fontSize: 15,
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 28
        },
        h2: {
          fontSize: 24
        },
        h3: {
          fontSize: 20
        },
        h4: {
          fontSize: 18
        },
        subtitle1: {
          fontSize: 15,
          fontWeight: "bold"
        },
        subtitle2: {
          fontSize: 13,
          fontWeight: "bold"
        },
        body1: {
          fontSize: 15
        },
        body2: {
          fontSize: 13
        },
        caption: {
          fontSize: 12
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(19, 22, 49, 0.6)" // Oxford_blue
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: { maxWidth: 500 },
        paperWidthMd: { maxWidth: 600 },
        paperWidthLg: { maxWidth: 800 }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlinedSecondary: {
          border: "1px solid #A4A8CD" // grey_warm_100
        },
        root: {
          fontSize: 15,
          textTransform: "none",
          minWidth: 0
        },
        sizeLarge: {
          padding: "14px 40px"
        },
        sizeMedium: {
          padding: "10px 20px"
        },
        sizeSmall: {
          padding: "6px 10px",
          fontSize: 13
        }
      }
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <ChevronRightIcon />
      },
      styleOverrides: {
        li: {
          overflow: "hidden",
          "& *": {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: { size: "small" }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginRight: 0,
          marginLeft: 0,
          width: 280
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 15
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 15
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiInputBase-multiline": {
            alignItems: "flex-start"
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          ":first-child": {
            paddingLeft: 0
          },
          ":last-child": {
            paddingRight: 0
          }
        },
        body: { borderBottom: "1px solid #DFE1F2" } //grey_warm_50
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        outlined: {
          borderColor: "#A4A8CD"
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": DIN_Next_Lt_Pro,
        body: {
          backgroundColor: "#fffff",
          color: "#131631",
          fontSize: 15
        },
        h1: {
          fontSize: 28
        },
        h2: {
          fontSize: 24
        },
        h3: {
          fontSize: 20
        },
        h4: {
          fontSize: 18
        }
      }
    }
  }
};

export default createTheme(theme) as DefaultTheme;
