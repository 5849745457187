import React from "react";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps
} from "@mui/material";

export interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  circularProgressProps?: CircularProgressProps;
}

export const LoadingButton: React.VFC<LoadingButtonProps> = ({
  isLoading,
  circularProgressProps = {},
  sx,
  disabled,
  ...buttonProps
}) => {
  return (
    <Box position="relative" sx={sx}>
      <Button {...buttonProps} disabled={disabled || isLoading} />

      {isLoading && (
        <Box
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress
            color={buttonProps.color}
            size={20}
            {...circularProgressProps}
          />
        </Box>
      )}
    </Box>
  );
};
