import React, { forwardRef } from "react";
import styled from "styled-components";
import { SnackbarContent, useSnackbar } from "notistack";

export interface NotificationSnackbarProps {
  id: string | number;
  message: string | React.ReactNode;
}

export const NotificationSnackbar = forwardRef<
  HTMLDivElement,
  NotificationSnackbarProps
>(({ message }, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Host onClick={() => closeSnackbar()}>{message}</Host>
    </SnackbarContent>
  );
});

const Host = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 15px;
  line-height: 24px;
  width: min(90vw, 460px);
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
  gap: 20px;
`;
