import { PlaceholderDetails, PlaceholderGroup } from "dto/Template";
import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Placeholder } from "dto/Project";
import { GenericField } from "./GenericField";
import { DefaultStatementsDialog } from "./DefaultStatementsDialog";
import { DisplayMode } from "../EditProject";

export interface PlaceholderGroupFormProps {
  placeholderGroup: PlaceholderGroup;
  placeholders: Record<string, Placeholder>;
  placeholderConfigs: Record<string, PlaceholderDetails>;
  updatePlaceholder: (
    key: string,
    text: string,
    allowedValueId?: string
  ) => void;
  groupVisited: (groupId: string) => void;
  displayMode: DisplayMode;
}

export const PlaceholderGroupForm: React.VFC<PlaceholderGroupFormProps> = ({
  placeholderGroup,
  placeholders,
  placeholderConfigs,
  updatePlaceholder,
  groupVisited,
  displayMode
}) => {
  const { sections } = placeholderGroup;
  const isEditDisabled = displayMode !== "edit";

  useEffect(() => {
    setTimeout(() => groupVisited(placeholderGroup.id), 500);
  }, [placeholderGroup.id]);

  return (
    <>
      {sections.map(
        (
          { name, description, infobox, placeholders: staticPlaceholders },
          i
        ) => (
          <Fragment key={i}>
            {name && <Name>{name}</Name>}
            {description && (
              <Description>
                {description}
                <span> </span>
                {infobox && (
                  <DefaultStatementsDialog infoBoxDetails={infobox} />
                )}
              </Description>
            )}

            {staticPlaceholders.map((placeholder, j) => (
              <FieldWrapper key={placeholder.key}>
                <GenericField
                  placeholderConfig={placeholderConfigs[placeholder.key]}
                  placeholder={placeholders[placeholder.key]}
                  autoFocus={i === 0 && j === 0}
                  updatePlaceholderValue={updatePlaceholder}
                  disabled={isEditDisabled}
                ></GenericField>
              </FieldWrapper>
            ))}
          </Fragment>
        )
      )}
    </>
  );
};

const Name = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
  margin: 0 0 24px;
`;

const FieldWrapper = styled.div`
  margin-bottom: 40px;
`;
