import { httpClient } from "../api/httpClient";
import { urls } from "../api/urls";
import {
  PagedProjects,
  DetailedProject,
  ProjectFeasibility,
  Stimulus,
  ListProject,
  Placeholder,
  Status
} from "../dto/Project";
import {
  CreateProjectPayload,
  CreateProjectResponse,
  EditProjectPayload
} from "dto/CreateProject";
import { Device, Gender } from "../dto/ProjectConfiguration";

export const createProject = async ({
  project,
  groupId
}: {
  project: CreateProjectPayload;
  groupId: string;
}): Promise<CreateProjectResponse> => {
  const response = await httpClient.post<CreateProjectResponse>(
    `${urls.groups}/${groupId}/projects`,
    project
  );
  return response.data;
};

export const editProject = async ({
  project
}: {
  project: EditProjectPayload;
}): Promise<CreateProjectResponse> => {
  const response = await httpClient.put<CreateProjectResponse>(
    `${urls.survey.projects}/${project.id}`,
    {
      name: project.name,
      referenceNumber: project.referenceNumber
    }
  );
  return response.data;
};

export const launchProject = async (projectId: string): Promise<number> => {
  const response = await httpClient.post(
    `${urls.survey.projects}/${projectId}/launch`
  );
  return response.status;
};

export const requestQuote = async (projectId: string): Promise<number> => {
  const response = await httpClient.post(`api/${projectId}/quote-request`);
  return response.status;
};

export interface PreviewLinks {
  previewUrl: string;
  questback: string;
}

export const recalculateProgress = async (
  projectId: string
): Promise<ListProject> => {
  const response = await httpClient.put<ListProject>(
    `${urls.survey.projects}/${projectId}/progress`
  );
  return response.data;
};

export const getPreviewLinks = async (
  projectId: string
): Promise<PreviewLinks> => {
  const response = await httpClient.put<PreviewLinks>(
    `${urls.survey.projects}/${projectId}/preview`
  );
  return response.data;
};

export const getAllProjects = async ({
  page,
  size,
  sort = "creationDate,desc",
  status,
  search,
  group,
  template
}: {
  page: number;
  size: number;
  sort?: string;
  status?: Status;
  search?: string;
  group?: string;
  template?: string;
}) => {
  const response = await httpClient.get<PagedProjects>(urls.survey.projects, {
    params: { page, size, sort, status, search, group, template }
  });
  return response.data;
};

export const getProjectById = async (id: string) => {
  const response = await httpClient.get<DetailedProject>(
    `${urls.survey.projects}/${id}`
  );
  return response.data;
};

export const getProjectFeasibility = async (id: string) => {
  const response = await httpClient.get<ProjectFeasibility>(
    `${urls.survey.projects}/${id}/feasibility`
  );
  return response.data;
};

export interface UpdateProjectConfigPayload {
  projectId: string;
  config: ProjectConfigPayload;
}

export interface ProjectConfigPayload {
  ageId: string;
  gender: Gender;
  groupSizePerStimuli: number;
  languageId: string;
  numberOfStimuli: number;
  supportedDevices: Device[];
}

export const updateProjectConfiguration = async (
  payload: UpdateProjectConfigPayload
): Promise<number> => {
  const response = await httpClient.put<number>(
    `${urls.survey.projects}/${payload.projectId}/configs`,
    payload.config
  );
  return response.status;
};

export const updateStimulus = async ({
  projectId,
  stimulus
}: {
  projectId: string;
  stimulus: Stimulus;
}) => {
  const response = await httpClient.put<void>(
    `${urls.survey.projects}/${projectId}/stimuli/${stimulus.id}`,
    {
      name: stimulus.name,
      incontextId: stimulus.incontextId,
      mediaList: stimulus.mediaList.map((media) => ({
        id: media.id,
        link: media.link,
        type: media.type
      }))
    }
  );
  return response.data;
};

export const updateManyStimuli = async ({
  projectId,
  stimuli
}: {
  projectId: string;
  stimuli: Stimulus[];
}) => {
  return Promise.all(
    stimuli.map((stimulus) => updateStimulus({ projectId, stimulus }))
  );
};

export const uploadFile = async ({
  projectId,
  stimulusId,
  mediaId,
  file,
  onProgressChange
}: {
  projectId: string;
  stimulusId: string;
  mediaId: string;
  file: File;
  onProgressChange: (progress: number) => void;
}) => {
  const data = new FormData();
  data.append("file", file);
  const response = await httpClient.post(
    `${urls.survey.projects}/${projectId}/stimuli/${stimulusId}/media/${mediaId}/files`,
    data,
    {
      onUploadProgress: (e: { loaded: number; total: number }) => {
        const min = 0;
        const max = 99;
        const progress = Math.round((e.loaded * 100) / e.total);
        onProgressChange(Math.min(Math.max(progress, min), max));
      }
    }
  );
  return response.data;
};

export const deleteFile = async ({
  projectId,
  stimulusId,
  mediaId
}: {
  projectId: string;
  stimulusId: string;
  mediaId: string;
}) => {
  const { data } = await httpClient.delete(
    `${urls.survey.projects}/${projectId}/stimuli/${stimulusId}/media/${mediaId}/files`
  );
  return data;
};

export interface ProjectPropertiesPayload {
  name: string;
  referenceNumber: string;
}

interface CopyProjectPayload extends ProjectPropertiesPayload {
  projectId: string;
}

export const copyProject = async ({
  projectId,
  ...projectProperties
}: CopyProjectPayload) => {
  const response = await httpClient.post(
    `${urls.survey.projects}/${projectId}/copy`,
    projectProperties
  );
  return response.data;
};

export const updateProject = async ({
  projectId,
  projectProperties
}: {
  projectId: string;
  projectProperties: ProjectPropertiesPayload;
}) => {
  const response = await httpClient.put(
    `${urls.survey.projects}/${projectId}`,
    projectProperties
  );
  return response.status;
};

export const updatePlaceholder = async ({
  projectId,
  placeholder
}: {
  projectId: string;
  placeholder: Placeholder;
}) => {
  const response = await httpClient.put<void>(
    `${urls.survey.projects}/${projectId}/placeholders/${placeholder.id}`,
    { text: placeholder.text || "", allowedValueId: placeholder.allowedValueId }
  );
  return response.data;
};

export const updateManyPlaceholders = async ({
  projectId,
  placeholders
}: {
  projectId: string;
  placeholders: Placeholder[];
}) => {
  return Promise.all(
    placeholders.map((placeholder) =>
      updatePlaceholder({ projectId, placeholder })
    )
  );
};
