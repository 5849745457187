import React from "react";
import styled from "styled-components";
import womenImg from "assets/images/women-in-blue.png";
import { Link, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth";
import { LoadingButton, PasswordInput } from "components/Shared";
import { NonAuthorizedLayout } from "components/Shared/Layout";
import { Paths } from "routing";

interface LoginState {
  email: string;
  password: string;
}

const resetPasswordPath = `/${Paths.auth.base}/${Paths.auth.resetPassword.base}`;

export const Login: React.FC = () => {
  const { t } = useTranslation(["auth"]);
  const { login } = useAuth();

  const [state, setState] = React.useState<LoginState>({
    email: "",
    password: ""
  });

  const handleChange =
    (prop: keyof LoginState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  const isDisabled = (error: any) => {
    if (error?.message) {
      return error.message.includes("disabled");
    }

    return false;
  };

  return (
    <NonAuthorizedLayout bgColor="#fff">
      <Wrapper>
        <LoginWrapper>
          <TransparentContainer>
            <TextContainer>
              <Header>{t("auth:platform")}</Header>
              <Description>{t("auth:platformDesc")}</Description>
            </TextContainer>
            <LoginImage src={womenImg} alt="Login" />
          </TransparentContainer>
        </LoginWrapper>
        <FormContainer>
          <FormGroup
            onSubmit={(e) => {
              e.preventDefault();
              const { email, password } = state;
              if (email && password) {
                login.mutate({ email, password });
              }
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h2">
              {t("auth:titleprefix")}
              <Typography component="span" variant="inherit" color="primary">
                {" "}
                {t("auth:platform")}{" "}
              </Typography>
              {t("auth:titlesufix")}
            </Typography>
            <TextField
              sx={{ mt: 5 }}
              id="login-email-field"
              label={t("auth:email")}
              variant="outlined"
              value={state.email}
              onChange={handleChange("email")}
            />
            <PasswordInput
              sx={{ mt: 10, mb: 4 }}
              id="login-password-field"
              value={state.password}
              label={t("auth:password")}
              onChange={handleChange("password")}
            />
            <Link
              component={RouterLink}
              color="textPrimary"
              to={resetPasswordPath}
              underline="hover"
            >
              {t("auth:forgotPassword")}
            </Link>
            {isDisabled(login.error) ? (
              <StyledAlert severity="error">
                {t("auth:accountDisabled")}
              </StyledAlert>
            ) : (
              <StyledAlert severity="error">
                {t("auth:wrongCredentials")}
              </StyledAlert>
            )}
            <LoadingButton
              sx={{ mt: 8 }}
              isLoading={login.isLoading}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
            >
              {t("auth:logIn")}
            </LoadingButton>
          </FormGroup>
        </FormContainer>
      </Wrapper>
    </NonAuthorizedLayout>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAlert = styled(Alert)`
  && {
    margin-top: 15px;
    width: 380px;

    &.MuiAlert-root {
      align-items: center;
    }
  }
`;

const Header = styled.span`
  font-size: 48px;
  font-weight: 700;
  float: right;
`;

const Description = styled.p`
  font-size: 18px;
  max-width: 190px;
  margin-top: 8px;
`;

const TextContainer = styled.div`
  padding-right: 50px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: ${(props) => props.theme.palette.neutral.navy_blue};
`;

const LoginWrapper = styled.div`
  margin: 0 52px 0 0;
  height: 500px;
  position: relative;
`;

const TransparentContainer = styled.div`
  margin-left: 213px;
  background-color: rgba(202, 204, 253, 0.2);
  width: 290px;
  height: 290px;
`;

const LoginImage = styled.img`
  width: 400px;
  height: 400px;
  z-index: 9;
  position: absolute;
  top: 100px;
  right: 103px;
`;

const FormContainer = styled.div`
  width: 380px;
  margin: 0 40px 0 52px;
`;

const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
`;
