import React, { useMemo } from "react";
import Avatar from "@mui/material/Avatar";

export interface UserAvatarProps {
  name: string;
  size?: number;
}

export const UserAvatar: React.VFC<UserAvatarProps> = ({
  name = "",
  size = 30
}) => {
  const backgroundColor = useMemo(() => stringToColor(name), [name]);
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        pt: 0.5,
        fontSize: 16,
        backgroundColor
      }}
    >
      {name?.charAt(0)}
    </Avatar>
  );
};

// from https://mui.com/components/avatars/#letter-avatars
function stringToColor(string: string) {
  if (!string) {
    return "#000000";
  }
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
