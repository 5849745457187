import { queryKeys } from "api/queryClient";
import { useNotificationContext } from "contexts/NotificationContext";
import { useMutation, useQueryClient } from "react-query";
import { getPreviewLinks, PreviewLinks } from "services/projectService";

export const usePreview = (projectId: string) => {
  const queryClient = useQueryClient();

  const { displayErrorSnackbar } = useNotificationContext();
  const {
    mutate: getPreview,
    isLoading: isPreviewLoading,
    reset
  } = useMutation(getPreviewLinks, { onError: () => displayErrorSnackbar() });

  const handlePreviewClick =
    (getLink: (data: PreviewLinks) => string) => () => {
      getPreview(projectId, {
        onSuccess: async (data) => {
          if (data) {
            await queryClient.invalidateQueries(queryKeys.projects.all);
            // Data could be undefined if popper is closed and mutation reset
            window.open(getLink(data), "_blank");
          }
        }
      });
    };

  return { handlePreviewClick, isPreviewLoading, reset };
};
