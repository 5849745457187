import React, { useContext, useEffect, useState } from "react";
import { CostDialog } from "components/Projects/Survey/EditProject/CostDialog";
import { useQuery, useQueryClient } from "react-query";
import { getProjectFeasibility } from "services/projectService";
import { useProjectState } from "./ProjectStateContext";
import { FeasibilityStatus, ProjectFeasibility } from "dto/Project";
import { getCurrencySymbol, delay } from "utils";
import { useTranslation } from "react-i18next";
import { Paths } from "routing";
import { useNavigate, useLocation } from "react-router";
import { EditStatus, useEditProject } from "./EditProjectContext";
import { useNotificationContext } from "./NotificationContext";
import { queryKeys } from "api/queryClient";
import { useMe } from "./Auth";
import { useStaticDataState } from "./StaticDataContext";
import { DisplayMode } from "components/Projects/Survey/EditProject/EditProject";

const defaultHandler = () => {
  throw new Error("Cannot find FeasibilityContext Provider");
};

interface SanitizedFeasibilityData {
  currency: string;
  stimuli: string;
  cellSize: string;
  requestedSampleSize: string;
  availableSampleSize: string;
  costPerInterview: string;
  totalCost: string;
}

interface FeasibilityContextType {
  hideCostDialog: () => void;
  showCostDialog: () => void;
  recalculate: () => void;
  onContinue: () => void;
  isLoading: boolean;
  isError: boolean;
  feasibility: SanitizedFeasibilityData;
}

const defaultContextValue: FeasibilityContextType = {
  hideCostDialog: defaultHandler,
  showCostDialog: defaultHandler,
  recalculate: defaultHandler,
  onContinue: defaultHandler,
  isLoading: false,
  isError: false,
  feasibility: {
    currency: "",
    stimuli: "",
    cellSize: "",
    requestedSampleSize: "-",
    availableSampleSize: "-",
    costPerInterview: "-",
    totalCost: "-"
  }
};

const FeasibilityContext = React.createContext(defaultContextValue);

type FeasibilityContextProps = {
  children: React.ReactNode;
  displayMode: DisplayMode;
};

export const FeasibilityProvider: React.FC<FeasibilityContextProps> = ({
  children,
  displayMode
}) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { displayErrorSnackbar } = useNotificationContext();
  const { role } = useMe();

  const isQueryEnabled = role === "ADMIN" || role === "PROJECT_MANAGER";
  const { pathname } = useLocation();
  const { groupSample } = Paths.surveys.edit;
  const isGroupSample = pathname.includes(groupSample);

  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const recalculate = () => {
    queryClient.invalidateQueries(
      queryKeys.projects.feasibilityById(project.id)
    );
  };

  const hideCostDialog = () => setOpen(false);
  const showCostDialog = () => {
    recalculate();
    setOpen(true);
  };

  const { project } = useProjectState();
  const { numberOfStimuli, groupSizePerStimuli } = project.configuration;
  const { adjustableStimuli } = useStaticDataState();

  const {
    data,
    isFetching,
    isError: isRequestError
  } = useQuery(
    queryKeys.projects.feasibilityById(project.id),
    () => getProjectFeasibility(project.id),
    {
      onSuccess: async ({ status }) => {
        await refetchWhenProcessing(status);
      },
      onError: () => displayErrorSnackbar(),
      enabled: isQueryEnabled
    }
  );

  const refetchWhenProcessing = async (status: FeasibilityStatus) => {
    if (status === FeasibilityStatus.PROCESSING) {
      await delay(800);
      recalculate();
    }
  };

  const { status: projectEditStatus } = useEditProject();

  useEffect(() => {
    if (projectEditStatus === EditStatus.SAVED && isGroupSample) {
      recalculate();
    }
  }, [project, projectEditStatus, isGroupSample]);
  const onContinue = () => {
    const { base, edit, preview } = Paths.surveys;
    const mode = displayMode === "preview" ? preview : edit;

    const pathToNavigate = adjustableStimuli
      ? `/${base}/${mode.base}/${project.id}/${mode.stimuli}`
      : `/${base}/${mode.base}/${project.id}/${mode.editSurvey}`;
    navigate(pathToNavigate);
    hideCostDialog();
  };

  const calculationsFailed = data?.status === FeasibilityStatus.FAILED;

  const isLoading =
    !data ||
    data.status === FeasibilityStatus.PROCESSING ||
    isFetching ||
    (projectEditStatus === EditStatus.SAVING && isGroupSample);

  const feasibility = mapDataToFeasibility(
    isLoading,
    data,
    i18n.language,
    numberOfStimuli,
    groupSizePerStimuli
  );

  return (
    <FeasibilityContext.Provider
      value={{
        hideCostDialog,
        showCostDialog,
        recalculate,
        onContinue,
        isLoading: !isRequestError && isLoading,
        isError: isRequestError || calculationsFailed,
        feasibility
      }}
    >
      {children}
      <CostDialog
        open={open}
        onClose={hideCostDialog}
        onContinue={onContinue}
      />
    </FeasibilityContext.Provider>
  );
};

export const useFeasibility = () => useContext(FeasibilityContext);

function mapDataToFeasibility(
  isLoading: boolean,
  data: ProjectFeasibility | undefined,
  language: string,
  numberOfStimuli: number,
  groupSizePerStimuli: number
): SanitizedFeasibilityData {
  if (!isLoading && data?.status === FeasibilityStatus.READY) {
    const availableSampleSize = data.totalCount;
    const requestedSampleSize = data.requestedTotalCount;
    const costPerInterview = data.costPerInterview;
    const totalCost = requestedSampleSize * costPerInterview;

    return {
      currency: getCurrencySymbol(language, data.currency),
      stimuli: numberOfStimuli.toFixed(0),
      cellSize: groupSizePerStimuli.toFixed(0),
      requestedSampleSize: requestedSampleSize.toFixed(0),
      availableSampleSize: availableSampleSize.toFixed(0),
      costPerInterview: costPerInterview.toFixed(2),
      totalCost: totalCost.toFixed(2)
    };
  }

  return {
    currency: "",
    stimuli: numberOfStimuli.toFixed(0),
    cellSize: groupSizePerStimuli.toFixed(0),
    requestedSampleSize: "-",
    availableSampleSize: "-",
    costPerInterview: "-",
    totalCost: "-"
  };
}
