import React, { useEffect, useReducer, useRef } from "react";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export interface CellSizeFieldProps {
  initialCellSize: number;
  stimuliNumber: number;
  onChange: (cellSize: number) => void;
  adjustableStimuli: boolean;
  stimuliNameAlias: string;
  disabled?: boolean;
}

const maxTotalSize = 1_000_000;
const minCellSize = 1;

interface Action {
  type:
    | "CHANGE_CELL_SIZE"
    | "BLUR_CELL_SIZE"
    | "CHANGE_TOTAL_SIZE"
    | "BLUR_TOTAL_SIZE";
  value: string;
}

interface State {
  cellSize: string;
  totalSize: string;
}

export const CellSizeField: React.FC<CellSizeFieldProps> = ({
  initialCellSize,
  stimuliNumber,
  onChange,
  adjustableStimuli,
  stimuliNameAlias,
  disabled
}) => {
  const isFirstRef = useRef(true);
  const { t } = useTranslation(["editProject"]);
  const initialTotalSize = initialCellSize * stimuliNumber;
  const maxCellSize = Math.floor(maxTotalSize / stimuliNumber);
  const minTotalSize = minCellSize * stimuliNumber;

  const calcFromTotalSize = (tempTotalSize: number) => {
    const clampedValue = clamp(tempTotalSize, minTotalSize, maxTotalSize);
    const newCellSize = Math.floor(clampedValue / stimuliNumber);
    const newTotalSize = newCellSize * stimuliNumber;
    return { newCellSize, newTotalSize };
  };

  const calcFromCellSize = (tempCellSize: number) => {
    const clampedValue = clamp(tempCellSize, minCellSize, maxCellSize);
    const newTotalSize = clampedValue * stimuliNumber;
    const newCellSize = clampedValue;
    return { newCellSize, newTotalSize };
  };

  const initialState = {
    cellSize: `${initialCellSize}`,
    totalSize: `${initialTotalSize}`
  };

  const reducer = (state: State, action: Action): State => {
    const value = sanitizeToPositiveInteger(action.value);
    const { newTotalSize, newCellSize } = action.type.includes("CELL_SIZE")
      ? calcFromCellSize(+value)
      : calcFromTotalSize(+value);

    if (action.type.includes("BLUR") && initialCellSize !== newCellSize) {
      onChange(newCellSize);
    }
    switch (action.type) {
      case "CHANGE_CELL_SIZE":
        return { cellSize: value, totalSize: `${newTotalSize}` };
      case "BLUR_CELL_SIZE":
        return { cellSize: `${newCellSize}`, totalSize: `${newTotalSize}` };
      case "CHANGE_TOTAL_SIZE":
        return { cellSize: `${newCellSize}`, totalSize: value };
      case "BLUR_TOTAL_SIZE":
        return { cellSize: `${newCellSize}`, totalSize: `${newTotalSize}` };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
      return;
    }

    const tempTotalSize = clamp(
      stimuliNumber * +state.cellSize,
      minTotalSize,
      maxTotalSize
    );
    dispatch({ type: "BLUR_TOTAL_SIZE", value: `${tempTotalSize}` });
  }, [stimuliNumber]);

  const stimuliNameLabel =
    stimuliNameAlias !== "Ad"
      ? stimuliNameAlias.toLowerCase()
      : t("editProject:groupSample.stimuli");

  return (
    <CellSizeRow>
      <FormControl variant="outlined">
        <InputLabel htmlFor="cell-size-field">
          {t("editProject:groupSample.cellSize")}
        </InputLabel>
        <OutlinedInput
          label={t("editProject:groupSample.cellSize")}
          id="cell-size-field"
          value={state.cellSize}
          onChange={(e) =>
            dispatch({ type: "CHANGE_CELL_SIZE", value: e.target.value })
          }
          onBlur={(e) =>
            dispatch({ type: "BLUR_CELL_SIZE", value: e.target.value })
          }
          disabled={disabled}
        />
      </FormControl>
      {adjustableStimuli && (
        <>
          <span>
            x {stimuliNumber}{" "}
            {adjustStimuliName(stimuliNameLabel, stimuliNumber)}
          </span>
          <FormControl variant="outlined">
            <InputLabel htmlFor="total-sample-size-field">
              {t("editProject:groupSample.totalSampleSize")}
            </InputLabel>
            <OutlinedInput
              label={t("editProject:groupSample.totalSampleSize")}
              id="total-sample-size-field"
              value={state.totalSize}
              onChange={(e) =>
                dispatch({ type: "CHANGE_TOTAL_SIZE", value: e.target.value })
              }
              onBlur={(e) =>
                dispatch({ type: "BLUR_TOTAL_SIZE", value: e.target.value })
              }
              disabled={disabled}
            />
          </FormControl>
        </>
      )}
    </CellSizeRow>
  );
};

function sanitizeToPositiveInteger(value: string): string {
  return (value.match(/[0-9]+/g) || []).join("");
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

const adjustStimuliName = (name: string, count: number) => {
  if (count > 1) {
    return `${name}s`;
  }
  return name;
};

const CellSizeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  span {
    margin-right: 20px;
    margin-left: 20px;
  }

  .MuiOutlinedInput-root {
    width: 180px;
  }
`;
