import React, { Suspense } from "react";
import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { ThemeProvider } from "styled-components";
import { QueryClientProvider } from "react-query";

import theme from "styles/theme";
import { ViewLoader, NotificationSnackbar } from "components/Shared";
import { queryClient } from "api/queryClient";
import { SnackbarProvider } from "notistack";
import { NotificationContextProvider } from "../../contexts/NotificationContext";
import { Routing } from "routing";
import { AuthProvider } from "contexts/Auth";

export const App = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        content={(key, message) => (
          <NotificationSnackbar id={key} message={message} />
        )}
      >
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<ViewLoader fullPage />}>
            <CssBaseline />
            <NotificationContextProvider>
              <AuthProvider>
                <Routing />
              </AuthProvider>
            </NotificationContextProvider>
          </Suspense>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </MuiThemeProvider>
);
