import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as NotificationsIcon } from "assets/icons/notifications.svg";
import { NotificationsDialog } from "./NotificationsDialog";
import { getIsSomethingToRead } from "services/notificationsService";
import { useQuery } from "react-query";
import { queryKeys } from "api/queryClient";

export const NotificationsButton: React.VFC = () => {
  const [open, setOpen] = useState(false);
  const { data: isSomethingToRead } = useQuery(
    queryKeys.notifications.isSomethingToRead,
    getIsSomethingToRead,
    {
      refetchOnWindowFocus: true,
      refetchInterval: 60 * 1000,
      enabled: !open
    }
  );
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <NotificationsIcon />
        {isSomethingToRead && (
          <Box
            position="absolute"
            right={3}
            top={3}
            width={12}
            height={12}
            bgcolor="primary.main"
            borderRadius="50%"
          />
        )}
      </IconButton>
      <NotificationsDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
