import { CognitoUserSession } from "amazon-cognito-identity-js";

export const USER_AUTH_KEY = "UserAuth";

export type UserAuth = {
  token: string;
  refreshToken: string;
  email: string;
  emailVerified: string;
};

export const getStoredToken = () => {
  return getStoredUserAuth()?.token;
};

export const getStoredUserAuth = () => {
  const auth = window.localStorage.getItem(USER_AUTH_KEY);
  if (auth) {
    return JSON.parse(auth) as UserAuth;
  }
};

export const clearUserAuth = () => {
  window.localStorage.removeItem(USER_AUTH_KEY);
};

export const storeUserAuth = (session: CognitoUserSession) => {
  window.localStorage.setItem(
    USER_AUTH_KEY,
    JSON.stringify(createAuthStatus(session))
  );
};

const createAuthStatus = (userSession: CognitoUserSession): UserAuth => {
  const decodedDataPayload = userSession.getIdToken().decodePayload();
  return {
    token: userSession.getIdToken().getJwtToken(),
    refreshToken: userSession.getRefreshToken().getToken(),
    email: decodedDataPayload.email,
    emailVerified: decodedDataPayload.email_verified
  };
};
