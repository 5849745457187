import { httpClient } from "api/httpClient";
import { urls } from "api/urls";
import { UserDetailsDto, UserRole } from "dto";

export const getUserById = async (id: string) => {
  const response = await httpClient.get<UserDetailsDto>(`${urls.users}/${id}`);
  return response.data;
};

export const getMe = async () => {
  const response = await httpClient.get<UserDetailsDto>(urls.me);
  //TODO temporary, remove when backend will implement canOpenInTivian
  if (response.data.permissions.projectPermissions.canOpenInTivian == null) {
    const canOpen = ["PROJECT_MANAGER", "ADMIN"].includes(response.data.role);
    response.data.permissions.projectPermissions.canOpenInTivian = canOpen;
  }
  return response.data;
};

export const disableUser = async (userId: string) => {
  const response = await httpClient.put<UserDetailsDto>(
    `${urls.users}/${userId}/disabled`
  );
  return response.data;
};

export const enableUser = async (userId: string) => {
  const response = await httpClient.put<UserDetailsDto>(
    `${urls.users}/${userId}/enabled`
  );
  return response.data;
};

export const deleteUser = async (userId: string) => {
  const data = await httpClient.delete(`${urls.users}/${userId}`);
  return data;
};

export const updateUsername = async ({
  userId,
  name
}: {
  userId: string;
  name: string;
}) => {
  const response = await httpClient.put<UserDetailsDto>(
    `${urls.users}/${userId}/name`,
    { name }
  );
  return response.data;
};

export const updateUserRole = async ({
  userId,
  role
}: {
  userId: string;
  role: UserRole;
}) => {
  const response = await httpClient.put<UserDetailsDto>(
    `${urls.users}/${userId}/role`,
    { role }
  );
  return response.data;
};
