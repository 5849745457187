import React, { useState } from "react";
import { CloseableDialog, LoadingButton } from "components/Shared";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { editFolder } from "services/foldersService";
import { queryKeys } from "api/queryClient";
import { FolderDetailsDto, FolderListItem } from "dto/Folder";

export interface EditFolderDialogProps {
  open: boolean;
  onClose: () => void;
  folder: FolderDetailsDto | FolderListItem;
}

type EditPayload = Parameters<typeof editFolder>[0];

export const EditFolderDialog: React.VFC<EditFolderDialogProps> = ({
  open,
  onClose,
  folder
}) => {
  const { t } = useTranslation(["folders", "common"]);

  const [payload, setPayload] = useState<EditPayload>({
    id: folder.id,
    name: folder.name,
    referenceNo: folder.referenceNo,
    description: folder.description
  });

  const queryClient = useQueryClient();
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const { isLoading, mutate } = useMutation(editFolder, {
    onSuccess: () => {
      displaySuccessSnackbar(
        t("folders:edit.successFeedback", { folderName: folder.name })
      );
      queryClient.invalidateQueries(queryKeys.folders.all);
      onClose();
    },
    onError: () => displayErrorSnackbar()
  });

  const onEdit = () => {
    const { id, name, referenceNo, description } = payload;
    if (!name) {
      displayErrorSnackbar(t("folders:create.wrongInputFeedback"));
      return;
    }
    mutate({ id, name, referenceNo, description });
  };

  const updatePayload = <T extends keyof EditPayload>(
    fieldName: T,
    value: EditPayload[T]
  ) => setPayload((prevPayload) => ({ ...prevPayload, [fieldName]: value }));

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={onClose}
      header={
        <Typography variant="h2" align="center" mb={3}>
          {t("folders:edit.title", { folderName: folder.name })}
        </Typography>
      }
      body={<DialogBody payload={payload} updatePayload={updatePayload} />}
      footer={
        <DialogFooter isLoading={isLoading} onClose={onClose} onEdit={onEdit} />
      }
    />
  );
};

interface DialogBodyProps {
  payload: EditPayload;
  updatePayload: <T extends keyof EditPayload>(
    fieldName: T,
    value: EditPayload[T]
  ) => void;
}

const DialogBody: React.VFC<DialogBodyProps> = ({ payload, updatePayload }) => {
  const { t } = useTranslation(["folders"]);
  return (
    <>
      <TextField
        margin="dense"
        fullWidth
        label={t("folders:create.fields.name")}
        variant="outlined"
        value={payload.name}
        onChange={(e) => updatePayload("name", e.target.value)}
        autoFocus
      />
      <Typography variant="subtitle2" mt={2}>
        {t("folders:create.formattingInformation")}
      </Typography>
      <Box component="ul" color="neutral.grey_dark" mt={1} pl={6}>
        <Typography variant="body2" component="li">
          {t("folders:create.formatExample1")}
        </Typography>
        <Typography variant="body2" component="li" mt={1}>
          {t("folders:create.formatExample2")}
        </Typography>
      </Box>
      <TextField
        margin="dense"
        fullWidth
        label={t("folders:create.fields.referenceNumber")}
        variant="outlined"
        value={payload.referenceNo}
        onChange={(e) => updatePayload("referenceNo", e.target.value)}
      />
      <TextField
        margin="dense"
        fullWidth
        multiline
        maxRows={4}
        label={t("folders:create.fields.description")}
        variant="outlined"
        value={payload.description}
        onChange={(e) => updatePayload("description", e.target.value)}
      />
    </>
  );
};

interface DialogFooterProps {
  isLoading: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const DialogFooter: React.VFC<DialogFooterProps> = ({
  isLoading,
  onClose,
  onEdit
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={onClose}
      >
        {t("common:cancel")}
      </Button>

      <LoadingButton
        isLoading={isLoading}
        variant="contained"
        size="large"
        color="primary"
        onClick={onEdit}
      >
        {t("common:continue")}
      </LoadingButton>
    </>
  );
};
