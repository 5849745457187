import { Button, Stack } from "@mui/material";
import { LoadingButton } from "components/Shared";
import { useTranslation } from "react-i18next";
import { ReportDialogComponentProps } from "./ReportsDialog";

interface ReportsDialogFooterProps extends ReportDialogComponentProps {
  addSurvey: () => void;
  isSubmitEnabled: boolean;
  isSubmitLoading: boolean;
  toggleViewMode: () => void;
}

export const ReportsDialogFooter = ({
  addSurvey,
  isSubmitEnabled,
  isSubmitLoading,
  isViewMode,
  toggleViewMode
}: ReportsDialogFooterProps) => {
  const { t } = useTranslation(["surveyList", "common"]);

  if (isViewMode) {
    return (
      <Stack direction="row" width="100%" justifyContent="flex-end">
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={toggleViewMode}
        >
          {t("surveyList:reports.dialog.view.button")}
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <Button
        disabled={isSubmitLoading}
        variant="outlined"
        size="large"
        color="secondary"
        onClick={toggleViewMode}
      >
        {t("common:cancel")}
      </Button>

      <LoadingButton
        isLoading={isSubmitLoading}
        disabled={!isSubmitEnabled}
        variant="contained"
        size="large"
        color="primary"
        onClick={addSurvey}
      >
        {t("common:confirm")}
      </LoadingButton>
    </>
  );
};
