import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportDialogComponentProps } from "./ReportsDialog";

export const ReportsDialogHeader = ({
  isViewMode
}: ReportDialogComponentProps) => {
  const { t } = useTranslation(["surveyList", "common"]);

  const mode = isViewMode ? "view" : "add";

  return (
    <Typography variant="h2" align="center" mb={3}>
      {t(`surveyList:reports.dialog.${mode}.header`)}
    </Typography>
  );
};
