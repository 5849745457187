import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getProjectById, requestQuote } from "services/projectService";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";
import { ViewLoader, LoadingButton } from "components/Shared";
import { DetailedProject, PreviewStatuses } from "dto/Project";
import { ReactComponent as LaunchIcon } from "assets/icons/launch.svg";
import { CostCalculator } from "./CostCalculator";
import { ProjectStateContext } from "contexts/ProjectStateContext";
import { useTranslation } from "react-i18next";
import { EditableProjectName } from "./EditableProjectName";
import { Breadcrumbs } from "./Breadcrumbs";
import {
  StaticDataContext,
  StaticDataContextType
} from "contexts/StaticDataContext";
import { getTemplateById } from "services/templateService";
import { EditStatusBox } from "./EditStatus";
import { EditProjectProvider } from "contexts/EditProjectContext";
import { FeasibilityProvider } from "contexts/FeasibilityContext";
import { LaunchFromEditDialog } from "../LaunchDialog";
import { queryKeys } from "api/queryClient";
import { usePermissions } from "contexts/Auth";
import { Paths, useRouteParam } from "routing";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNotificationContext } from "contexts/NotificationContext";
import { GeneralInfo } from "./GeneralInfo";
import { FlatButton } from "../../../Shared/FlatButton";
import { useOpen } from "utils/useOpen";

export type DisplayMode = "preview" | "edit";

type EditProjectProps = {
  displayMode: DisplayMode;
};

export const EditProject: React.VFC<EditProjectProps> = ({ displayMode }) => {
  const { t } = useTranslation(["editProject"]);
  const { open, close, isOpen } = useOpen();
  const navigate = useNavigate();
  const { canLaunch, canRequestQuote, canSeeCosts } =
    usePermissions("projectPermissions");
  const id = useRouteParam("ProjectId");
  const [project, setProject] = useState<DetailedProject | undefined>();
  const { isError } = useQuery(
    queryKeys.projects.byId(id),
    () => getProjectById(id),
    { onSuccess: setProject }
  );

  useEffect(() => {
    const isRouteAllowed = () => {
      if (!project?.status) {
        return;
      }
      const { status } = project;
      const isPreviewStatus = PreviewStatuses.includes(status);

      if (!isPreviewStatus && displayMode === "preview") {
        navigate(Paths.projects.base);
        displayErrorSnackbar(t("editProject:cannotAccessNonPreviewProject"));
        return;
      }

      if (isPreviewStatus && displayMode === "edit") {
        navigate(Paths.projects.base);
        displayErrorSnackbar(t("editProject:cannotAccessActiveProject"));
        return;
      }
    };

    isRouteAllowed();
  });

  const templateId = project?.templateId;
  const { data: template } = useQuery(
    ["template", templateId],
    () => getTemplateById(templateId),
    { enabled: !!templateId }
  );

  const staticData: StaticDataContextType = {
    availableLanguages: template?.availableLanguages || [],
    availableAge: template?.availableAge || [],
    placeholderGroups: template?.placeholderGroups || [],
    maxStimuliNumber: template?.stimuliNumber || 1,
    adjustableStimuli: Boolean(
      template?.stimuliNumber && template.stimuliNumber > 1
    ),
    stimuliNameAlias: template?.stimuliNameAlias || "Ad"
  };

  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const { isLoading: isRequestQuoteLoading, mutate: requestQuoteMutate } =
    useMutation(requestQuote, {
      onSuccess: () => {
        displaySuccessSnackbar(t("editProject:requestQuote.successFeedback"));
        navigate(Paths.surveys.base);
      },
      onError: () => displayErrorSnackbar()
    });

  if (isError) {
    return <Navigate to="/" />;
  }

  if (project && template) {
    const handleRequestQuote = () => {
      requestQuoteMutate(project.id);
    };
    const updateProject = (update: Partial<DetailedProject>) =>
      setProject((prevProject) => {
        if (!prevProject) {
          throw new Error("Cannot update unset project");
        }
        return { ...prevProject, ...update };
      });

    return (
      <ProjectStateContext.Provider value={{ project, updateProject }}>
        <StaticDataContext.Provider value={staticData}>
          <EditProjectProvider>
            <FeasibilityProvider displayMode={displayMode}>
              <EditableProjectNameWrapper>
                <EditableProjectName displayMode={displayMode} />
              </EditableProjectNameWrapper>
              <Host>
                <Header>
                  <HeaderWrapper>
                    <Breadcrumbs displayMode={displayMode} />
                    <Buttons>
                      {displayMode === "edit" && <EditStatusBox />}
                      {canLaunch && (
                        <StyledButton
                          variant="contained"
                          color="primary"
                          endIcon={<LaunchIcon />}
                          onClick={open}
                        >
                          {t("editProject:launchSurvey")}
                        </StyledButton>
                      )}
                      {canRequestQuote && (
                        <LoadingButton
                          isLoading={isRequestQuoteLoading}
                          sx={{ mr: 4 }}
                          size="small"
                          variant="contained"
                          color="primary"
                          endIcon={<ArrowForwardIos sx={{ width: 12 }} />}
                          onClick={handleRequestQuote}
                        >
                          {t("editProject:requestQuote.button")}
                        </LoadingButton>
                      )}
                      <FlatButton id={project.id} />
                    </Buttons>
                  </HeaderWrapper>
                </Header>
                <Main>
                  <MainWrapper>
                    <StepWrapperCard>
                      <Outlet />
                    </StepWrapperCard>
                    <CostCard>
                      <GeneralInfo
                        templateName={template.shortCode}
                        projectName={project.projectName}
                        projectId={project.id}
                        groupName={project.groupName}
                        referenceNo={project.referenceNumber}
                        displayMode={displayMode}
                      />
                      {canSeeCosts && <CostCalculator />}
                    </CostCard>
                  </MainWrapper>
                </Main>
              </Host>
              {canLaunch && (
                <LaunchFromEditDialog open={isOpen} onClose={close} />
              )}
              {canRequestQuote && (
                <StyledButton
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIos sx={{ width: 12 }} />}
                  onClick={handleRequestQuote}
                >
                  {t("editProject:requestQuote.button")}
                </StyledButton>
              )}
            </FeasibilityProvider>
          </EditProjectProvider>
        </StaticDataContext.Provider>
      </ProjectStateContext.Provider>
    );
  }

  return <ViewLoader />;
};

const Host = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.grey_warm_20};
  height: 100%;
  display: grid;
  grid-template-rows: 70px 1fr;
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-top: 1px;
`;

const EditableProjectNameWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 69px;
  min-width: 400px;
  background-color: ${({ theme }) => theme.palette.background.default};
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%);
`;

const Main = styled.div`
  overflow-y: scroll;
  overflow-y: overlay;
`;

const StepWrapperCard = styled.div`
  margin: 25px 0;
  width: 100%;
  max-width: 826px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
`;

const CostCard = styled(StepWrapperCard)`
  align-items: stretch;
  right: 0;
  top: 25px;
  position: sticky;
  width: 260px;
`;

const MainWrapper = styled.div`
  position: relative;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HeaderWrapper = styled(MainWrapper)`
  height: 100%;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  height: 32px;
  margin: 0 8px 0 4px;
  stroke: ${({ theme }) => theme.palette.background.default};

  &:disabled {
    background-color: ${({ theme }) => theme.palette.neutral.grey_warm_50};
    color: ${({ theme }) => theme.palette.background.default};
  }

  svg {
    transform: translateY(-1px);
  }
`;
