import React from "react";
import styled from "styled-components";
import { FieldType, Placeholder, PlaceholderDetails } from "../../../../../dto";
import { CountryBasedSelect } from "./PlaceholderFields/CountryBasedSelect";
import { SliderField } from "./PlaceholderFields/SliderField";
import { SelectField } from "./PlaceholderFields/SelectField";
import { TextInput } from "./PlaceholderFields/TextField";

type GenericFieldProps = {
  disabled?: boolean;
  placeholderConfig: PlaceholderDetails;
  placeholder: Placeholder;
  autoFocus: boolean;
  updatePlaceholderValue: (
    key: string,
    value: string,
    allowedValueId?: string
  ) => void;
};

export const GenericField: React.FC<GenericFieldProps> = ({
  disabled,
  placeholderConfig,
  placeholder,
  autoFocus,
  updatePlaceholderValue
}) => {
  const { fieldType, tip } = placeholderConfig;
  const value = placeholder.text;

  return (
    <>
      {fieldType === FieldType.INPUT && !placeholderConfig.hide && (
        <TextInput
          placeholderConfig={placeholderConfig}
          placeholder={placeholder}
          autofocus={autoFocus}
          updatePlaceholderValue={updatePlaceholderValue}
          disabled={disabled}
        />
      )}
      {fieldType === FieldType.SELECT && (
        <SelectField
          placeholderConfig={placeholderConfig}
          placeholder={placeholder}
          updatePlaceholderValue={updatePlaceholderValue}
          disabled={disabled}
        />
      )}
      {fieldType === FieldType.SELECT_COUNTRY_BASED && (
        <CountryBasedSelect
          placeholderConfig={placeholderConfig}
          placeholder={placeholder}
          updatePlaceholderValue={updatePlaceholderValue}
          disabled={disabled}
        />
      )}
      {fieldType === FieldType.SLIDER && (
        <SliderField
          placeholderConfig={placeholderConfig}
          value={value ?? ""}
          updatePlaceholderValue={updatePlaceholderValue}
          disabled={disabled}
        />
      )}
      {tip && <Tip>{tip}</Tip>}
    </>
  );
};

const Tip = styled.p`
  margin: 4px 0 0 12px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;
