import React, { useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreHoriz";

export interface MoreButtonProps extends ButtonProps {
  listMinWidth?: number;
  renderItems: (closePopper: () => void) => React.ReactNode;
}

export const MoreButton: React.VFC<MoreButtonProps> = ({
  sx,
  listMinWidth = 240,
  renderItems,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => setOpen(false);
  const handleClickAway = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="medium"
        {...buttonProps}
        onClick={handleToggle}
        sx={{ px: 2.5, ...sx }}
        ref={anchorRef}
      >
        <MoreIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper sx={{ borderRadius: 1 }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList sx={{ minWidth: listMinWidth, py: 3 }}>
                  {renderItems(handleClose)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
