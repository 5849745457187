import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { usePermissions } from "contexts/Auth";
import { ProjectTab } from "dto/Project";
import { ReactNode, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Paths } from "routing";

const availableTabs = Object.values(ProjectTab);

export const ProjectsContainer = ({
  tab,
  createButton,
  children
}: {
  tab: ProjectTab;
  createButton?: ReactNode;
  children: ReactNode;
}) => {
  const { t } = useTranslation(["projects"]);
  const { canCreate: canCreateProject } = usePermissions("projectPermissions");
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<ProjectTab>(tab);

  const handleTabChange = (event: SyntheticEvent, newValue: ProjectTab) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case ProjectTab.FILES:
        return navigate(`/${Paths.folders.base}`);
      case ProjectTab.SURVEYS:
        return navigate(`/${Paths.surveys.base}`);
      case ProjectTab.TEMPLATES:
        return navigate(`/${Paths.templates.base}`);
      default:
        return;
    }
  };

  return (
    <Box p={6.25}>
      <Paper
        sx={{ py: 10, px: 8, mx: "auto", maxWidth: 1110, minHeight: 600 }}
        elevation={0}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={5}
        >
          <Typography variant="h1">{t("projects:heading")}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="primary"
          >
            {availableTabs.map(
              (value) =>
                !(value === ProjectTab.TEMPLATES && !canCreateProject) && (
                  <Tab
                    key={value}
                    value={value}
                    label={t(`projects:tabs.${value}`)}
                  />
                )
            )}
          </Tabs>
          {createButton}
        </Box>
        {children}
      </Paper>
    </Box>
  );
};
