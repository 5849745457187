import React from "react";
import { Button, Dialog, IconButton, Stack } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { useFeasibility } from "contexts/FeasibilityContext";
import { CostFeedback } from "./CostFeedback";
import { LoadingButton } from "components/Shared";
import { useStaticDataState } from "../../../../contexts/StaticDataContext";
import { InfoIcon } from "components/Shared/InfoIcon";

export interface CostDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export const CostDialog: React.FC<CostDialogProps> = ({
  open,
  onClose,
  onContinue
}) => {
  const { t } = useTranslation(["editProject", "common"]);
  const { isLoading, isError, feasibility } = useFeasibility();
  const { stimuliNameAlias, adjustableStimuli } = useStaticDataState();
  const displayStimuliRow = stimuliNameAlias === "Ad" && adjustableStimuli;
  const {
    currency,
    stimuli,
    cellSize,
    requestedSampleSize,
    availableSampleSize,
    costPerInterview,
    totalCost
  } = feasibility;

  return (
    <Dialog onClose={onClose} open={open}>
      <Header>
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Heading>{t("editProject:costsDialog.groupSampleAndCosts")} </Heading>
      </Header>
      <Main>
        {displayStimuliRow && (
          <Row>
            <span>{t("editProject:costsDialog.stimuli")}</span>
            <b>{stimuli}</b>
          </Row>
        )}
        <CellSizeRow>
          <span>{t("editProject:costsDialog.cellSize")}</span>
          <b>{cellSize}</b>
        </CellSizeRow>
        <Row>
          <Stack direction="row" alignItems="center" spacing={1}>
            <span>{t("editProject:costsDialog.requestedSampleSize")}</span>
            <InfoIcon
              tooltip={t("editProject:costsDialog.requestedSampleSizeTooltip")}
            />
          </Stack>
          <b>{requestedSampleSize}</b>
        </Row>
        <Row>
          <span>{t("editProject:costsDialog.availableSampleSize")}</span>
          <b>{availableSampleSize}</b>
        </Row>
        <FeedbackWrapper>
          <CostFeedback
            requestedSampleSize={requestedSampleSize}
            availableSampleSize={availableSampleSize}
            isLoading={isLoading}
            isError={isError}
            withIcon
          />
        </FeedbackWrapper>
        <CostsRow>{t("editProject:costsDialog.costs")}</CostsRow>
        <Row>
          <span>{t("editProject:costsDialog.costOrInterview")}</span>

          <b>
            <Currency>{currency}</Currency> {costPerInterview}
          </b>
        </Row>
        <Row>
          <span>{t("editProject:costsDialog.surveyCost")}</span>
          <b>
            <Currency>{currency}</Currency> {totalCost}
          </b>
        </Row>
        <Buttons>
          <Button variant="outlined" onClick={onClose}>
            {t("editProject:costsDialog.changeGroupSample")}
          </Button>
          <LoadingButton
            isLoading={isLoading}
            variant="contained"
            color="primary"
            disabled={isError}
            onClick={onContinue}
          >
            {t("common:continue")}
          </LoadingButton>
        </Buttons>
      </Main>
    </Dialog>
  );
};

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  gap: 20px;
`;

const Header = styled.div`
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CloseButton = styled(IconButton)`
  padding: 5px;
`;

const Main = styled.div`
  padding: 0 60px 40px;
`;

const Heading = styled.h2`
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  margin: 16px 0 12px;
  width: 100%;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
`;

const Row = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;
`;

const CellSizeRow = styled(Row)`
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
`;

const CostsRow = styled(Row)`
  margin-top: 20px;
  font-weight: bold;
`;

const FeedbackWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;
