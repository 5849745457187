import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { CreateGroupDialog } from "./CreateGroupDialog";

export const CreateGroupButton: React.VFC = () => {
  const { t } = useTranslation(["group"]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        {t("group:create.heading")}
      </Button>
      <CreateGroupDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
