import React, { useRef, useState } from "react";
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import { useAuth } from "contexts/Auth";
import { UserAvatar } from "../UserAvatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ReactComponent as LogoutIcon } from "assets/icons/log_out.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Paths } from "routing";

export const UserDropdown: React.VFC = () => {
  const { t } = useTranslation(["common"]);
  const i18nBase = `common:userDropdown`;
  const { user: me, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => setOpen(false);
  const handleClickAway = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    handleClose();
  };

  const name = me?.name || "";
  const email = me?.email || "";

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleToggle}
        ref={anchorRef}
        sx={{ width: 230, p: 2.25 }}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        <UserAvatar name={name} />
        <Typography flex={1} noWrap align="left" ml={2} variant="subtitle1">
          {name}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper sx={{ borderRadius: 1 }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList onClick={handleClose}>
                  <MenuItem disabled sx={{ px: 6, py: 5 }}>
                    <Typography width={210} noWrap variant="body1">
                      {email}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ px: 6, py: 3 }}
                    component={Link}
                    to={`/${Paths.accountSettings}`}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t(`${i18nBase}.accountSettings`)} />
                  </MenuItem>
                  <MenuItem disabled sx={{ px: 6, py: 3 }}>
                    <ListItemIcon>
                      <DownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t(`${i18nBase}.guide`)} />
                  </MenuItem>
                  <MenuItem
                    onClick={() => logout.mutate()}
                    sx={{ px: 6, py: 3 }}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={t(`${i18nBase}.logout`)} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
