import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Stack,
  Box
} from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { launchProject } from "services/projectService";
import { useNotificationContext } from "contexts/NotificationContext";
import { queryKeys } from "api/queryClient";
import { InfoIcon } from "components/Shared/InfoIcon";
import { usePreview } from "components/Shared/usePreview";

export interface LaunchProjectDialogContentProps {
  projectId: string;
  isFeasibilityLoading: boolean;
  isFeasibilityError: boolean;
  availableSampleSize: string;
  currency: string;
  costPerInterview: string;
  totalCost: string;
  isPreviewed: boolean;
  onClose: (isActive: boolean) => void;
}

export const LaunchProjectDialogContent: React.FC<LaunchProjectDialogContentProps> =
  ({
    projectId,
    onClose,
    isFeasibilityLoading,
    isFeasibilityError,
    currency,
    costPerInterview,
    availableSampleSize,
    totalCost,
    isPreviewed
  }) => {
    const { t } = useTranslation(["surveyList", "common", "editProject"]);
    const [active, setActive] = useState(false);
    const { displayErrorSnackbar } = useNotificationContext();
    const queryClient = useQueryClient();

    const { mutate: launch, isLoading } = useMutation(launchProject, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.projects.all);
        setActive(true);
      },
      onError: () => displayErrorSnackbar()
    });

    const handleLaunchClick = () => launch(projectId);

    const { handlePreviewClick, isPreviewLoading } = usePreview(projectId);

    if (isLoading) {
      return (
        <LoadingWrapper>
          <CircularProgress size={24} color="primary" />
        </LoadingWrapper>
      );
    }

    const handleClose = () => {
      onClose(active);
    };

    return (
      <Wrapper>
        {!active ? (
          <>
            <Header>{t("surveyList:launchProject.headingAsk")}</Header>
            <Description>{t("surveyList:launchProject.descAsk")}</Description>
            <CostWrapper>
              {isFeasibilityLoading || isFeasibilityError ? (
                <CalculationStatus>
                  {isFeasibilityLoading && (
                    <CircularProgress size={20} color="primary" />
                  )}
                  <p>
                    {t(
                      isFeasibilityLoading
                        ? "editProject:costsDialog.calculatingCosts"
                        : "editProject:costsDialog.calculateCostError"
                    )}
                  </p>
                </CalculationStatus>
              ) : (
                <>
                  <Row>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <span>
                        {t("editProject:costsDialog.requestedSampleSize")}
                      </span>
                      <InfoIcon
                        tooltip={t(
                          "editProject:costsDialog.requestedSampleSizeTooltip"
                        )}
                      />
                    </Stack>
                    <b>{availableSampleSize}</b>
                  </Row>
                  <CostsRow>{t("editProject:costsDialog.costs")}</CostsRow>
                  <Row>
                    <span>{t("editProject:costsDialog.costOrInterview")}</span>
                    <b>
                      <Currency>{currency}</Currency> {costPerInterview}
                    </b>
                  </Row>
                  <Row>
                    <span>{t("editProject:costsDialog.surveyCost")}</span>
                    <b>
                      <Currency>{currency}</Currency> {totalCost}
                    </b>
                  </Row>
                </>
              )}
            </CostWrapper>
            {!isPreviewed && (
              <Box
                bgcolor="error.light"
                p="16px"
                borderRadius="4px"
                mb="40px"
                width="min(100%,450px)"
                mx="auto"
              >
                <Typography color="error">
                  {t("surveyList:launchProject.isPreviewedInfo")}
                </Typography>
              </Box>
            )}
            <ButtonsWrapper>
              <Button variant="outlined" onClick={handleClose}>
                {t("common:cancel")}
              </Button>
              {isPreviewed ? (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isFeasibilityLoading || isFeasibilityError}
                  onClick={() => handleLaunchClick()}
                >
                  {t("surveyList:launchProject.launch")}
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isPreviewLoading}
                  onClick={handlePreviewClick((data) => data.previewUrl)}
                >
                  {isPreviewLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    t("surveyList:preview")
                  )}
                </Button>
              )}
            </ButtonsWrapper>
          </>
        ) : (
          <>
            <Header>{t("surveyList:launchProject.headingDone")}</Header>
            <Description>{t("surveyList:launchProject.descDone")}</Description>
            <ButtonsWrapper isCenter>
              <OkButton
                color="primary"
                variant="contained"
                onClick={handleClose}
              >
                {t("common:ok")}
              </OkButton>
            </ButtonsWrapper>
          </>
        )}
      </Wrapper>
    );
  };

const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.neutral.grey_warm_20};
  border-radius: 8px;
  flex-grow: 1;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  align-self: center;
  padding: 20px 40px;
  max-width: 450px;
`;

const CalculationStatus = styled.div`
  margin: auto;

  p {
    margin: 0;
    width: 300px;
  }

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.neutral.oxford_blue};
  }
`;

const Row = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;

  &:last-child {
    margin: 0;
  }
`;

const CostsRow = styled(Row)`
  font-weight: bold;
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const ButtonsWrapper = styled.div<{ isCenter?: boolean }>`
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? "center" : "space-between")};

  Button:first-child {
    height: 48px;
    width: 125px;
    font-weight: 700;
  }

  Button:nth-child(2) {
    margin-left: 20px;
    width: 178px;
    height: 48px;
    font-weight: 700;
  }
`;

const OkButton = styled(Button)`
  margin-top: 48px;
`;

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const LoadingWrapper = styled.div`
  height: 416px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h2`
  width: 100%;
  margin: 0 0 12px 0;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
`;

const Description = styled.p`
  width: 100%;
  margin: 0;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;
