import { useAddSurveyReport } from "api/mutations";
import { CloseableDialog } from "components/Shared";
import { usePermissions } from "contexts/Auth";
import { useNotificationContext } from "contexts/NotificationContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportsDialogBody } from "./ReportsDialogBody";
import { ReportsDialogFooter } from "./ReportsDialogFooter";
import { ReportsDialogHeader } from "./ReportsDialogHeader";

export interface ReportDialogComponentProps {
  isViewMode: boolean;
}

interface ReportsDialogProps {
  close: () => void;
  isOpen: boolean;
  projectId: string;
}

const ReportsDialog = ({ close, isOpen, projectId }: ReportsDialogProps) => {
  const { t } = useTranslation();
  const { displaySuccessSnackbar, displayErrorSnackbar } =
    useNotificationContext();
  const { canCreate } = usePermissions("reportPermissions");

  const [isViewMode, setIsViewMode] = useState(true);

  const [nameValue, setNameValue] = useState("");
  const [linkValue, setLinkValue] = useState("");

  const { mutate: addSurvey, isLoading } = useAddSurveyReport();

  const isLinkValid = validateUrl(linkValue);
  const areFieldsValid = !!nameValue && !!linkValue && isLinkValid;

  const clearFields = () => {
    setNameValue("");
    setLinkValue("");
  };

  const toggleViewMode = () => {
    if (!isViewMode) {
      clearFields();
    }

    setIsViewMode(!isViewMode);
  };

  const handleAddSurvey = () => {
    addSurvey(
      {
        projectId,
        body: {
          name: nameValue,
          link: linkValue
        }
      },
      {
        onSuccess: () => {
          toggleViewMode();
          displaySuccessSnackbar(
            t("surveyList:reports.dialog.add.successFeedback")
          );
        },
        onError: () => {
          displayErrorSnackbar();
        }
      }
    );
  };

  return (
    <CloseableDialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={close}
      header={<ReportsDialogHeader isViewMode={isViewMode} />}
      body={
        <ReportsDialogBody
          isFormLoading={isLoading}
          isViewMode={isViewMode}
          linkValue={linkValue}
          nameValue={nameValue}
          onLinkChange={setLinkValue}
          onNameChange={setNameValue}
          projectId={projectId}
        />
      }
      footer={
        canCreate && (
          <ReportsDialogFooter
            addSurvey={handleAddSurvey}
            isSubmitEnabled={areFieldsValid}
            isSubmitLoading={isLoading}
            isViewMode={isViewMode}
            toggleViewMode={toggleViewMode}
          />
        )
      }
    />
  );
};

export default ReportsDialog;

const validateUrl = (url: string) => {
  const urlRegex =
    /^https:\/\/([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*(:[0-9]+)?\/?)([^\s]*)$/;
  return urlRegex.test(url);
};
