import React, { useEffect, useState } from "react";
import { CloseableDialog, ViewLoader } from "components/Shared";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationDto } from "dto";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNotifications } from "services/notificationsService";
import { NotificationsList } from "./NotificationsList";

export interface NotificationsDialogProps {
  open: boolean;
  onClose: () => void;
}
const PAGE_SIZE = 10;

const i18nBase = "common:notifications";

export const NotificationsDialog: React.VFC<NotificationsDialogProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation(["common"]);

  const { notifications, hasNextPage, fetchNextPage, resetList } =
    useInfiniteNotificationsList();

  useEffect(() => {
    resetList();
    if (open) {
      fetchNextPage();
    }
  }, [open]);

  return (
    <CloseableDialog
      bodyProps={{ id: "notificationsListScrollableDiv" }}
      fullWidth
      PaperProps={{ sx: { maxHeight: 800, height: "100vh" } }}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      header={
        <Typography variant="h2" align="center">
          {t(`${i18nBase}.header`)}
        </Typography>
      }
      body={
        <Box
          minHeight={100}
          display="grid"
          gap={4}
          sx={{ alignItems: "center", overflowY: "auto" }}
        >
          {notifications ? (
            <InfiniteScroll
              dataLength={notifications.length}
              next={fetchNextPage}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden"
              }}
              hasMore={hasNextPage}
              loader={
                <Box height={50}>
                  <ViewLoader size={30} />
                </Box>
              }
              scrollableTarget="notificationsListScrollableDiv"
            >
              <NotificationsList
                notifications={notifications}
                onRead={onClose}
              />
            </InfiniteScroll>
          ) : (
            <ViewLoader size={30} />
          )}
        </Box>
      }
    />
  );
};

const useInfiniteNotificationsList = () => {
  const [notifications, setNotifications] = useState<NotificationDto[]>();
  const [hasNextPage, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const fetchNextPage = () => {
    getNotifications({ page: pageIndex, size: PAGE_SIZE }).then((pageable) => {
      setPageIndex((prevIndex) => prevIndex + 1);
      setHasNext(!pageable.last);
      setNotifications((prevNotifications) => {
        if (!prevNotifications) {
          return pageable.content;
        }
        const prevNotificationsIdsMap = prevNotifications.reduce<
          Record<string, true>
        >((acc, { id }) => ({ ...acc, [id]: true }), {});

        const onlyNew = pageable.content.filter(
          ({ id }) => !prevNotificationsIdsMap[id]
        );
        return prevNotifications.concat(onlyNew);
      });
    });
  };

  const resetList = () => {
    setNotifications(undefined);
    setPageIndex(0);
  };

  return { notifications, hasNextPage, fetchNextPage, resetList };
};
