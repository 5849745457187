import styled from "styled-components";
import { Button } from "@mui/material";
import { Template } from "dto/Template";
import { useTranslation } from "react-i18next";
import { ReactComponent as PreviewIcon } from "assets/icons/preview.svg";

export interface TemplateCardProps {
  template: Template;
}

export const TemplateCard = ({ template }: TemplateCardProps) => {
  const { t } = useTranslation(["createSurvey"]);

  const handlePreview: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    window.open(previewUrl, "_blank");
  };

  const { base64Background, shortCode, title, description, previewUrl } =
    template;

  return (
    <Host>
      <Img src={`data:image/png;base64,${base64Background}`} alt={title} />
      <TextBox>
        <Title>
          {title} ({shortCode})
        </Title>
        <Description>{description}</Description>
        <PreviewButton
          onClick={handlePreview}
          color="primary"
          endIcon={<StyledPreviewIcon />}
        >
          {t("createSurvey:previewTemplate")}
        </PreviewButton>
      </TextBox>
    </Host>
  );
};

const Host = styled.div`
  position: relative;
  width: 238px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  background-color: ${({ theme }) => theme.palette.neutral.grey_warm_50};
  transition: border-color 0.2s;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.palette.neutral.grey_warm_100};
  }
`;

const Img = styled.img`
  display: block;
  width: 100%;
`;

const TextBox = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 13px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 140px;
`;

const Title = styled.p`
  margin: 0;
  font-weight: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.neutral.w_black};
`;

const Description = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
`;

const PreviewButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.neutral.oxford_blue};
  stroke: ${({ theme }) => theme.palette.neutral.oxford_blue};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledPreviewIcon = styled(PreviewIcon)`
  width: 16px;
  height: 16px;
  transform: translateY(-1px);
`;
