import React from "react";
import { CloseableDialog } from "../../../../../Shared";
import {
  TableRow,
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  styled
} from "@mui/material";
import { Language } from "../../../../../../dto";

type PlaceholderValuesDialogProps = {
  open: boolean;
  onClose: () => void;
  textDescription: string;
  language?: Language;
};

export type DescriptionTable = {
  itemName: string;
  rows: DescriptionRows[];
};

export type DescriptionRows = {
  country: string | null;
  item: string;
  incidence: string;
};

export const PlaceholderValuesDialog: React.FC<PlaceholderValuesDialogProps> =
  ({ open, onClose, textDescription, language }) => {
    const descriptionTable = mapToDescriptionTable(textDescription);
    const countryAvailable = descriptionTable.rows.some((row) => !!row.country);
    const tableRows = filterRows(
      descriptionTable.rows,
      countryAvailable,
      language
    );
    const itemHeaderName = descriptionTable.itemName;
    const tableHeaders = Object.keys(tableRows[0]);

    return (
      <CloseableDialog
        bodyProps={{ id: "notificationsListScrollableDiv" }}
        fullWidth
        PaperProps={{ sx: { minWidth: 700, maxHeight: 550, height: "100vh" } }}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        body={
          <Box
            minHeight={100}
            display="grid"
            gap={4}
            sx={{ alignItems: "center", overflowY: "auto" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header}
                      align={header === "incidence" ? "center" : "left"}
                    >
                      {mapFirstLetterUppercase(
                        header === "item" ? itemHeaderName : header
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <StyledTableBody>
                {tableRows.map((row, index) => (
                  <TableRow key={index}>
                    <CellWithBorder
                      withoutBottomBorder={index === tableRows.length - 1}
                    >
                      {row.item}
                    </CellWithBorder>
                    <CellWithBorder
                      withoutBottomBorder={index === tableRows.length - 1}
                      align="center"
                    >
                      {row.incidence}
                    </CellWithBorder>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </Box>
        }
      />
    );
  };

const mapToDescriptionTable = (text: string) => {
  return JSON.parse(JSON.parse(text)) as DescriptionTable;
};

const filterRows = (
  rows: DescriptionRows[],
  countryAvailable: boolean,
  language?: Language
) => {
  if (!countryAvailable || !language) {
    return rows;
  }
  const extendedRows = extendRowsWithCountry(rows);
  const countryFromLocale = getCountryFromLocale(language.locale);
  return extendedRows
    .filter((row) => row.country === countryFromLocale)
    .map(
      (row) => ({ item: row.item, incidence: row.incidence } as DescriptionRows)
    );
};

const extendRowsWithCountry = (rows: DescriptionRows[]) => {
  const extendedRows: DescriptionRows[] = [];
  for (let index = 0; index < rows.length; ++index) {
    extendedRows.push({
      ...rows[index],
      country: rows[index].country
        ? rows[index].country
        : extendedRows[index - 1].country
    });
  }
  return extendedRows;
};

const getCountryFromLocale = (locale: string) => {
  switch (locale) {
    case "en_GB":
      return "UK";
    case "en_US":
      return "US";
    case "de_DE":
      return "GER";
    default:
      return "";
  }
};

const mapFirstLetterUppercase = (value: string) => {
  return (
    value.substr(0, 1).toUpperCase() +
    value.substr(1, value.length - 1).toLowerCase()
  );
};

const CellWithBorder = styled(({ withoutBottomBorder, ...props }) => (
  <TableCell {...props}></TableCell>
))`
  border-right: 1px solid #dfe1f2;

  &:last-child {
    border-right: none;
  }

  border-bottom: ${(props) =>
    props.withoutBottomBorder ? "none" : "1px solid #dfe1f2"};
`;

const StyledTableBody = styled(TableBody)`
  border-top: 1px solid #dfe1f2;
`;
