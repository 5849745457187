import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { App } from "components/App/App";
import { BrowserRouter } from "react-router-dom";

import "./config/i18n";

const render = () =>
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );

render();

if (module.hot) {
  module.hot.accept("components/App/App", () => {
    render();
  });
}
