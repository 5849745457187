import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as ProjectLaunchedIcon } from "assets/icons/play-circle.svg";
import { ReactComponent as QuoteRequestIcon } from "assets/icons/project.svg";
import { ReactComponent as FolderFinishedIcon } from "assets/icons/upload-2.svg";

import { NotificationDto, NotificationType } from "dto";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "react-query";
import { setNotificationAsRead } from "services/notificationsService";
import { queryKeys } from "api/queryClient";
import { Paths } from "routing";
import { useNavigate } from "react-router";

export interface NotificationProps {
  notification: NotificationDto;
  onRead: () => void;
}

const i18nBase = "common:notifications.content";

export const Notification: React.VFC<NotificationProps> = ({
  notification,
  onRead
}) => {
  const { t } = useTranslation(["common"]);
  const color = notification.read ? "textSecondary" : "textPrimary";
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate } = useMutation(setNotificationAsRead, {
    onSuccess: () =>
      queryClient.invalidateQueries(queryKeys.notifications.isSomethingToRead)
  });
  const onClick = () => {
    navigate(getUrl(notification));
    queryClient.invalidateQueries(getQueryKey(notification));
    mutate(notification.id);
    onRead();
  };

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        display: "grid",
        gridTemplateColumns: "auto minmax(0,1fr) auto",
        py: 4,
        textAlign: "left",
        alignItems: "start",
        borderBottom: 1,
        borderColor: "neutral.grey_warm_20"
      }}
    >
      <Box width={24} mr={4}>
        {getIcon(notification.type)}
      </Box>

      <Box>
        <Typography color={color} variant="subtitle1">
          {t(`${i18nBase}.${notification.type}.heading`)}
        </Typography>
        <Typography color={color} variant="body2">
          <Trans
            t={t}
            i18nKey={`${i18nBase}.${notification.type}.description`}
            values={{
              initiatorName: notification.initiator.username,
              resourceName: notification.resource.name,
              groupName: notification.group.name
            }}
            components={{ b: <strong /> }}
          />
        </Typography>
      </Box>

      <Typography variant="body2" color="textSecondary">
        {dayjs(notification.creationDate).fromNow()}
      </Typography>
    </ButtonBase>
  );
};

const getIcon = (type: NotificationType) => {
  switch (type) {
    case "FOLDER_FINISHED":
      return <FolderFinishedIcon />;
    case "PROJECT_LAUNCHED":
      return <ProjectLaunchedIcon />;
    case "QUOTE_REQUESTED":
      return <QuoteRequestIcon />;
    default:
      return null;
  }
};

const getUrl = (notification: NotificationDto): string => {
  // TODO adjust after merging feature with projects refactor
  const { id } = notification.resource;
  const { surveys } = Paths;
  switch (notification.type) {
    case "FOLDER_FINISHED":
      return `/${Paths.accountSettings}`;
    case "PROJECT_LAUNCHED":
    case "QUOTE_REQUESTED":
      return `/${surveys.base}/${surveys.edit.base}/${id}/${surveys.edit.groupSample}`;
  }
};

const getQueryKey = (notification: NotificationDto): string[] => {
  // TODO adjust after merging feature with projects refactor
  const { id } = notification.resource;
  switch (notification.type) {
    case "FOLDER_FINISHED":
      return queryKeys.folders.byId(id);
    case "PROJECT_LAUNCHED":
    case "QUOTE_REQUESTED":
      return queryKeys.projects.byId(id);
  }
};
