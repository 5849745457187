import { Box } from "@mui/material";
import { ProjectTab, Template } from "dto";
import { ProjectsContainer } from "../ProjectsContainer";
import { Templates } from "../Survey/CreateSurvey";

export const TemplatesList = () => {
  return (
    <ProjectsContainer tab={ProjectTab.TEMPLATES}>
      <Box mx={-6} mt={8}>
        <Templates
          handleOpen={(templateById: Template) => {
            // console.log(templateById);
          }}
        />
      </Box>
    </ProjectsContainer>
  );
};
