import React, { useEffect, useState } from "react";
import { CloseableDialog, LoadingButton } from "components/Shared";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNotificationContext } from "contexts/NotificationContext";
import { createFolder } from "services/foldersService";
import { queryKeys } from "api/queryClient";
import { useMe } from "contexts/Auth";

export interface CreateFolderDialogProps {
  open: boolean;
  onClose: () => void;
}

type CreatePayload = Parameters<typeof createFolder>[0];
const defaultPayload = {
  name: "",
  referenceNo: "",
  description: "",
  groupId: ""
};

export const CreateFolderDialog: React.VFC<CreateFolderDialogProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation(["folders", "common"]);
  const [payload, setPayload] = useState<CreatePayload>(defaultPayload);
  const { managedGroups, group } = useMe();

  useEffect(() => {
    if (!managedGroups && group.id) {
      setPayload({ ...defaultPayload, groupId: group.id });
    }
  }, [managedGroups, group]);

  const handleOnClose = () => {
    if (!managedGroups && group.id) {
      setPayload({ ...defaultPayload, groupId: group.id });
    }
    onClose();
  };

  const queryClient = useQueryClient();
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const { isLoading, mutate } = useMutation(createFolder, {
    onSuccess: async ({ id, name }) => {
      await queryClient.invalidateQueries(queryKeys.folders.all);
      handleOnClose();
      displaySuccessSnackbar(
        t("folders:create.successFeedback", { folderName: name })
      );
    },
    onError: () => displayErrorSnackbar()
  });

  const onCreate = () => {
    const { name, referenceNo, groupId, description } = payload;
    if (!name || !groupId) {
      displayErrorSnackbar(t("folders:create.wrongInputFeedback"));
      return;
    }
    mutate({ name, referenceNo, groupId, description });
  };

  const updatePayload = <T extends keyof CreatePayload>(
    fieldName: T,
    value: CreatePayload[T]
  ) => setPayload((prevPayload) => ({ ...prevPayload, [fieldName]: value }));

  return (
    <CloseableDialog
      fullWidth
      open={open}
      onClose={handleOnClose}
      header={<DialogHeader />}
      body={<DialogBody payload={payload} updatePayload={updatePayload} />}
      footer={
        <DialogFooter
          isLoading={isLoading}
          onClose={handleOnClose}
          onCreate={onCreate}
        />
      }
    />
  );
};

const DialogHeader: React.VFC = () => {
  const { t } = useTranslation(["folders"]);
  return (
    <>
      <Typography variant="h2" align="center" mb={3}>
        {t("folders:create.title")}
      </Typography>
      <Typography variant="body1" align="center" color="neutral.grey_dark">
        {t("folders:create.subtitle")}
      </Typography>
    </>
  );
};

interface DialogBodyProps {
  payload: CreatePayload;
  updatePayload: <T extends keyof CreatePayload>(
    fieldName: T,
    value: CreatePayload[T]
  ) => void;
}

const DialogBody: React.VFC<DialogBodyProps> = ({ payload, updatePayload }) => {
  const { t } = useTranslation(["folders", "surveyList"]);
  const { managedGroups } = useMe();
  return (
    <>
      {managedGroups && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="group-filter-label">
            {t("surveyList:listFilters.group.title")}
          </InputLabel>
          <Select
            labelId="group-filter-label"
            id="group-filter"
            label="Group"
            value={payload.groupId}
            onChange={(event) => updatePayload("groupId", event.target.value)}
          >
            {managedGroups.map((value) => (
              <MenuItem key={value.id} value={value.id}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <TextField
        margin="dense"
        fullWidth
        label={t("folders:create.fields.name")}
        variant="outlined"
        value={payload.name}
        onChange={(e) => updatePayload("name", e.target.value)}
        autoFocus
      />
      <Typography variant="subtitle2" mt={2}>
        {t("folders:create.formattingInformation")}
      </Typography>
      <Box component="ul" color="neutral.grey_dark" mt={1} pl={6}>
        <Typography variant="body2" component="li">
          {t("folders:create.formatExample1")}
        </Typography>
        <Typography variant="body2" component="li" mt={1}>
          {t("folders:create.formatExample2")}
        </Typography>
      </Box>
      <TextField
        margin="dense"
        fullWidth
        label={t("folders:create.fields.referenceNumber")}
        variant="outlined"
        value={payload.referenceNo}
        onChange={(e) => updatePayload("referenceNo", e.target.value)}
      />
      <TextField
        margin="dense"
        fullWidth
        multiline
        rows={4}
        label={t("folders:create.fields.description")}
        variant="outlined"
        value={payload.description}
        onChange={(e) => updatePayload("description", e.target.value)}
      />
    </>
  );
};

interface DialogFooterProps {
  isLoading: boolean;
  onClose: () => void;
  onCreate: () => void;
}

const DialogFooter: React.VFC<DialogFooterProps> = ({
  isLoading,
  onClose,
  onCreate
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={onClose}
      >
        {t("common:cancel")}
      </Button>

      <LoadingButton
        isLoading={isLoading}
        variant="contained"
        size="large"
        color="primary"
        onClick={onCreate}
      >
        {t("common:continue")}
      </LoadingButton>
    </>
  );
};
