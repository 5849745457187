import {
  FieldType,
  Language,
  Placeholder,
  PlaceholderDetails
} from "../../../../../dto";
import { createRecordWithKey } from "../../../../../utils/recordFactory";

export const getDefaultPlaceholderValue = (
  placeholder: Placeholder,
  placeholderConfig: PlaceholderDetails,
  language: Language
) => {
  switch (placeholderConfig.fieldType) {
    case FieldType.INPUT:
      return getDefaultInputValue(placeholder, placeholderConfig, language);
    case FieldType.SLIDER:
      return getDefaultSliderValue(placeholder);
    case FieldType.SELECT:
      return getDefaultSelectValue(placeholder);
    case FieldType.SELECT_COUNTRY_BASED:
      return getDefaultSelectValue(placeholder);
    default:
      return "";
  }
};

export const getDefaultInputValue = (
  placeholder: Placeholder,
  placeholderConfig: PlaceholderDetails,
  language: Language
) => {
  if (placeholder.text !== undefined && placeholder.text !== null) {
    return placeholder.text;
  }
  if (!placeholder.defaultValues && !placeholderConfig.exampleSentences) {
    return "";
  } else if (placeholder.defaultValues.length > 0) {
    const defaultValue = placeholder.defaultValues.find(
      (p) => p.locale === language.locale
    )?.value;
    return defaultValue ? defaultValue : "";
  } else if (placeholderConfig.exampleSentences.length > 0) {
    const exampleSentence = placeholderConfig.exampleSentences.find(
      (example) => example.locale === language.locale
    )?.value;
    return exampleSentence ? exampleSentence : "";
  } else {
    return "";
  }
};

export const getDefaultSliderValue = (placeholder: Placeholder) => {
  return placeholder.text ? placeholder.text : "50";
};

export const getDefaultSelectValue = (placeholder: Placeholder) => {
  return placeholder.text ? placeholder.text : "";
};

export const getPlaceholdersToUpdateWithDefault = (
  placeholders: Record<string, Placeholder>,
  placeholderConfigsRecord: Record<string, PlaceholderDetails>,
  language: Language
) => {
  const updatedPlaceholders = Object.values(placeholders).map((placeholder) => {
    const placeholderConfig = placeholderConfigsRecord[placeholder.key];
    const defaultValue = getDefaultPlaceholderValue(
      placeholder,
      placeholderConfig,
      language
    );
    return { ...placeholder, text: defaultValue };
  });
  if (updatedPlaceholders.length === 0) {
    return;
  }
  return createRecordWithKey(updatedPlaceholders);
};
