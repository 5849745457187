import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
  Breadcrumbs,
  CircularProgress
} from "@mui/material";
import { Box } from "@mui/system";
import { queryClient, queryKeys } from "api/queryClient";
import { LoadingButton, MoreButton, ViewLoader } from "components/Shared";
import { useNotificationContext } from "contexts/NotificationContext";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Paths, useRouteParam } from "routing";
import {
  deleteFolder,
  getFolderById,
  markFolderAsFinished
} from "services/foldersService";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { Check, Download, Folder } from "@mui/icons-material";
import { MultipleFilesUploader } from "../Files/MultipleFilesUploader";
import dayjs from "dayjs";
import { EditFolderButton } from "../Edit";
import saveAs from "file-saver";
import { usePermissions } from "contexts/Auth";

export const FolderDetails: React.VFC = () => {
  const { t } = useTranslation(["folders", "common"]);
  const { canEdit, canDelete } = usePermissions("folderPermissions");
  const id = useRouteParam("FolderId");
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const { displayErrorSnackbar, displaySuccessSnackbar } =
    useNotificationContext();
  const navigate = useNavigate();

  const { data: folder } = useQuery(
    queryKeys.folders.byId(id),
    () => getFolderById(id),
    {
      onError: () => {
        displayErrorSnackbar();
        navigate(Paths.folders.base);
      }
    }
  );

  const deleteMutation = useMutation(deleteFolder, {
    onSuccess: () => {
      navigate(Paths.folders.base);
      queryClient.invalidateQueries(queryKeys.folders.all);
      displaySuccessSnackbar(t("folders:files.delete.successFeedback"));
    },
    onError: () => displayErrorSnackbar()
  });

  const finishMutation = useMutation(markFolderAsFinished, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.folders.all);
      displaySuccessSnackbar(
        t("folders:finish.successFeedback", { folderName: folder?.name })
      );
    },
    onError: () => displayErrorSnackbar()
  });

  const downloadAll = () => {
    setIsDownloadInProgress(true);
    const requestOptions = {
      method: "GET"
    };

    fetch(`${window.location.origin}/api/folders/${id}/files`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(URL.createObjectURL(blob), `${folder?.name}.zip`);
        setIsDownloadInProgress(false);
      })
      .catch(() => {
        displayErrorSnackbar();
        setIsDownloadInProgress(false);
      });
  };

  const handleDeleteFolder = () => {
    deleteMutation.mutate({ folderId: id });
  };

  return folder ? (
    <Box p={6.25}>
      <Paper sx={{ pt: 10, mx: "auto", maxWidth: 1110 }} elevation={0}>
        <Box
          px={12.5}
          pb={7.5}
          borderBottom={1}
          borderColor="neutral.grey_warm_50"
        >
          <Breadcrumbs sx={{ mb: 2 }}>
            <Button
              sx={{ px: 0 }}
              size="small"
              startIcon={<ArrowBackIcon />}
              color="secondary"
              onClick={() => navigate("..")}
            >
              {t("folders:breadcrumbs.folders")}
            </Button>
            <Typography noWrap sx={{ flex: 1, minWidth: 0 }} variant="body2">
              {folder.name}
            </Typography>
          </Breadcrumbs>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ flex: 1, minWidth: 0 }}
            >
              <Folder
                sx={{
                  color: "#F8C821",
                  width: 45,
                  height: 45
                }}
              />
              <Typography variant="h1" mx={4} noWrap>
                {folder.name}
              </Typography>
            </Box>
            <Box>
              <LoadingButton
                sx={{
                  display: "inline-block",
                  mx: 2,
                  button: { minWidth: 144 }
                }}
                variant="contained"
                size="small"
                color="primary"
                disabled={!folder.files.length || folder.isFinished}
                isLoading={finishMutation.isLoading}
                onClick={() => finishMutation.mutate({ folderId: id })}
                endIcon={<Check />}
              >
                {t("folders:buttons.finish")}
              </LoadingButton>
              <LoadingButton
                sx={{ display: "inline-block", mx: 2 }}
                variant="outlined"
                size="small"
                color="secondary"
                disabled={!folder.files.length}
                endIcon={<Download />}
                isLoading={isDownloadInProgress}
                onClick={() => downloadAll()}
              >
                {t("folders:buttons.download")}
              </LoadingButton>
              <MoreButton
                sx={{ padding: "5px", ml: 2 }}
                renderItems={(closePopper) => (
                  <Box>
                    <EditFolderButton
                      folder={folder}
                      disabled={!canEdit}
                      handleClosePopper={closePopper}
                    />
                    <MenuItem
                      sx={{ color: "error.main" }}
                      onClick={handleDeleteFolder}
                      disabled={
                        deleteMutation.isLoading ||
                        folder.isFinished ||
                        !canDelete
                      }
                    >
                      <ListItemIcon>
                        <TrashIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("folders:buttons.deleteFolder")}
                      />
                      {deleteMutation.isLoading && (
                        <CircularProgress size={20} />
                      )}
                    </MenuItem>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
        <Grid container>
          <Grid item md={8}>
            <Box
              sx={{ height: "100%" }}
              px={12.5}
              py={7.5}
              borderRight={1}
              borderColor="neutral.grey_warm_50"
            >
              <MultipleFilesUploader folder={folder} />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box px={12.5} py={7.5}>
              <Typography variant="h2">
                {t("folders:details.header")}
              </Typography>
              <Grid mt={5} container>
                <Grid item mt={5} md={4}>
                  <Typography variant="body2" color="GrayText">
                    {t("folders:details.referenceNumber")}
                  </Typography>
                </Grid>
                <Grid item mt={5} md={8}>
                  {folder.referenceNo}
                </Grid>
                <Grid item mt={5} md={4}>
                  <Typography variant="body2" color="GrayText">
                    {t("folders:details.spaceUsed")}
                  </Typography>
                </Grid>
                <Grid item mt={5} md={8}>
                  {folder.spaceUsed}
                </Grid>
                <Grid item mt={5} md={4}>
                  <Typography variant="body2" color="GrayText">
                    {t("folders:details.visibility")}
                  </Typography>
                </Grid>
                <Grid item mt={5} md={8}>
                  {folder?.groupName}
                </Grid>
                <Grid item mt={5} md={4}>
                  <Typography variant="body2" color="GrayText">
                    {t("folders:details.createdBy")}
                  </Typography>
                </Grid>
                <Grid item mt={5} md={8}>
                  {dayjs(new Date(folder.created)).format("DD MMM YYYY")}
                  &nbsp;
                  <Typography component="span" variant="body2" color="GrayText">
                    {t("common:by")}
                  </Typography>
                  &nbsp;
                  {folder.author.name}
                </Grid>
                <Grid item mt={5} md={4}>
                  <Typography variant="body2" color="GrayText">
                    {t("folders:details.lastEdited")}
                  </Typography>
                </Grid>
                <Grid item mt={5} md={8}>
                  {dayjs(new Date(folder.lastEdited)).format(
                    "DD MMM YYYY HH:mm"
                  )}
                </Grid>
                <Grid item mt={5} md={4}>
                  <Typography variant="body2" color="GrayText">
                    {t("folders:details.description")}
                  </Typography>
                </Grid>
                <Grid item mt={5} md={8}>
                  {folder.description}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  ) : (
    <ViewLoader />
  );
};
