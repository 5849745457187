import { PageableParams } from "dto";
import { useQuery } from "react-query";
import { getSurveyReports } from "./endpoints";
import { queryKeys } from "./queryClient";

export const useGetSurveyReports = (
  projectId: string,
  params: PageableParams
) => {
  return useQuery(
    queryKeys.reports.listByParams(projectId, params),
    () => getSurveyReports(projectId, params),
    {
      keepPreviousData: true
    }
  );
};
