import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStaticDataState } from "contexts/StaticDataContext";
import { useProjectState } from "contexts/ProjectStateContext";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { Paths } from "routing";
import { NextButton, PrevButton } from "components/Shared";
import { PlaceholderGroupForm } from "./PlaceholderGroupForm";
import { useDebounce } from "utils";
import { useEditProject } from "contexts/EditProjectContext";
import { useFeasibility } from "../../../../../contexts/FeasibilityContext";
import { getPlaceholdersToUpdateWithDefault } from "./defaultValueProvider";
import { createRecordWithKey } from "../../../../../utils/recordFactory";
import { mergePlaceholdersWithDynamicConfig } from "./configurationPlaceholdersProvider";
import { DisplayMode } from "../EditProject";

type EditSurveyProps = {
  displayMode: DisplayMode;
};

export const EditSurvey: React.VFC<EditSurveyProps> = ({ displayMode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["editProject", "common"]);

  const { project } = useProjectState();
  const { recalculate } = useFeasibility();

  const { placeholderGroups } = useStaticDataState();
  const placeholderConfigs = placeholderGroups
    .flatMap((group) => group.sections)
    .flatMap((section) => section.placeholders);

  const savedPlaceholders = createRecordWithKey(project.placeholders);
  const placeholderConfigsRecord = mergePlaceholdersWithDynamicConfig(
    savedPlaceholders,
    createRecordWithKey(placeholderConfigs)
  );

  const [placeholders, setPlaceholders] = useState(savedPlaceholders);
  const [groupsVisited, setGroupsVisited] = useState<string[]>([]);

  const [currentGroupIndex, setGroupIndex] = useState(0);
  const currentGroup = placeholderGroups[currentGroupIndex];
  const updateLocalPlaceholder = (
    key: string,
    text: string,
    allowedValueId?: string
  ) => {
    setPlaceholders((prevPlaceholders) => ({
      ...prevPlaceholders,
      [key]: allowedValueId
        ? {
            ...prevPlaceholders[key],
            text,
            allowedValueId: allowedValueId
          }
        : { ...prevPlaceholders[key], text }
    }));
  };
  const lastGroupIndex = placeholderGroups.length - 1;
  const isCurrentGroupIndexLast = currentGroupIndex === lastGroupIndex;

  const { base, edit } = Paths.surveys;
  const prevPath = `/${base}/${edit.base}/${project.id}/${edit.stimuli}`;
  const exitPath = `/${base}`;

  const handlePrev = () => {
    if (currentGroupIndex === 0) {
      return navigate(prevPath);
    }
    setGroupIndex((prevIndex) => --prevIndex);
  };
  const handleNext = () => {
    setGroupIndex((prevIndex) => ++prevIndex);
  };
  const handleExit = () => {
    navigate(exitPath);
  };

  const { updatePlaceholders } = useEditProject();

  useEffect(() => {
    const updatedPlaceholderRecord = getPlaceholdersToUpdateWithDefault(
      placeholders,
      placeholderConfigsRecord,
      project.configuration.language
    );
    if (updatedPlaceholderRecord) {
      setPlaceholders(updatedPlaceholderRecord);
    }
  }, [project.configuration.language]);

  const debouncedPlaceholders = useDebounce(placeholders, 500);
  useEffect(() => {
    const placeholdersToUpdate = Object.values(debouncedPlaceholders).filter(
      (newPlaceholder) => {
        const oldPlaceholder = savedPlaceholders[newPlaceholder.key];
        return (
          newPlaceholder.text !== oldPlaceholder.text ||
          newPlaceholder.allowedValueId !== oldPlaceholder.allowedValueId
        );
      }
    );
    if (placeholdersToUpdate.length) {
      const recalculateRequired = placeholdersToUpdate.some((placeholder) => {
        const placeholderConfig = placeholderConfigs.find(
          (config) => config.key === placeholder.key
        );
        return placeholderConfig?.extraUsage === "INCIDENCE";
      });
      if (recalculateRequired) {
        updatePlaceholders(project.id, placeholdersToUpdate, recalculate);
      } else {
        updatePlaceholders(project.id, placeholdersToUpdate);
      }
    }
  }, [debouncedPlaceholders]);

  const placeholderGroupsKeys = placeholderGroups.map(({ sections }) =>
    sections.reduce<string[]>(
      (acc, { placeholders }) => [
        ...acc,
        ...placeholders.map(({ key }) => key)
      ],
      []
    )
  );
  const isGroupValid = (keys: string[], groupId: string) => {
    return (
      keys.every((key) => savedPlaceholders[key].text) &&
      groupsVisited.includes(groupId)
    );
  };

  if (placeholderGroups.length < 1) {
    return <Navigate to="/" />;
  }

  return (
    <Host>
      <Heading>{t("editProject:editSurvey")}</Heading>
      <Main>
        <Buttons>
          {placeholderGroups.map((group, i) => (
            <GroupButton
              key={i}
              variant="outlined"
              disabled={i === currentGroupIndex}
              onClick={() => setGroupIndex(i)}
            >
              <div>{group.name}</div>
              {isGroupValid(placeholderGroupsKeys[i], group.id) && (
                <CheckIcon />
              )}
            </GroupButton>
          ))}
        </Buttons>
        <div>
          <GroupHeading>{currentGroup.name}</GroupHeading>
          <GroupDescription>{currentGroup.description}</GroupDescription>
          <PlaceholderGroupForm
            placeholderGroup={currentGroup}
            placeholders={placeholders}
            placeholderConfigs={placeholderConfigsRecord}
            updatePlaceholder={updateLocalPlaceholder}
            groupVisited={(groupId) =>
              setGroupsVisited((prevState) => [...prevState, groupId])
            }
            displayMode={displayMode}
          />
        </div>
      </Main>
      <Footer>
        <PrevButton onClick={handlePrev} />
        {isCurrentGroupIndexLast ? (
          displayMode === "edit" && (
            <ExitButton variant="outlined" onClick={handleExit}>
              {t("common:exit")}
            </ExitButton>
          )
        ) : (
          <NextButton onClick={handleNext} />
        )}
      </Footer>
    </Host>
  );
};

const Host = styled.div`
  width: 100%;
`;

const Heading = styled.h2`
  margin: 0;
  padding: 30px 0 20px 50px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  margin-bottom: 32px;
`;

const Main = styled.div`
  display: grid;
  padding: 0 50px 0 25px;
  gap: 50px;
  grid-template-columns: auto 1fr;
`;

const Buttons = styled.div`
  display: grid;
  width: 236px;
  gap: 8px;
  grid-auto-rows: 56px;
  margin-bottom: 20px;
`;

const ExitButton = styled(Button)`
  && {
    padding: 12px 20px;
  }
`;

const GroupButton = styled(Button)`
  && {
    height: 56px;
  }

  &:disabled {
    border-color: ${({ theme: { palette } }) => palette.neutral.navy_blue};
    color: ${({ theme: { palette } }) => palette.neutral.oxford_blue};
  }

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    flex-grow: 1;
  }

  & svg {
    margin-left: 4px;
    flex-shrink: 0;
    transform: translateY(-3px);
  }
`;

const GroupHeading = styled.h4`
  margin: 0 0 8px;
`;

const GroupDescription = styled.p`
  color: ${({ theme }) => theme.palette.neutral.grey_dark};
  margin: 0 0 40px;
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.grey_warm_50};
  height: 79px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
