import { Typography, Paper } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupsTable } from "./GroupsTable";
import { getAllGroups } from "services/groupService";
import { useQuery } from "react-query";
import { QueryErrorFeedback, ViewLoader } from "components/Shared";
import { Box } from "@mui/system";
import { queryKeys } from "api/queryClient";
import { useUrlSortSettings } from "utils";
import { CreateGroupButton } from "../Create";
import { Paginator } from "components/Shared/Pagination";
import { usePermissions } from "contexts/Auth";

const size = 10;

export const GroupList: React.VFC = () => {
  const { t } = useTranslation(["group"]);
  const { canCreate } = usePermissions("groupPermissions");
  const [page, setPage] = useState(0);
  const { rawParam: sort } = useUrlSortSettings();

  const {
    data: pageable,
    refetch,
    isError,
    isFetching
  } = useQuery(
    queryKeys.group.list({ page, size, sort }),
    () => getAllGroups({ page, size, sort }),
    { keepPreviousData: true }
  );

  return (
    <Box p={6.25}>
      <Paper sx={{ py: 10, mx: "auto", maxWidth: 1110 }} elevation={0}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={12.5}
          py={5}
          borderBottom={1}
          borderColor="neutral.grey_warm_50"
        >
          <Typography variant="h1">{t("group:list.heading")}</Typography>
          {canCreate && <CreateGroupButton />}
        </Box>
        <Box mt={7.5} mx={12.5}>
          {isError ? (
            <QueryErrorFeedback retry={refetch} />
          ) : pageable ? (
            <>
              <GroupsTable
                isFetching={isFetching}
                groups={pageable.content}
                emptyFallback={
                  <Box
                    py={10}
                    display="grid"
                    gap={10}
                    sx={{ placeItems: "center" }}
                  >
                    <Typography variant="h2">
                      {t("group:list.emptyFallback")}
                    </Typography>
                    {canCreate && <CreateGroupButton />}
                  </Box>
                }
              />
              <Paginator
                resourceName={t("group:list.resourceName")}
                page={page}
                pageable={pageable}
                setPage={setPage}
                size={size}
              />
            </>
          ) : (
            <ViewLoader />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
