import { useParams } from "react-router-dom";
import { RouteParams } from "./routeParams";

type ParamName = keyof typeof RouteParams;

export const useRouteParam = (paramName: ParamName) => {
  const params = useParams<typeof RouteParams[ParamName]>();
  const selectedParam = params[RouteParams[paramName]];
  if (selectedParam == null) {
    throw Error(
      `Received undefined for "${paramName}" route param. Probably you are using it from wrong place.`
    );
  }
  return selectedParam;
};
