import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "assets/icons/circle-check.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/circle-error.svg";
import { CircularProgress } from "@mui/material";

export interface CostFeedbackProps {
  requestedSampleSize: string;
  availableSampleSize: string;
  isLoading: boolean;
  isError: boolean;
  withIcon?: boolean;
}

export const CostFeedback: React.VFC<CostFeedbackProps> = ({
  requestedSampleSize,
  availableSampleSize,
  isLoading,
  isError,
  withIcon = false
}) => {
  const { t } = useTranslation(["editProject"]);
  const isWarning = Number(availableSampleSize) < Number(requestedSampleSize);

  const icon = isWarning || isError ? <ErrorIcon /> : <CheckIcon />;

  const getMessage = () => {
    if (isError) {
      return t("editProject:costsDialog.calculateCostError");
    }
    return isWarning
      ? `${requestedSampleSize} ` +
          t("editProject:costsDialog.calculateCostWarningPart1") +
          ` ${availableSampleSize} ` +
          t("editProject:costsDialog.calculateCostWarningPart2")
      : t("editProject:costsDialog.calculateCostConfirmation");
  };

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Host isWarning={isError || isWarning}>
      {withIcon && icon} {getMessage()}
    </Host>
  );
};

const Host = styled.p<{ isWarning: boolean }>`
  max-width: 380px;
  width: 100%;
  display: flex;
  align-items: center;
  background: ${({ theme, isWarning }) =>
    isWarning ? theme.palette.error.light : theme.palette.success.light};
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme, isWarning }) =>
    isWarning ? theme.palette.error.main : theme.palette.success.main};
  padding: 16px 12px;

  & svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
`;
