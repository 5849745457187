import {
  Box,
  LinearProgress,
  TableCell,
  TableContainer,
  Tooltip,
  Typography
} from "@mui/material";
import styled from "styled-components";
import React, { useState } from "react";
import { ReactComponent as LaunchIcon } from "assets/icons/launch.svg";
import { DataTable, SortButton, SplitButton } from "components/Shared";
import refresh from "assets/icons/refresh.svg";
import { StatusBox } from "./StatusBox";
import { useTranslation } from "react-i18next";
import { ListProject, Project, Status } from "dto/Project";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { recalculateProgress } from "services/projectService";
import { LaunchFromListDialog } from "../LaunchDialog";
import { useNotificationContext } from "contexts/NotificationContext";
import { useNavigate } from "react-router";
import { Paths } from "routing";
import { usePermissions } from "contexts/Auth";
import { useUrlSortSettings } from "utils";
import { ReactComponent as ResultsEmpty } from "assets/icons/results-empty.svg";
import { SurveyListEmpty } from "./SurveyList";
import { TruncatedTypography } from "components/Shared/TruncatedTypography";
import ReportsButton from "./ReportsButton";
import ImageIconButton from "components/Shared/ImageIconButton";
import IconButton from "components/Shared/IconButton";

export interface SurveyTableProps {
  projects: ListProject[];
  footer?: React.ReactNode;
  hasFilters: boolean;
}

export const SurveyTable: React.FC<SurveyTableProps> = ({
  projects,
  footer,
  hasFilters
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["surveyList", "common"]);
  const canAccessGroupsAndUsers = usePermissions("canAccessGroupsAndUsers");
  const projectPermissions = usePermissions("projectPermissions");
  const reportPermissions = usePermissions("reportPermissions");
  const { displayErrorSnackbar } = useNotificationContext();
  const { setSortSettings, sortSettings } = useUrlSortSettings();

  const launchBtnVisible = (status: Status) => {
    return (
      projectPermissions.canLaunch &&
      (status === Status.PAUSED || status === Status.DRAFT)
    );
  };

  const projectIsEditable = (status: Status) => {
    return (
      projectPermissions.canEdit &&
      (status === Status.PAUSED ||
        status === Status.DRAFT ||
        status === Status.QUOTE_REQUESTED)
    );
  };

  const restartBtnVisible = (status: Status) => {
    return status === Status.ACTIVE;
  };

  const getReportsButton = (status: Status, projectId: string) => {
    const inactiveStatuses = [Status.QUOTE_REQUESTED, Status.DRAFT];
    const hasInactiveStatuses = inactiveStatuses.some(
      (inactiveStatus) => inactiveStatus === status
    );

    if (hasInactiveStatuses || !reportPermissions.canAccess) {
      return null;
    }

    return <ReportsButton projectId={projectId} />;
  };

  const [projectToLaunch, setProjectToLaunch] = useState<Project | null>(null);

  const handleLaunchProject = (project: Project) => setProjectToLaunch(project);
  const handleClose = () => setProjectToLaunch(null);

  const { mutate: recalculate } = useMutation(recalculateProgress);

  const refreshRow = (projectId: string, updatedProject: ListProject) => {
    const projectToUpdate = projects.find(
      (project) => project.id === projectId
    );
    if (projectToUpdate) {
      projectToUpdate.totalSamplesCount = updatedProject.totalSamplesCount;
      projectToUpdate.finishedSamplesCount =
        updatedProject.finishedSamplesCount;
    }
  };

  const handleRecalculateProgress = (projectId: string) => {
    recalculate(projectId, {
      onSuccess: (updatedProject) => {
        refreshRow(projectId, updatedProject);
      },
      onError: () => {
        displayErrorSnackbar();
      }
    });
  };

  const handleNameClick = (id: string) => {
    const { base, edit } = Paths.surveys;
    navigate(`/${base}/${edit.base}/${id}/${edit.groupSample}`);
  };

  return (
    <StyledTableContainer>
      <DataTable
        data={projects}
        emptyFallback={
          hasFilters ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={10}
              mb={10}
            >
              <ResultsEmpty />
              <Typography
                variant="subtitle1"
                sx={{ color: "neutral.w_black", mt: 5, fontSize: 15 }}
              >
                {t("common:empty.title")}
              </Typography>
              <Typography variant="body2" color="neutral.grey_dark">
                {t("common:empty.subtitle")}
              </Typography>
            </Box>
          ) : (
            <SurveyListEmpty />
          )
        }
        columnsNumber={7}
        head={
          <>
            <TableCell width={90} />
            <TableCell width={150}>
              <SortButton
                fieldName="projectName"
                value={sortSettings}
                onChange={setSortSettings}
              >
                {t("surveyList:name")}
              </SortButton>
            </TableCell>
            {canAccessGroupsAndUsers && (
              <TableCell width={150}>
                <SortButton
                  fieldName="groupName"
                  value={sortSettings}
                  onChange={setSortSettings}
                >
                  {t("surveyList:group")}
                </SortButton>
              </TableCell>
            )}
            <TableCell>
              <SortButton
                fieldName="creationDate"
                value={sortSettings}
                onChange={setSortSettings}
              >
                {t("surveyList:date")}
              </SortButton>
            </TableCell>
            <TableCell>
              <SortButton
                fieldName="author"
                value={sortSettings}
                onChange={setSortSettings}
              >
                {t("surveyList:author")}
              </SortButton>
            </TableCell>
            <TableCell>
              <SortButton
                fieldName="status"
                value={sortSettings}
                onChange={setSortSettings}
              >
                {t("surveyList:status")}
              </SortButton>
            </TableCell>
            <TableCell align="right" sx={{ zIndex: 1, position: "relative" }}>
              <SortButton
                fieldName="finishedSamplesCount"
                value={sortSettings}
                onChange={setSortSettings}
              >
                {t("surveyList:complete")}
              </SortButton>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" color="textSecondary">
                {t("surveyList:actions")}
              </Typography>
            </TableCell>
          </>
        }
        renderRow={(row) => (
          <>
            <LogoCell width={90}>
              <LogoWrapper>{row.templateShortCode.substr(0, 3)}</LogoWrapper>
            </LogoCell>
            <ProjectNameCell
              component="th"
              scope="row"
              width={150}
              sx={{ minWidth: 150 }}
              onClick={
                projectIsEditable(row.status)
                  ? () => handleNameClick(row.id)
                  : undefined
              }
            >
              <TruncatedTypography width={150}>
                {row.projectName}
              </TruncatedTypography>
            </ProjectNameCell>
            {canAccessGroupsAndUsers && (
              <TableCell width={150} sx={{ minWidth: 150 }}>
                {row.groupName}
              </TableCell>
            )}
            <DateCell width={150} sx={{ minWidth: 150 }}>
              <span>{dayjs(row.creationDate).format("DD.MM.YY")}</span>
              <span>{dayjs(row.creationDate).format("HH:mm")}</span>
            </DateCell>
            <TableCell width={140} sx={{ minWidth: 140 }}>
              {row.author.name}
            </TableCell>
            <TableCell width={115} sx={{ maxWidth: 115 }}>
              <StatusBox status={row.status}>
                {t(`surveyList:listFilters.status.${row.status}`)}
              </StatusBox>
            </TableCell>
            <CompletesCell align="right" width={90} sx={{ minWidth: 90 }}>
              <div>
                <ProgressNumber draft={row.status === Status.DRAFT}>
                  {row.finishedSamplesCount}/{row.totalSamplesCount}
                </ProgressNumber>
                <ProgressBar
                  variant="determinate"
                  value={
                    (row.finishedSamplesCount / row.totalSamplesCount) * 100
                  }
                />
              </div>
            </CompletesCell>
            <ButtonCell align="right" status={row.status}>
              <div>
                {getReportsButton(row.status, row.id)}
                {restartBtnVisible(row.status) && (
                  <Tooltip title={t("surveyList:refresh")}>
                    <ImageIconButton
                      sx={{ marginRight: "8px" }}
                      onClick={() => handleRecalculateProgress(row.id)}
                      iconAlt="refresh icon"
                      iconSource={refresh}
                    />
                  </Tooltip>
                )}
                {launchBtnVisible(row.status) &&
                  (row.previewed ? (
                    <Tooltip title={t("surveyList:launch")}>
                      <IconButton
                        sx={{ marginRight: "8px" }}
                        icon={<LaunchIcon />}
                        onClick={() => handleLaunchProject(row)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={t("surveyList:launchProject.launchDisabledMsg")}
                    >
                      <WrapperRequiredForTooltip>
                        <IconButton
                          sx={{ marginRight: "8px" }}
                          icon={<LaunchIconDisabled />}
                          variant="outlined"
                          color="primary"
                          disabled
                        />
                      </WrapperRequiredForTooltip>
                    </Tooltip>
                  ))}
                <SplitButton
                  withEdit={projectIsEditable(row.status)}
                  id={row.id}
                  project={row}
                />
              </div>
            </ButtonCell>
          </>
        )}
      />
      {footer && <Box>{footer}</Box>}
      {projectPermissions.canLaunch && (
        <LaunchFromListDialog project={projectToLaunch} onClose={handleClose} />
      )}
    </StyledTableContainer>
  );
};

const StyledTableContainer = styled(({ className, ...props }) => (
  <TableContainer {...props} className={className} />
))`
  && {
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    flex-grow: 1;
  }
`;

const LogoCell = styled(TableCell)`
  && {
    padding-right: 12px;
  }
`;

const ProjectNameCell = styled(TableCell)`
  && {
    ${({ onClick }) => (onClick ? "cursor: pointer" : "")};
    padding-left: 0;
  }
`;

const DateCell = styled(TableCell)`
  span:first-child {
    margin-right: 7px;
  }

  span:nth-child(2) {
    color: ${(props) => props.theme.palette.neutral.grey_dark};
  }
`;

const LogoWrapper = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.neutral.royal_blue};
  color: ${(props) => props.theme.palette.background.default};
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-top: 3px;
`;

const CompletesCell = styled(TableCell)`
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }
`;

const ButtonCell = styled(({ status, ...otherProps }) => (
  <TableCell {...otherProps} />
))`
  && {
    width: 360px;
    background-color: #fff;

    div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }
`;

const ProgressBar = styled(LinearProgress)`
  border-radius: 2px;

  && {
    &.MuiLinearProgress-colorPrimary {
      background-color: ${(props) => props.theme.palette.neutral.grey_warm_50};
    }

    .MuiLinearProgress-bar {
      border-radius: 2px;
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: ${(props) => props.theme.palette.neutral.navy_blue};
    }
  }
`;

const ProgressNumber = styled(({ draft, ...otherProps }) => (
  <span {...otherProps} />
))`
  && {
    width: 100%;
    color: ${(props) =>
      props.draft
        ? props.theme.palette.neutral.grey_dark
        : props.theme.palette.neutral.oxford_blue};
  }
`;

const WrapperRequiredForTooltip = styled.div``;

const LaunchIconDisabled = styled(LaunchIcon)`
  stroke: ${({ theme }) => theme.palette.neutral.grey_warm_50};
`;
