import { Button, ButtonProps } from "@mui/material";
import React, { forwardRef } from "react";

interface IconButtonProps extends ButtonProps {
  icon: React.ReactNode;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, ...buttonProps }, ref) => {
    return (
      <Button
        ref={ref}
        variant="outlined"
        {...buttonProps}
        sx={{
          height: "32px",
          width: "32px",
          padding: "0px",
          "&.MuiButton-root": {
            minWidth: "32px"
          },
          stroke: "black",
          ...buttonProps.sx
        }}
      >
        {icon}
      </Button>
    );
  }
);

export default IconButton;
