import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useProjectState } from "contexts/ProjectStateContext";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { useEditProject } from "contexts/EditProjectContext";
import { DisplayMode } from "./EditProject";
import { BoxProps } from "@mui/system";

type EditableProjectNameProps = {
  displayMode: DisplayMode;
};

const editStyles: BoxProps["sx"] = {
  cursor: "pointer",
  "&:hover": {
    border: "1px solid #dfe1f2"
  }
};

export const EditableProjectName: React.VFC<EditableProjectNameProps> = ({
  displayMode
}) => {
  const { project } = useProjectState();
  const [value, setValue] = useState(project.projectName);
  const [editMode, setEditMode] = useState(false);
  const { updateProject } = useEditProject();
  const isEditMode = displayMode === "edit";

  const handleFocus = () => {
    if (displayMode === "preview") {
      return;
    }

    setEditMode(true);
  };

  const handleBlur = () => {
    updateProject(
      project.id,
      {
        name: value,
        referenceNumber: project.referenceNumber
      },
      () => setEditMode(false)
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleBlur();
  };

  return (
    <Host onSubmit={handleSubmit}>
      <Box
        onClick={handleFocus}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          borderRadius: "4px",
          padding: "11px 8px",
          border: "1px solid transparent",
          "& svg": {
            transform: "translateY(-2px)"
          },
          ...(isEditMode && editStyles)
        }}
      >
        <Text>{value}</Text>
        {isEditMode && <EditIcon />}
      </Box>
      {editMode && (
        <StyledTextField
          fullWidth
          autoFocus
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
        />
      )}
    </Host>
  );
};

const Host = styled.form`
  position: relative;
`;

const Text = styled.p`
  height: 24px;
  line-height: 24px;
  margin: 0;
  max-width: 500px;
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTextField = styled(TextField)`
  position: absolute;
  top: 0;
  background-color: white;

  & input {
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    padding-right: 28px;
    padding-left: 8px;
  }

  & .MuiInputBase-root {
    width: 100%;
  }
`;
