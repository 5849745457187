import React from "react";
import { ReactComponent as SavedIcon } from "assets/icons/check.svg";
import { CircularProgress } from "@mui/material";
import { ReactComponent as FailIcon } from "assets/icons/close_red.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { EditStatus, useEditProject } from "contexts/EditProjectContext";

export const EditStatusBox: React.FC = () => {
  const { t } = useTranslation(["editProject"]);
  const { status } = useEditProject();

  const getProperStatus = () => {
    if (status === EditStatus.SAVING) {
      return (
        <>
          <CircularProgress size={20} />
          <span>{t("editProject:saving")}</span>
        </>
      );
    }
    if (status === EditStatus.FAILED) {
      return (
        <>
          <FailIcon />
          <span>{t("editProject:failed")}</span>
        </>
      );
    }
    return (
      <>
        <SavedIcon />
        <span>{t("editProject:saved")}</span>
      </>
    );
  };

  return <Status>{getProperStatus()}</Status>;
};

const Status = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;

  span {
    margin-top: 4px;
    margin-left: 6px;
  }
`;
