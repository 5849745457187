import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationDto } from "dto";
import { Notification } from "./Notification";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

export interface NotificationsDialogProps {
  notifications: NotificationDto[];
  onRead: () => void;
}

const i18nBase = "common:notifications";

export const NotificationsList: React.VFC<NotificationsDialogProps> = ({
  notifications,
  onRead
}) => {
  const { t } = useTranslation(["common"]);
  const { newNotifications, oldNotifications } = useMemo(
    () => splitNotifications(notifications),
    [notifications]
  );

  return notifications.length ? (
    <Box display="grid" sx={{ gap: 4 }}>
      {newNotifications && (
        <>
          <Typography variant="h4">{t(`${i18nBase}.new`)}</Typography>
          <Box display="grid">
            {newNotifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                onRead={onRead}
              />
            ))}
          </Box>
        </>
      )}
      {oldNotifications && (
        <>
          <Typography variant="h4">{t(`${i18nBase}.older`)}</Typography>
          <Box display="grid">
            {oldNotifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                onRead={onRead}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  ) : (
    <Typography variant="h4" align="center">
      {t(`${i18nBase}.emptyFeedback`)}
    </Typography>
  );
};

const splitNotifications = (notifications: NotificationDto[]) => {
  const newNotifications: NotificationDto[] = [];
  const oldNotifications: NotificationDto[] = [];

  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);

  notifications.forEach((n) => {
    const isNew = !n.read;
    (isNew ? newNotifications : oldNotifications).push(n);
  });

  return { newNotifications, oldNotifications };
};
