import { NotAuthorizedHeader } from "./NotAuthorizedHeader";
import styled from "styled-components";
import { Footer } from "./Footer";
import { Suspense } from "react";
import { ViewLoader } from "../ViewLoader";

export type NonAuthorizedLayoutProps = {
  bgColor?: string;
};

export const NonAuthorizedLayout: React.FC<NonAuthorizedLayoutProps> = ({
  children,
  bgColor = "#F1F1F9"
}) => (
  <>
    <NotAuthorizedHeader />
    <ContentWrapper bgColor={bgColor}>
      <Suspense fallback={<ViewLoader fullPage />}>{children}</Suspense>
    </ContentWrapper>
    <Footer />
  </>
);

const ContentWrapper = styled.div<{ bgColor: string }>`
  width: 100%;
  height: 100vh;
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
`;
