import { TableCell, Typography } from "@mui/material";
import styled from "styled-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { GroupListDto } from "dto/Group";
import { ChevronRight } from "@mui/icons-material";
import { DataTable, GroupAvatar, SortButton } from "components/Shared";
import { useNavigate } from "react-router-dom";
import { useUrlSortSettings } from "utils";

export interface GroupsTableProps {
  groups: GroupListDto[];
  emptyFallback: React.ReactNode;
  isFetching: boolean;
}

export const GroupsTable: React.FC<GroupsTableProps> = ({
  groups,
  emptyFallback,
  isFetching
}) => {
  const { t } = useTranslation(["group"]);
  const navigate = useNavigate();
  const i18nBase = "group:list.table";
  const { setSortSettings, sortSettings } = useUrlSortSettings();

  return (
    <DataTable
      data={groups}
      emptyFallback={emptyFallback}
      isFetching={isFetching}
      columnsNumber={5}
      onRowClick={({ id }) => navigate(id)}
      head={
        <>
          <TableCell width={300}>
            <SortButton
              fieldName="name"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.header.name`)}
            </SortButton>
          </TableCell>
          <TableCell>
            <SortButton
              fieldName="usersCount"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.header.usersCount`)}
            </SortButton>
          </TableCell>
          <TableCell>
            <SortButton
              fieldName="projectsCount"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.header.projectsCount`)}
            </SortButton>
          </TableCell>
          <TableCell>
            <SortButton
              fieldName="foldersCount"
              value={sortSettings}
              onChange={setSortSettings}
            >
              {t(`${i18nBase}.header.foldersCount`)}
            </SortButton>
          </TableCell>
          <TableCell align="left" />
        </>
      }
      renderRow={(group) => (
        <>
          <TableCell>
            <NameWrapper>
              <GroupAvatar group={group} />
              <Typography
                variant="subtitle2"
                noWrap
                data-testid="group-name-cell"
              >
                {group.name}
              </Typography>
            </NameWrapper>
          </TableCell>
          <TableCell>
            <Typography variant="body2" data-testid="users-count-cell">
              {t(`${i18nBase}.value.usersCount`, {
                value: group.usersCount
              })}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" data-testid="projects-count-cell">
              {t(`${i18nBase}.value.projectsCount`, {
                value: group.projectsCount
              })}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" data-testid="folders-count-cell">
              {t(`${i18nBase}.value.foldersCount`, {
                value: group.foldersCount
              })}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <ChevronRight sx={{ m: 2, cursor: "pointer" }} />
          </TableCell>
        </>
      )}
    />
  );
};

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 19px;
`;
